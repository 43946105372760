import { Component } from '@angular/core';
import { OffTitlesTabContext } from '@core/models/tab-contexts/off-titles-tab-context';
import { ParametersService } from '@core/services/parameters.service';
import { OffTitlesService } from '@core/services/off-titles.service';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'off-title-tab',
  templateUrl: './off-title-tab.component.html',
  styleUrls: ['./off-title-tab.component.scss']
})
export class OffTitlesTabComponent {
  tabContext: OffTitlesTabContext = new OffTitlesTabContext(this.translateService, this.parametersService, this.offTitlesService);
  
  constructor(public translateService: EpisoftTranslateService, private parametersService: ParametersService, private offTitlesService : OffTitlesService) {
    this.translateService.onChangeCallback = () => { this.initOptionAddedSubscription(); };
    this.tabContext.sort.selectedOptionCode = "HTTypeLabel";
  } 

  async ngOnInit() {
    this.initOptionAddedSubscription();
  };

  public async initOptionAddedSubscription() {  
    this.tabContext.sort.options = [
      { Code: "CountryLabel", Label: await this.translateService.getAsyncTrad('common.shared.country').toPromise() || '' },
      { Code: "SituationLabel", Label: await this.translateService.getAsyncTrad('common.shared.status').toPromise() || '' },
      { Code: "TitleLabel", Label: await this.translateService.getAsyncTrad('other.props.name').toPromise() || '' },
      { Code: "HTTypeLabel", Label: await this.translateService.getAsyncTrad('other.props.type').toPromise() || '' },
    ];
  };
}
