import { BERUserEntity } from "../back-end-raw-entities/ber-user-entity";
import { FEDUserProfileEntity } from "./fed-user-profile-entity";

export class FEDUserEntity implements BERUserEntity {
    public constructor(source : BERUserEntity) {
        Object.assign(this, source);
    }

    static fromBERUserEntity(source: BERUserEntity): FEDUserEntity {
        let user: FEDUserEntity = new FEDUserEntity(source);
        user.Profile = FEDUserProfileEntity.fromBERUserProfileEntity(source.Profile);
        return user;
    }

    // Données de BERUserEntity
    Id!: number;
    ZenUserID!: string;
    UserLevel!: number;
    IsSuperAdmin!: boolean;
    EpisoftID!: string;
    ProfileID!: number;
    Profile!: FEDUserProfileEntity;
    Login!: string;
    Fullname!: string;
    IsoLanguageCode!: string;
    // Données connues uniquement côté Front End
}
