<div class="profile-dialog-component">
  <mat-dialog-content>

    <div class="profile-dialog-component__header">
      <span class="profile-dialog-component__profile-icon glyphicon glyphicon-tags"></span>
      <span>{{ this.profile.Name }} </span>
      <div class="profile-dialog-component__close-button" [mat-dialog-close]="true">X</div>
    </div>
    <!-- Nom -->
    <div class="profile-dialog-component__input-row">
      <label>{{ translateService.getLabel("admin.profiles.table.name") }}</label>
      <input class="profile-dialog-component__input" 
        [disabled]="this.profile.IsDefault"
        [class.invalid-input]="!validateName()" [(ngModel)]="this.profile.Name" name="Name"/>
    </div>
    <!-- Niveau d'autorisations -->
    <div class="profile-dialog-component__input-row">
      <label>{{ translateService.getLabel("admin.profiles.table.authorizationLevel") }}</label>
          <select class="epw-combobox" [(ngModel)]="this.profile.AuthorizationLevel" name="AuthLevel" [disabled]="this.profile.IsDefault">
            <option *ngFor="let level of this.authLevels"
            value="{{ level.code }}">{{ translateService.getLabel("admin.profiles.authLevels." + level.label ) }}
        </option>
      </select>
    </div>
    <!-- Accès données financières -->
    <div class="profile-dialog-component__input-row">
      <label></label>
      <span class="profile-dialog-component__checkbox-container">
          <input id="AccessFinancialData" class="epw-checkbox" [disabled]="this.profile.IsDefault" type="checkbox" [(ngModel)]="this.profile.AccessFinancialData" />
          <label for="AccessFinancialData">{{ translateService.getLabel("admin.profiles.table.financialAccess") }}</label>
      </span>
    </div>
    <!-- Filtrage du portefeuille -->
    <div class="profile-dialog-component__input-row">
      <label></label>
      <span class="profile-dialog-component__checkbox-container">
        <input id="wallet" class="epw-checkbox" [disabled]="this.profile.IsDefault" type="checkbox" [(ngModel)]="this.profile.hasFilteringOwners" />
          <label for="wallet">{{ translateService.getLabel("admin.profiles.table.wallet") }}</label>
      </span>
    </div>
    <!-- Liste des titulaires -->
    <div *ngIf="this.profile.hasFilteringOwners" class="profile-dialog-component__owners-table-container">
      <div class="profile-dialog-component__owners-table">
        <ng-container *ngFor="let owner of this.wallet">
            <input id="{{ owner.Id }}" class="epw-checkbox" type="checkbox" [checked]="this.profile.containsOwner(owner.Id)" (change)="onOwnerChecked(owner)"/>
            <label for="{{ owner.Id }}" [class.noActiveItems]="!owner.HasActiveItems" class="epw-label bold">{{ owner.Label }}</label>  
        </ng-container>
      </div>
    </div>
    <!-- Boutons du bas -->
    <div class="profile-dialog-component__bottom">
      <button class="profile-dialog-component__bottom--delete-button" [mat-dialog-close]="true" (click)="delete()"
        *ngIf="this.canDelete()">
        <span class="glyphicon glyphicon-trash"></span>{{ translateService.getLabel('common.delete') }}</button>
      <button class="epw-blue-button" [mat-dialog-close]="true" [disabled]="!validateName()" (click)="save()"
        *ngIf="this.canSave()">
        <span class="glyphicon glyphicon-floppy-disk"></span>{{ translateService.getLabel('common.save') }}</button>
       <button class="epw-white-button" [mat-dialog-close]="true">{{ translateService.getLabel('common.close') }}</button>
    </div>

  </mat-dialog-content>
</div>