import { Component } from '@angular/core';
import { LogService } from '@core/services/log.service';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'episoftwebv3';
  constructor (
      public translateService: EpisoftTranslateService, 
      private logService: LogService){
    // Initialisation du service de traduction - une seule fois, on ne veut pas le refaire à chaque refresh de la page
    translateService.addLanguages(['en', 'fr']);
    translateService.initializeCurrentLanguage();
  }
  
}