import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageCarouselComponent } from '@shared/image-carousel/image-carousel.component';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { FEDDrawingModelEntity } from '@core/models/entities/front-end-displayable-entities/fed-drawing-model-entity';
import { FEDDrawingModelDetails } from '@core/models/details/front-end-displayable-details/fed-drawing-model-details';

@Component({
  selector: 'carousel-selector',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './carousel-selector.component.html',
  styleUrls: ['./carousel-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselSelectorComponent {
  @Input({ required: true }) imageUrl!: string;
  @Input({ required: true }) entity!: FEDDrawingModelEntity | FEDDrawingModelDetails;
  dialogRef: any; 

  constructor(public translateService: EpisoftTranslateService, private dialog: MatDialog) {}

  public openPopover() {
    this.dialogRef = this.dialog.open(ImageCarouselComponent, {
      data: { imageUrl: this.imageUrl, entity: this.entity },
    });

    this.dialogRef.afterClosed().subscribe(() => {
      // Handle close event if needed
    });
 }

  public closePopover() {}

}