
import { BERWatchingEntity } from "../back-end-raw-entities/ber-watching-entity";
import { FEDBaseEntity } from "./fed-base-entity";

export class FEDWatchingEntity extends FEDBaseEntity implements BERWatchingEntity {
    constructor(source : BERWatchingEntity) {
        super(source);
        Object.assign(this, source);
    }
    // Données de BERWatchingEntity
    Subscriptions!: { NumOrder: string; Type: string; StartDate: Date; DueDate: Date; }[];
    WatchingTypeCode!: string;
    Denomination!: string;
    Classes!: { NumOrder: string; Num: string; }[];
    Options!: { NumOrder: string; TypeOption: string; CodePaysOption: string; }[];
    CurrentSubscriptionNumOrder!: string;

    // Données connues uniquement côté Front End
    CurrentOptionLabel!: string;
    CurrentSubscriptionLabel!: string;
    CurrentClassesLabel!: string;
}
