import { Component, Input } from '@angular/core';
import { DomainNameCriteria } from '@core/models/criterias/domain-name-criteria';
import { Owner } from '@core/models/owner';
import { TabContext } from '@core/models/tab-contexts/tab-context';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'domain-names-search-panel',
  templateUrl: './domain-names-search-panel.component.html',
  styleUrls: ['./domain-names-search-panel.component.scss']
})
export class DomainNamesSearchPanelComponent {
  @Input() tabContext! : TabContext;

  searchCriterias : DomainNameCriteria = new DomainNameCriteria();

  public getSearchCriterias() : DomainNameCriteria {
    return this.searchCriterias;
  }

  constructor(public translateService: EpisoftTranslateService) {}

  ngOnInit() {   
    if(this.tabContext != undefined) {
      this.tabContext.searchCriterias = this.searchCriterias;
    }
  }

  ownerLabelSelector : (owner : Owner) => string = owner => owner.Name;
  
  ownerIsActiveSelector : (owner : Owner) => boolean = owner => owner.HasActiveItems;

  ownerValueSelector : (owner : Owner) => string = owner => String(owner.Code);

  updateOwner(newValue: string) {
    let newValueAsNumber: number = Number(newValue);
    newValueAsNumber = isNaN(newValueAsNumber) ? 0 : newValueAsNumber;
    this.searchCriterias.Owner = newValueAsNumber;
  }

  updateIncludeInactive(newValue: boolean) {
    this.searchCriterias.IncludeInactive = newValue; 
  }
}
