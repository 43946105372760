<span class="search-panel-input-text-auto-complete-component">
    <div><label class="search-panel-input-text-auto-complete-component__label">{{ this.headerText }}</label></div>
    <div class="search-panel-input-text-auto-complete-component__row-container">
    <input type="text" class="search-panel-input-text-auto-complete-component__search-panel-input" [(ngModel)]="this.criteria.Txt" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let value of this.getValues()" 
            class="search-panel-input-text-auto-complete-component__autocomplete-item"
            [value]="value" [innerHTML]="value | highlight: this.criteria.Txt">{{value}}
        </mat-option>
    </mat-autocomplete>
    <span *ngIf="this.showTextFilter">
        <button [matMenuTriggerFor]="menu" class="search-panel-input-text-auto-complete-component__sort-button">
            <span class="glyphicon" [ngClass]="getGlyphiconClass(this.criteria.Comp)"></span>
        </button>
        <mat-menu #menu="matMenu">
            <button *ngFor="let comp of this.getComparisonOptions()" (click)="updateCriteria(comp)" mat-menu-item>
                <span class="search-panel-input-text-auto-complete-component__filter-menu-item glyphicon" [ngClass]="this.getGlyphiconClass(comp)"></span>
                {{ translateService.getLabel('common.searchComparison.' + comp) }}
            </button>
        </mat-menu>
    </span>
</div>
</span>