import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { DrawingModelsService } from '@core/services/drawing-models.service';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { FEDDrawingModelDetails } from '@core/models/details/front-end-displayable-details/fed-drawing-model-details';
import { FEDDrawingModelBaseDetails } from '@core/models/details/front-end-displayable-details/fed-base-drawing-model-details';
import { ActivatedRoute } from '@angular/router';
import { HydratationHelper } from '@core/helpers/hydratation-helper';
import { EntityRef } from '@core/models/details/entity-ref';
import { DateHelper } from '@core/helpers/date-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'drawing-model-details-page',
  templateUrl: './drawing-model-details-page.component.html',
  styleUrls: ['./drawing-model-details-page.component.scss']
})
export class DrawingModelDetailsPageComponent {
  drawingModelDetails!: FEDDrawingModelDetails;
  // Tableau d'objets des échéances passées
  pastOps: {
    Date: Date;
    DateLabel: string;
    Type: EntityRef;
  }[]= [];
  // Tableau d'objets des échéances à venir
  nextOps: {
    Date: Date;
    DateLabel: string;
    Type: EntityRef;
  }[]= [];
  refgm: string = '' ;
  guid: string = '' ;
  isFraction: boolean = false;

  constructor(private route: ActivatedRoute, private authService: AuthService, public translateService: EpisoftTranslateService, public drawingmodelsService: DrawingModelsService) {
  }

  ngOnInit() {   
    this.route.paramMap.subscribe(p => {
      this.refgm = p.get('id') ?? "";
      this.guid = p.get('guid') ?? "";
      this.drawingmodelsService.getDetails(this.refgm, this.guid).subscribe(d => 
        { 
          this.drawingModelDetails = d;
          // Tri entre échéances passés et à venir 
          this.sortDuesDates(this.drawingModelDetails);
          // Ecrasement Root en cas de fraction pour afficher les bonnes informations de fractions
          if (this.setIsFraction(this.drawingModelDetails)){
            this.drawingModelDetails.Root = this.drawingModelDetails.Fractions.find(f => f.RefGM === this.refgm)! ;
            HydratationHelper.HydrateDrawingModelDetails(this.drawingModelDetails, this.translateService.getCurrentLanguage());
          }
          //Reconstruction tableau pays désignés pour modèles internationaux éclatées 
          else if(this.drawingModelDetails.IsSplit){
            this.getDesignatedCountries(this.drawingModelDetails);
            HydratationHelper.HydrateDrawingModelDetails(this.drawingModelDetails, this.translateService.getCurrentLanguage());
          }
        });
    });

  }

  public getImageUrl(e: FEDDrawingModelDetails): string {
    return this.drawingmodelsService.GetImageUrl(e.Root.RefGM);
  }

  // Connexion ZenWeb
  public openDocuments() : void {   
    let url: string = `${environment.zenWebUrl}/content/B/${this.drawingModelDetails.CodeZen}?token=${this.authService.getToken()}`;
    window.open(url);
  };

  public canAccessFinancialData() : boolean{
   return this.authService.isAuthorizedToAccessFinancialData()
  }

  // Vérifie si le modèle demandé est un fraction ou non
  public setIsFraction(e: FEDDrawingModelDetails) : boolean{
    return this.isFraction = e.Root.RefGM !== this.refgm;
  }

  // Tri des dates d'échéances selon la date du jour
  public sortDuesDates(e: FEDDrawingModelDetails){
    const now : Date = new Date()
    var result : number = 0
    var fillTable : number = 0;
    e.Root.DueDates.sort((a,b) => DateHelper.compareDates(a.Date, b.Date)).forEach(dueDate => {
      result = DateHelper.compareDates(dueDate.Date, now)
      if(result > 0){
        this.nextOps.push(dueDate)
      } else { 
        this.pastOps.push(dueDate)
      } 
    });
    // Compléter tableau le plus court avec éléments vides pour un rendu propre
    fillTable = this.pastOps.length - this.nextOps.length;
    if (fillTable > 0){    
      for(let i=0; i< fillTable; i++){
        this.nextOps.push({
          Date: new Date(),
          DateLabel: '',
          Type: {
            $id: null,
            Code: '',
            Label: '',
            FR: '',
            EN: ''
          }
        });
      }      
    } 
    else {
      fillTable = - fillTable;
      for(let i=0; i< fillTable; i++){
        this.pastOps.push({
          Date: new Date(),
          DateLabel: '',
          Type: {
            $id: null,
            Code: '',
            Label: '',
            FR: '',
            EN: ''
          }
        });
      }
    }
  }

  // Fonction de construction de pays désignés pour modèles après éclatement effectif
  public getDesignatedCountries(e: FEDDrawingModelDetails) {
    e.Fractions.forEach(fraction =>{
      e.Root.DesignatedCountries.push({
          Code: fraction.DepositCountry.Code,
          Label: fraction.DepositCountry,
          SubItems: fraction.DesignatedCountries,
          Situation: fraction.Situation,
          DesDate: fraction.DesignationDate,
          DesignationDateLabel: '',
          EffDate: null,       
          EffectDateLabel: '',
          Designation: fraction.Designation,
          Active: fraction.Active,
      })
    });
  }

  // Affichage conditionnel selon présence ou abence de données
  public testConditionsDesignatedCountries(e: FEDDrawingModelDetails): number{
    return e.Root.DesignatedCountries.filter(ds => ds.Situation === null && ds.Designation === null).length;
  }

  public getDesignatedCountryLabel(code: string) : string {
    // Ce code est une bidouille pour rajouter les libellés des pays désignés d'une fraction, avec des retours chariots
    // Si pas de pays désignés, on renvoie simplement le libellé du pays dont le code est passé en paramètre
    // Pas optimal du tout, mais refaire les choses proprement serait trop coûteux
    let country = this.drawingModelDetails.Root.DesignatedCountries.find(c => c.Code === code);
    let label = country?.Label?.Label ?? code;
    // Ajout des pays désignés de la fraction le cas échéant
    this.drawingModelDetails.Fractions.filter(f => f.DepositCountry.Code === code).flatMap(f => f.DesignatedCountries).forEach(c => {
        label += "\n\t" + c.Label?.Label ?? c.Code;
      }
    );
    return label;
  }

 // Affichage de la légende sous le premier modèle disponible
  public getModelLabel(e: FEDDrawingModelDetails) {
    let result : string = this.translateService.getLabel("model.help.modelHint")
    result = result.replaceAll("%{num}", e.Root.Images[0].Idx.toString());
    return result;
  }
}
