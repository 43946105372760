<div class="off-title-details-card-component">
    <div class="off-title-details-card-component__header">
        <input class="epw-checkbox" id="checkboxSelectItem{{ details.RefGM }}" type="checkbox" value="on"
            [checked]="details.IsSelected" (click)="selectItem()">
        <label class="epw-title bigger bold" for="checkboxSelectItem{{ details.RefGM }}">{{ details.CurrentTitleLabel }}</label>
        <button class="off-title-details-card-component__button-open-documents" (click)="openDocuments()" [title]="this.translateService.getLabel('common.documents')">
            {{ translateService.getLabel('common.documents') }}
            <span class="glyphicon glyphicon-duplicate gm-margin-left-5px"></span>
        </button>
    </div>
    <div class="off-title-details-card-component__grid">
        <!-- 1ère colonne -->
        <div class="off-title-details-card-component__grid-column">
            <div class="off-title-details-card-component__grid-cell-title">{{ translateService.getLabel('other.props.type') }}</div> 
            <div class="off-title-details-card-component__grid-cell-value">{{ details.TypeLabel }}&nbsp;</div>    
        </div>
        <!-- 2ème colonne -->
        <div class="off-title-details-card-component__grid-column">
            <div class="off-title-details-card-component__grid-cell-title" *ngIf="details.Country != ''">{{ translateService.getLabel('common.shared.country') }}</div>    
            <div class="off-title-details-card-component__grid-cell-value">{{ details.CountryLabel }}&nbsp;</div>
        </div>
        <!-- 3ère colonne -->
        <div class="off-title-details-card-component__grid-column">
            <div class="off-title-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.status') }}</div>
            <div class="off-title-details-card-component__grid-cell-value">{{ details.SituationLabel }}&nbsp;</div>
        </div>
        <!-- 4ème colonne -->
        <div class="off-title-details-card-component__grid-column">
            <div class="off-title-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.refGM') }}</div>
            <div class="off-title-details-card-component__grid-cell-value">{{ details.RefGM }}&nbsp;</div>
        </div>
    </div>
</div>