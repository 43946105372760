import { BERDrawingModelRootEntity } from "../back-end-raw-entities/ber-drawing-model-root-entity";
import { FEDBaseEntity } from "./fed-base-entity";


export class FEDDrawingModelRootEntity extends FEDBaseEntity implements BERDrawingModelRootEntity {
    constructor(source : BERDrawingModelRootEntity) {
        super(source);
        Object.assign(this, source);
    }
    // Données de BERDrawingModelRootEntity
    RenewDueDates!: { Type: string; Date: Date; }[];
    DepositCountry!: string;
    DesignatedCountries!: { Code: string; SubItems: []; Active: boolean; }[];
    Title!: { FR: string; EN: string; };
    Images!: { Idx: number; Desig: string; Active: boolean; }[];
    Owners!: { Name: string; }[];
    OriDepDate!: Date;
    CurrRegNum!: string;
    CurrRegDate!: Date;
    DesignationDate!: Date | null;
    Designation!: { $ref: string; Code: string; FR: string; EN: string; } | null;
    IsCollectiveMark!: string;

    // Données connues uniquement côté Front End     
    RenewDueDatesLabels!: string;
    DepositCountryLabel!: string;
    DesignatedCountriesLabel!: string;
    CurrentTitleLabel!: string;
    OwnersLabel!: string;
    OriDepDateLabel!: string;
    CurrRegDateLabel!: string;
}
