import { Component, Input } from '@angular/core';
import { DrawingModelCriteria } from '@core/models/criterias/drawing-model-criteria';
import { Owner } from '@core/models/owner';
import { TabContext } from '@core/models/tab-contexts/tab-context';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'drawing-models-search-panel',
  templateUrl: './drawing-models-search-panel.component.html',
  styleUrls: ['./drawing-models-search-panel.component.scss']
})
export class DrawingModelsSearchPanelComponent {
  @Input() tabContext! : TabContext;

  searchCriterias : DrawingModelCriteria = new DrawingModelCriteria();

  constructor(public translateService: EpisoftTranslateService) {}

  ngOnInit(){
    if (this.tabContext != undefined){
      this.tabContext.searchCriterias = this.searchCriterias;
      this.searchCriterias.RegNum.Alphanum = true;
      this.searchCriterias.DepNum.Alphanum = true;
      this.searchCriterias.PrioNum.Alphanum = true;
    }
  }

  updateExactMatch(newValue: boolean){
    this.searchCriterias.Title.ExactMatch = newValue;
  }

  // Méthodes gérant la liste déroulante "Titulaire"
  ownerLabelSelector : (owner : Owner) => string = owner => owner.Name;
  
  ownerIsActiveSelector : (owner : Owner) => boolean = owner => owner.HasActiveItems; 

  ownerValueSelector : (owner : Owner) => Number = owner => owner.Code;

  updateOwner(newValue: string) {
    let newValueAsNumber: number = Number(newValue);
    newValueAsNumber = isNaN(newValueAsNumber) ? 0 : newValueAsNumber;
    this.searchCriterias.Owner = newValueAsNumber;
  }

  // Méthodes gérant la liste déroulante "Pays" 
  countryLabelSelector : (country: any) => string = country => country.CurrentCountryLabel;

  countryIsActiveSelector: Function = (a: any): boolean => true; //Tous les pays sont toujours proposés par défaut

  countryValueSelector: (country: any) => string = country => String(country.Code);

  updateCountry(newValue: string){
    this.searchCriterias.Country = newValue;
  }

  // Méthodes gérant la liste déroulante "Type dessin & modèle"
  typeLabelSelector : (TypeTitle: any) => string = TypeTitle => TypeTitle.CurrentTypeLabel;

  typeIsActiveSelector: Function = (a: any): boolean => true; //Tous les types de dossiers sont toujours proposés par défaut

  typeValueSelector: (TypeTitle: any) => string = TypeTitle => String(TypeTitle.Code);

  updateType(newValue: any) {
    this.searchCriterias.TypeTitle = newValue;
  }

  updateIncludeInactive(newValue: boolean){
    this.searchCriterias.IncludeInactive = newValue;
  }
}