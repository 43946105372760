import { TabContext } from "./tab-context";
import { StringHelper } from "@core/helpers/string-helper";
import { OffTitlesService } from "@core/services/off-titles.service";
import { EpisoftTranslateService } from '@core/services/translate.service';
import { ParametersService } from "@core/services/parameters.service";
import { Observable } from "rxjs";
import { GenericCriteria } from "../criterias/generic-criteria";
import { OffTitleCriteria } from "../criterias/off-title-criteria";
import { FEDOffTitleEntity } from "../entities/front-end-displayable-entities/fed-off-title-entity";
import { ExportActionType } from "../export-action";

export class OffTitlesTabContext extends TabContext {

    constructor(override translateService: EpisoftTranslateService, override parametersService : ParametersService, private offTitlesService : OffTitlesService ) {
        super(translateService, parametersService);
    }

    static readonly moduleId = "offTitles";

    override getModuleId(): string {
        return OffTitlesTabContext.moduleId;
    }

    protected override customLoadData(searchCriterias : GenericCriteria | undefined): Observable<any> {
        let customSearchCriterias = searchCriterias as OffTitleCriteria;
        return this.offTitlesService.GetOffTitles(customSearchCriterias);
      }
     
    override loadParameters() : void { 
        this.offTitlesService.GetParams().subscribe(r => {
            this.parameters.types = r.HTTypes;
            this.parameters.status = r.Situations;
            this.loadCurrentLanguageEntityParameters();
            this.sortEntityParameters();
          }
        ); 
        this.parametersService.Get().subscribe(r => {
            this.parameters.countries = r.Countries;
            this.loadCurrentLanguageGlobalParameters();
            this.sortGlobalParameters();
          }
        ); 
    }

    override sortData() {
        let rawData: FEDOffTitleEntity[] = this.data;

        rawData.sort((a, b) => {
            // Fonction de comparaison en fonction du sortCode
            switch (this.sort.selectedOptionCode) {
                case 'CountryLabel':
                    return StringHelper.compareStrings(a.Country, b.Country);
                case 'SituationLabel':
                    return StringHelper.compareStrings(a.SituationLabel, b.SituationLabel);
                case 'TitleLabel':
                    return StringHelper.compareStrings(a.CurrentTitleLabel, b.CurrentTitleLabel);
                case 'HTTypeLabel':
                    return StringHelper.compareStrings(a.TypeLabel, b.TypeLabel);
                default:
                    return 0; // Aucun tri par défaut
            }
        });
      
          if(!this.sort.useSortOrderASC) {
            rawData.reverse();
          }
      
          this.data = rawData;
    }

    public override resetSearchCriterias(): void {
        this.searchCriterias = new OffTitleCriteria();
      }

    public override getAvailableExportTypes(): ExportActionType[] {
        return [ExportActionType.List];
    }

    public override export(exportType: ExportActionType): void {
    this.offTitlesService.Export(exportType, this.data.filter(d => d.IsSelected));
    }

}