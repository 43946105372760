import { Component, Inject, Input } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { PatentsService } from '@core/services/patents.service';
import { GenericDetailsCardComponent } from '../generic-details-card/generic-details-card.component';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { TabContentPanelComponent } from '@shared/tab-content-panel/tab-content-panel.component';
import { FEDPatentFamilyEntity } from '@core/models/entities/front-end-displayable-entities/fed-patent-family-entity';
import { Router } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'patent-details-card',
  templateUrl: './patent-details-card.component.html',
  styleUrls: ['./patent-details-card.component.scss']
})
export class PatentDetailsCardComponent extends GenericDetailsCardComponent {
  @Input({ required: true }) details!: FEDPatentFamilyEntity;
  @Input({ required: true }) parent!: TabContentPanelComponent;

  constructor(@Inject(APP_BASE_HREF) public baseHref:string, private router: Router, authService: AuthService, public translateService: EpisoftTranslateService, public patentsService: PatentsService) {
    super(authService);
  }

  public selectItem() {
    // Elément (dé)sélectionné : on transmet l'info au parent pour mettre à jour la check box de sélection globale
    this.details.IsSelected = !this.details.IsSelected;
    // On passe par un "input" plutôt que par un évènement classique car le composant est généré dynamiquement et output n'est pas encore géré par ngComponentOutlet
    this.parent.itemSelectionStateChangedCallback(this.details.IsSelected);
  }

  public getImageUrl(e: FEDPatentFamilyEntity): string {
    return this.patentsService.GetImageUrl(e.RefImage);
  }

  // Connexion ZenWeb
  public openDocuments(codeZen : string) : void {   
    let url: string = `${environment.zenWebUrl}/content/B/${codeZen}?token=${this.authService.getToken()}`;
    window.open(url);
  };

  public openDetails(refgm: string) {
    const url = this.router.createUrlTree([this.baseHref, "home", "br", refgm]).toString();
    window.open(url, '_blank');
  }
}
