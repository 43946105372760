<mat-accordion displayMode='flat' multi (keyup.enter)="search()">
    <ng-container *ngIf="selectedComponent">
        <ng-container *ngComponentOutlet="selectedComponent; inputs: { tabContext: tabContext }" />
    </ng-container>
</mat-accordion>
<span class="button-search-container">
    <button class="button-search" [disabled]="!this.tabContext.isIdle" (click)="search()">
        <span class="glyphicon gm-margin-right-5px"
        [class.glyphicon-search]="!this.tabContext.isSearching"
        [class.glyphicon-repeat]="this.tabContext.isSearching"
        [class.spinner]="this.tabContext.isSearching">
        </span>&nbsp;{{ translateService.getLabel('common.search') }}
    </button>
</span>