<div class="domain-name-details-card-component">
    <div class="domain-name-details-card-component__header">
        <input class="epw-checkbox" id="checkboxSelectItem{{ details.RefGM }}" type="checkbox" value="on"
            [checked]="details.IsSelected" (click)="selectItem()">
        <label class="epw-label bigger bold" for="checkboxSelectItem{{ details.RefGM }}">{{ details.DNS }}</label>
        <button class="domain-name-details-card-component__button-open-details" (click)="openDomainNameURL()"><span
                class="glyphicon glyphicon-new-window"></span></button>
        <button class="domain-name-details-card-component__button-open-documents" (click)="openDocuments()" [title]="this.translateService.getLabel('common.documents')">{{ translateService.getLabel('common.documents') }}<span
                class="glyphicon glyphicon-duplicate gm-margin-left-5px"></span></button>
    </div>
    <div class="domain-name-details-card-component__grid">
        <!-- 1ère colonne -->
        <div class="domain-name-details-card-component__grid-column">
            <div class="domain-name-details-card-component__grid-cell-title">{{ translateService.getLabel('domain.props.bookingDate') }}</div>
            <div class="domain-name-details-card-component__grid-cell-value">{{ details.BookDateLabel }}&nbsp;</div>
            <div class="domain-name-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.renewDates') }}</div> 
            <div class="domain-name-details-card-component__grid-cell-value">{{ details.RenewDueDatesLabels }}&nbsp;</div>
            <div class="domain-name-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.status') }}</div>
            <div class="domain-name-details-card-component__grid-cell-value">{{ details.SituationLabel }}&nbsp;</div>        
        </div>
        <!-- 2ème colonne -->
        <div class="domain-name-details-card-component__grid-column">
            <div class="domain-name-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.owner') }}</div>
            <div class="domain-name-details-card-component__grid-cell-value">{{ details.Owner.Name }}&nbsp;</div>
            <div class="domain-name-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.refGM') }}</div>
            <div class="domain-name-details-card-component__grid-cell-value">{{ details.RefGM }}&nbsp;</div>
        
        </div>
    </div>
</div>