
import { BERSoleauEntity } from "../back-end-raw-entities/ber-soleau-entity";
import { FEDBaseEntity } from "./fed-base-entity";

export class FEDSoleauEntity extends FEDBaseEntity implements BERSoleauEntity {
    constructor(source : BERSoleauEntity) {
        super(source);
        Object.assign(this, source);
    }
    // Données de BERSoleauEntity
    Title!: { EN: string; FR: string; };
    Owner!: { Name: string; };
    DepDate!: Date;
    DepNum!: string;
    RenewDueDates!: { Type: string; Date: Date; }[];
    OtherDueDates!: { Type: string; Date: Date; }[];

    // Données connues uniquement côté Front End
    CurrentTitleLabel!: string;
    RenewDueDatesLabels! : string;
    DepDateLabel! : string;
}
