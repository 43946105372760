
export abstract class PasswordHelper {
    public static validatePassword(password : string) : boolean {
        // 6 caractères minimum
        if (password.length < 6) {
            return false;
        }

        // Au moins une majuscule
        if (!/[A-Z]/.test(password)) {
            return false;
        }

        // Au moins une minuscule
        if (!/[a-z]/.test(password)) {
            return false;
        }

        // Au moins un chiffre
        if (!/\d/.test(password)) {
            return false;
        }  

        return true;
    }

    public static generatePassword() {
        let n = 8; // Longueur
        var keyListAlpha = "abcdefghijklmnopqrstuvwxyz",
          keyListInt = "123456789",
          i,
          password = "";
        // One upper case
        password = keyListAlpha
          .charAt(Math.floor(Math.random() * keyListAlpha.length))
          .toUpperCase();
        // Three lower cases
        for (i = 0; i < 3; i++) {
          password += keyListAlpha.charAt(
            Math.floor(Math.random() * keyListAlpha.length)
          );
        }
        // Two digits
        for (i = 0; i < 2; i++) {
          password += keyListInt.charAt(
            Math.floor(Math.random() * keyListInt.length)
          );
        }      
        return password;
      }
}