import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { ApiPathsService } from '@core/services/api-paths.service';
import { LogService } from '@core/services/log.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService, 
    private apiPathsService: ApiPathsService, 
    private router: Router,
    private logService: LogService) { 
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler):
    Observable<HttpEvent<any>> {
    let isSecured: Boolean = false;
    // Si l'API réclame une authentification, on ajoute le token dans l'entête
    if (this.apiPathsService.RequiresAuth(request.url)) {
      // Récupération du token
      const token = this.authService.getToken();
      if (token != "") {
        isSecured = true;
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    this.logService.log(`=> [${request.method}] ${request.url} ${isSecured? "[Secured]":""}`);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Redirige vers la page de connexion en cas d'erreur 401 (unauthorized)
          this.router.navigate(['/login']);
        }
        return throwError(error);
      }));
  }
}
