import { Component, Input, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { TabContext } from '@core/models/tab-contexts/tab-context';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'tab-generic',
  templateUrl: './tab-generic.component.html',
  styleUrls: ['./tab-generic.component.scss']
})
export class TabGenericComponent {
  @Input() tabContext! : TabContext;

  isOpen = false;

  @ViewChild('searchPanel') searchPanel!: MatDrawer;

  constructor(
    public translateService : EpisoftTranslateService) {}

  toggleSearchPanel() : void {
    this.tabContext.isSearchPanelVisible = !this.tabContext.isSearchPanelVisible;
    this.searchPanel.toggle();
  }

  ngOnInit() {
    // On ouvre le panneau latéral de recherche après un court délai pour avoir un effet visuel similaire à ce qui était fait dans EpisoftWeb v2
    setTimeout(() => {
      this.isOpen = true;
    }, 50);
  }

}
