<mat-expansion-panel class="search-panel mat-elevation-z0" expanded="true">
    <mat-expansion-panel-header>
        <div class="panel-header">
            <label class="epw-label bold title">{{ translateService.getLabel('common.groups.general') }}</label>
        </div>
    </mat-expansion-panel-header>
    <div>
        <search-panel-input-text-autocomplete
            headerText="{{ translateService.getLabel('watching.props.name') }}" 
            [showTextFilter]="true" 
            [criteria]="this.searchCriterias.Denomination"
            [typeAhead]="this.tabContext.typeAhead" 
        />
        <search-panel-input-check-box
            checkBoxLabel="{{ translateService.getLabel('common.exactMatch') }}"
            (criteriaValueChanged)="this.updateExactMatch($event)"
        />
        <search-panel-input-combo-box  
            headerText="{{ translateService.getLabel('watching.props.type') }}" 
            [items]="this.tabContext.parameters.types"
            [labelSelector]="typeLabelSelector"
            [valueSelector]="typeValueSelector"
            [activeSelector]="typeIsActiveSelector"
            (criteriaValueChanged)="this.updateType($event)" 
        />
        <search-panel-input-combo-box #comboBoxOptions 
            headerText="{{ translateService.getLabel('watching.props.options') }}" 
            [items]="this.tabContext.parameters.filteredOptions"
            [labelSelector]="optionLabelSelector"
            [valueSelector]="optionValueSelector"
            [activeSelector]="optionIsActiveSelector"
            (criteriaValueChanged)="this.updateOption($event)"
            [disableSelector] = "this.tabContext.disableOptions"
        />   
        <search-panel-input-combo-box #comboBoxCountries
            headerText="{{ translateService.getLabel('watching.props.pays') }}" 
            [items]="this.tabContext.parameters.countries"
            [labelSelector]="countryLabelSelector"
            [valueSelector]="countryValueSelector"
            [activeSelector]="countryIsActiveSelector"
            (criteriaValueChanged)="this.updateCountry($event)"
            [disableSelector] = "this.tabContext.disableCountries"  
        />
        <search-panel-input-classes-selector
            headerText="{{ translateService.getLabel('watching.props.classes') }}"
            (criteriaValueChanged)="this.updateClasses($event)"
            [disableSelector] = "this.tabContext.disableClasses"  
        />
        <search-panel-input-text 
            headerText="{{ translateService.getLabel('common.shared.refGM') }}" 
            [showTextFilter]="false" 
            [criteria]="this.searchCriterias.RefGM"
        />
        <div class="panel-row-container">
            <div class="split-50">
                <search-panel-input-date-selector
                    headerText="{{ translateService.getLabel('watching.props.stateDate') }}"
                    [criteria]="this.searchCriterias.StateDate"
                />
            </div>
        </div>
        <search-panel-input-check-box
            headerText="{{ translateService.getLabel('common.shared.closed') }}"
            checkBoxLabel="{{ translateService.getLabel('common.shared.closedHelp') }}"
            (criteriaValueChanged)="this.updateIncludeInactive($event)"
        />
    </div>
</mat-expansion-panel>
