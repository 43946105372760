import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AuthService } from '@core/services/auth.service';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { ParametersService } from '@core/services/parameters.service';
import { GenericDetailsCardComponent } from '../generic-details-card/generic-details-card.component';
import { TabContentPanelComponent } from '@shared/tab-content-panel/tab-content-panel.component';
import { FEDOffTitleEntity } from '@core/models/entities/front-end-displayable-entities/fed-off-title-entity';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'off-title-details-card',
  templateUrl: './off-title-details-card.component.html',
  styleUrls: ['./off-title-details-card.component.scss']
})
export class OffTitleDetailsCardComponent extends GenericDetailsCardComponent {

  @Input({ required: true }) details!: FEDOffTitleEntity;
  @Input({ required: true }) parent!: TabContentPanelComponent;

  constructor(public translateService :EpisoftTranslateService, authService: AuthService, private parametersService: ParametersService,) {
    super(authService);
  }
 
  public selectItem() {
    // Elément (dé)sélectionné : on transmet l'info au parent pour mettre à jour la check box de sélection globale
    this.details.IsSelected = !this.details.IsSelected;
    this.parent.itemSelectionStateChangedCallback(this.details.IsSelected);
  }

  // Connexion ZenWeb
  public openDocuments() {   
    let url: string = `${environment.zenWebUrl}/content/B/${this.details.CodeZen}?token=${this.authService.getToken()}`;
    window.open(url);
  };

}
