import { Component, Input } from '@angular/core';

import { AuthService } from '@core/services/auth.service';

import { GenericDetailsCardComponent } from '../generic-details-card/generic-details-card.component';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { TabContentPanelComponent } from '@shared/tab-content-panel/tab-content-panel.component';
import { FEDDomainNameEntity } from '@core/models/entities/front-end-displayable-entities/fed-domain-name-entity';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'domain-name-details-card',
  templateUrl: './domain-name-details-card.component.html',
  styleUrls: ['./domain-name-details-card.component.scss']
})
export class DomainNameDetailsCardComponent extends GenericDetailsCardComponent {
  @Input({ required: true }) details!: FEDDomainNameEntity;
  @Input({ required: true }) parent!: TabContentPanelComponent;

  constructor(authService: AuthService, public translateService: EpisoftTranslateService) {
    super(authService);
  }

  public openDomainNameURL() : void {
      // Ouvre une nouvelle fenêtre ou onglet avec l'URL du nom de domaine
    const url = `http://${this.details.DNS}`;
    window.open(url, '_blank');
  }

  public selectItem() {
    // Elément (dé)sélectionné : on transmet l'info au parent pour mettre à jour la check box de sélection globale
    this.details.IsSelected = !this.details.IsSelected;
    // On passe par un "input" plutôt que par un évènement classique car le composant est généré dynamiquement et output n'est pas encore géré par ngComponentOutlet
    this.parent.itemSelectionStateChangedCallback(this.details.IsSelected);
  }

  // Connexion ZenWeb
  public openDocuments() : void {   
    let url: string = `${environment.zenWebUrl}/content/B/${this.details.CodeZen}?token=${this.authService.getToken()}`;
    window.open(url);
  };

}
