import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';

export interface EpiMessage {
  Text: string;
  DateStart: Date;
  DateEnd: Date;
  Created: Date;
}

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private httpApiService: HttpApiService) { }

  public GetMessage(): Observable<EpiMessage>  {
    return this.httpApiService.get<EpiMessage>("API-Messages-GET");
  }
}
