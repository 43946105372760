import { DateRangeCriteria } from "./date-range-criteria";
import { GenericCriteria } from "./generic-criteria";
import { SearchString } from "./search-string";

export class DrawingModelCriteria extends GenericCriteria {
    public Title: SearchString = new SearchString();
    public Owner: number = 0;
    public Country: string = '';
    public RefGM: SearchString = new SearchString();
    public Client: SearchString = new SearchString();
    public TypeTitle: string = '';
    public RegDate: DateRangeCriteria = new DateRangeCriteria();
    public RegNum: SearchString = new SearchString(); 
    public DepDate: DateRangeCriteria = new DateRangeCriteria();
    public DepNum: SearchString = new SearchString(); 
    public PrioDate: DateRangeCriteria = new DateRangeCriteria();
    public PrioNum: SearchString = new SearchString();
    public IncludeInactive: boolean = false;
    public DueDates: DateRangeCriteria = new DateRangeCriteria();

    public override resetToDefault(): void {
        const defaultValues = new DrawingModelCriteria();
        Object.assign(this, defaultValues);
    }
}