import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth.service';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { LogService } from '@core/services/log.service';
import { UsersService } from '@core/services/users.service';
import { ChangePasswordDialogComponent } from '@shared/dialogs/change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'navigationBar',
  templateUrl: './navigation.bar.component.html',
  styleUrls: ['./navigation.bar.component.scss']
})
export class NavigationBarComponent {

  constructor(
    public translateService: EpisoftTranslateService, 
    private router: Router, 
    private usersService: UsersService, 
    public dialog: MatDialog,
    private authService: AuthService,
    private logService: LogService) { }

  public logout() {
    this.logService.log("Logout");
    this.authService.logout(); // Remise à blanc du token de session
    this.router.navigate(['/login']);
  } 
  
  public getCurrentUserName(): string {
    return this.usersService.currentUser?.Fullname ?? "";
  }

  public getCurrentLanguageCode(): string {
    return this.translateService.getCurrentLanguage();
  }

  public isCurrentUserAdmin() : Boolean {
    return (this.usersService.currentUser?.UserLevel ?? 0 )> 0;
  }

  public updateLanguageSelection(languageCode: string) {
    this.translateService.setCurrentLanguage(languageCode)
    // Stockage langue en cache navigateur pour pages détails
    localStorage.setItem("IsoLanguageCode", languageCode);
    if(this.usersService.currentUser !== null)
      this.usersService.currentUser.IsoLanguageCode = languageCode
    // Rafraichissement de la page
     window.location.href = window.location.href;
  }

  public openChangePasswordDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '420px',
      height: '250px',
      position: {top: '50px', right: '150px'},
      data: {} // données à passer au composant du dialogue, si nécessaire
    });

    // Ajouter des gestionnaires d'événements pour le résultat du dialogue ici
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
