<div class="content-panel-header-component">
    <div class="content-panel-header-component__first-row">
        <span class="content-panel-header-component__toggle-button glyphicon" [class.glyphicon-menu-left]="this.tabContext.isSearchPanelVisible"
            [class.glyphicon-menu-right]="!this.tabContext.isSearchPanelVisible"
            (click)="this.toggleSearchPanel.emit()"></span>
        <button class="content-panel-header-component__white-button_with_margin" [disabled]="!this.tabContext.isIdle" (click)="loadData()">
                <span class="glyphicon glyphicon-briefcase"
                    [class.glyphicon-briefcase]="!this.tabContext.isLoading"
                    [class.glyphicon-repeat]="this.tabContext.isLoading"
                    [class.spinner]="this.tabContext.isLoading">
                </span>
            <span>{{ translateService.getLabel('common.wallet') }}</span>
        </button>
        <button class="content-panel-header-component__white-button_with_margin" 
            [matMenuTriggerFor]="menu" [disabled]="this.tabContext.isLoading || this.tabContext.data.length === 0">
            <span class="glyphicon glyphicon-export"></span>{{ translateService.getLabel('common.export') }}
            <mat-menu #menu="matMenu">
                <div *ngFor="let exportAction of this.exportActions">
                    <button mat-menu-item (click)="export(exportAction.type)">
                    <span [class]="exportAction.class + ' glyphicon gm-margin-right-15px'"></span>
                    <span>{{ translateService.getLabel(exportAction.label) }}</span>
                    </button>
                </div>
            </mat-menu>
        </button>
    </div>
    <div class="content-panel-header-component__other-row">
        <label class="epw-label blue bold">{{ translateService.getLabel('common.paginate.nbItems') }}</label> 
        <select class="epw-combobox" [(ngModel)]="this.tabContext.pageSize.selectedOption"
            (ngModelChange)="navigateToPage(1)">
            <option *ngFor="let pageSizeOption of this.tabContext.pageSize.options"
                [value]="pageSizeOption">
                {{pageSizeOption}}
            </option>
        </select>
        <label class="epw-label blue bold">{{ translateService.getLabel('common.paginate.sortOrder') }}</label>
        <span>
            <select class="epw-combobox" [(ngModel)]="this.tabContext.sort.selectedOptionCode"
                (ngModelChange)="onSortOptionSelected()">
                <option *ngFor="let sortOption of this.tabContext.sort.options" [value]="sortOption.Code">
                    {{sortOption.Label}}
                </option>
            </select>
            <button class="content-panel-header-component__sort-button" (click)="switchSortOrder()">
                <span class="glyphicon"
                    [ngClass]="{'glyphicon-sort-by-attributes': this.tabContext.sort.useSortOrderASC, 'glyphicon-sort-by-attributes-alt': !this.tabContext.sort.useSortOrderASC}"></span></button>
        </span>
        <span class="content-panel-header-component__paginator" *ngIf="this.tabContext.data.length > 0 && this.getPageCount() > 1">
            <button class="content-panel-header-component__pagination-button" 
                (click)="navigateToPage(1)"
                [disabled]="this.tabContext.pagination.currentPage === 1">«</button>

            <button class="content-panel-header-component__pagination-button" *ngFor="let page of getPagesToShow()"
                (click)="navigateToPage(page)" [disabled]="page === -1"
                [class.selected]="page === this.tabContext.pagination.currentPage"
                [class.active]="page === this.tabContext.pagination.currentPage">
                {{ adjustPageLabel(page) }}
            </button>

            <button class="content-panel-header-component__pagination-button"
                (click)="navigateToPage(this.getPageCount())"
                [disabled]="this.tabContext.pagination.currentPage === this.getPageCount()">»</button>
        </span>
    </div>
    <div class="content-panel-header-component__other-row">
        <span class="epw-badge" ngDefaultControl [ngModel]="this.tabContext.data">{{ this.tabContext.data.length }}
            {{ translateService.getLabel('common.paginate.results') }}</span>
        <input id="checkboxSelectAll" class="epw-checkbox" type="checkbox" value="on"
               *ngIf="this.tabContext.data.length > 0"
            [checked]="this.tabContext.selectAllIsChecked" (click)="selectAll()" />
        <label for="checkboxSelectAll" *ngIf="this.tabContext.data.length > 0"
            class="content-panel-header-component__select-all-label">{{ translateService.getLabel('common.selectAll') }}</label>
    </div>
</div>