import { Component } from '@angular/core';

import { DomainNamesTabContext } from '@core/models/tab-contexts/domain-names-tab-context';
import { DomainNamesService } from '@core/services/domain-names.service';
import { ParametersService } from '@core/services/parameters.service';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'domain-names-tab',
  templateUrl: './domain-names-tab.component.html',
  styleUrls: ['./domain-names-tab.component.scss']
})

export class DomainNamesTabComponent {

  tabContext: DomainNamesTabContext = new DomainNamesTabContext(this.translateService, this.parametersService, this.domainNamesService);

  constructor(public translateService: EpisoftTranslateService, private parametersService: ParametersService, private domainNamesService: DomainNamesService) {     
      this.translateService.onChangeCallback = () => { this.initOptionAddedSubscription(); };
      this.tabContext.sort.selectedOptionCode = "DNS";   
  }

  public async ngOnInit() {
    this.initOptionAddedSubscription();
  };

  public async initOptionAddedSubscription() {  
    this.tabContext.sort.options = [
      { Code: "BookingDate", Label: await this.translateService.getAsyncTrad('domain.props.bookingDate').toPromise() || '' || '' },
      { Code: "RenewDueDates", Label: await this.translateService.getAsyncTrad('common.shared.renewDates').toPromise() || '' },
      { Code: "DNS", Label: await this.translateService.getAsyncTrad('domain.name').toPromise() || '' },
      { Code: "SituationLabel", Label: await this.translateService.getAsyncTrad('common.shared.status').toPromise() || '' },
      { Code: "Owner", Label: await this.translateService.getAsyncTrad('common.shared.owner').toPromise() || '' },
    ];
  };

}

