import { TabContext } from "./tab-context";
import { StringHelper } from "@core/helpers/string-helper";
import { SoleauService } from "@core/services/soleau.service";
import { EpisoftTranslateService } from '@core/services/translate.service';
import { DateHelper } from "@core/helpers/date-helper";
import { Observable } from "rxjs";
import { ParametersService } from "@core/services/parameters.service";
import { FEDSoleauEntity } from "../entities/front-end-displayable-entities/fed-soleau-entity";
import { GenericCriteria } from "../criterias/generic-criteria";
import { SoleauCriteria } from '@core/models/criterias/soleau-criteria';
import { ExportActionType } from "../export-action";

export class SoleauTabContext extends TabContext {

    constructor(override translateService: EpisoftTranslateService, override parametersService : ParametersService, private soleauService : SoleauService) {
        super(translateService, parametersService);
    }

    static readonly moduleId = "soleau";

    override getModuleId(): string {
        return SoleauTabContext.moduleId;
    }

    protected override customLoadData(searchCriterias : GenericCriteria | undefined): Observable<any> {
        let customSearchCriterias = searchCriterias as SoleauCriteria;
        return this.soleauService.GetSoleau(customSearchCriterias); 
    }

    override loadParameters() : void { 
        this.soleauService.GetParams().subscribe(r => {
            this.parameters.owners = r.Owners;
            this.sortEntityParameters();  
        }
        ); 
    }

    override sortData() {
        let rawData: FEDSoleauEntity[] = this.data;

        rawData.sort((a, b) => {
            // Fonction de comparaison en fonction du sortCode
            switch (this.sort.selectedOptionCode) {
                case 'TitleLabel':
                    return StringHelper.compareStrings(a.CurrentTitleLabel, b.CurrentTitleLabel);
                case 'Owner':
                    return StringHelper.compareStrings(a.Owner.Name, b.Owner.Name);
                case 'DepDate':
                    return DateHelper.compareDates(a.DepDate, b.DepDate)
                case 'RenewDueDates':
                    return DateHelper.compareDates( (a.RenewDueDates.length > 0 ? new Date(a.RenewDueDates[0].Date) : new Date(1970, 1, 1)), 
                    (b.RenewDueDates.length > 0 ? new Date(b.RenewDueDates[0].Date) : new Date(1970, 1, 1)));
                case 'SituationLabel':
                    return StringHelper.compareStrings(a.SituationLabel, b.SituationLabel);
                default:
                    return 0; // Aucun tri par défaut
            }
        });
      
          if(!this.sort.useSortOrderASC) {
            rawData.reverse();
          }
      
          this.data = rawData;
    }

    public override resetSearchCriterias(): void {
        if (this.searchCriterias === null) this.searchCriterias = new SoleauCriteria();
        this.searchCriterias?.resetToDefault();
    }

    public override export(exportType: ExportActionType): void {
        this.soleauService.Export(exportType, this.data.filter(d => d.IsSelected));
    }

    public override getAvailableExportTypes(): ExportActionType[] {
        return [ExportActionType.List];
      }
}