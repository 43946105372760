import { Injectable } from '@angular/core';
import { Observable, catchError, map} from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { LogService } from '@core/services/log.service';
import { FEDUserEntity } from '@core/models/entities/front-end-displayable-entities/fed-user-entity';
import { BERUserEntity } from '@core/models/entities/back-end-raw-entities/ber-user-entity';
import { EpisoftTranslateService } from './translate.service';


@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(
        private httpApiService: HttpApiService, 
        private logService: LogService) { }

    public currentUser : FEDUserEntity | null = null;

    public LoadCurrentUserData() : void {
        this.httpApiService.get<BERUserEntity>("API-Users-Me-GET")
             .subscribe((data: BERUserEntity) => { 
             this.currentUser = FEDUserEntity.fromBERUserEntity(data);
         });
    }

    public GetCurrentUserData() : Observable<FEDUserEntity> {
        return this.httpApiService.get<BERUserEntity>("API-Users-Me-GET").pipe(
            map(user => FEDUserEntity.fromBERUserEntity(user))
        );
    }

    public GetUsers(eid: string): Observable<FEDUserEntity[]>  {
        return this.httpApiService.get<BERUserEntity[]>("API-Users-GET", new Map<string, string>([ [ "episoftid", eid ] ]))
            .pipe(map(users => users.map(user =>  FEDUserEntity.fromBERUserEntity(user))));
    }

    public UpdatePassword(zenuserid: string, password: string) : void {
        let payload = {
            Password: btoa(password)
        }
        // Attention, l'API utilisée pour MAJ le mot de passe est une API ZenWeb, et non EpisoftWeb !
        // C'est pour cela qu'elle utilise un code ZEN (de la forme Z000000)
        this.httpApiService.patch("API-Users-PATCH-ZEN", payload, new Map<string, string>([ [ "zenuserid", zenuserid ] ])).subscribe();
    }

    public CreateUser(user : FEDUserEntity, password: string) : void{
        let payload = {
            Login : user.Login,
            UserLevel : user.UserLevel,
            ProfileID : user.ProfileID,
            Fullname : user.Fullname,
            EpisoftID : user.EpisoftID,
            NewPassword : btoa(password),
            IsoLanguageCode : user.IsoLanguageCode
        };
        this.httpApiService.post<BERUserEntity>("API-Users-POST", payload, new Map<string, string>()).subscribe();
    }

    public UpdateUser(user : FEDUserEntity, password: string | undefined) : void{
        let payload: any = {
            ProfileID: user.ProfileID,
            Fullname: user.Fullname,
            IsoLanguageCode: user.IsoLanguageCode
        };
        
        // Ajout du mot de passe uniquement s'il a été modifié
        if (password !== undefined) {
            payload.NewPassword = btoa(password);
        }
        
        this.httpApiService.patch(
            "API-Users-PATCH", 
            payload, 
            new Map<string, string>([["userid", String(user.Id)]])
        ).subscribe();      
    }

    public DeleteUser(user : FEDUserEntity) {
        let payload =  {}; // Aucun payload pour cette API
        this.httpApiService.delete(
            "API-Users-DELETE", 
            payload, 
            new Map<string, string>([["userid", String(user.Id)]])
        ).subscribe();   
    }
  
} 