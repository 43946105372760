import { Component } from '@angular/core';
import { PatentsTabContext } from '@core/models/tab-contexts/patents-tab-context';
import { ParametersService } from '@core/services/parameters.service';
import { PatentsService } from '@core/services/patents.service';
import { EpisoftTranslateService } from '@core/services/translate.service';


@Component({
  selector: 'patents-tab',
  templateUrl: './patents-tab.component.html',
  styleUrls: ['./patents-tab.component.scss']
})
export class PatentsTabComponent {  
  tabContext: PatentsTabContext = new PatentsTabContext(this.translateService, this.parametersService, this.patentsService);
  
  constructor(public translateService: EpisoftTranslateService, private parametersService: ParametersService, private patentsService: PatentsService) { 
    this.translateService.onChangeCallback = () => { this.initOptionAddedSubscription(); };
    this.tabContext.sort.selectedOptionCode = "DatePri";
  };

  ngOnInit() {
    this.initOptionAddedSubscription();
  }

  public async initOptionAddedSubscription() {  
    this.tabContext.sort.options = [
      { Code: "DepDate", Label: await this.translateService.getAsyncTrad('common.shared.depDate').toPromise() || '' }, 
      { Code: "DatePri", Label: await this.translateService.getAsyncTrad('common.shared.prioDate').toPromise() || '' },  
      { Code: "PubDate", Label: await this.translateService.getAsyncTrad('common.shared.pubDate').toPromise() || '' },     
    ];
  };
}