import { Component, Input } from '@angular/core';
import { PatentCriteria } from '@core/models/criterias/patent-criteria';
import { Owner } from '@core/models/owner';
import { TabContext } from '@core/models/tab-contexts/tab-context';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'patents-search-panel',
  templateUrl: './patents-search-panel.component.html',
  styleUrls: ['./patents-search-panel.component.scss']
})
export class PatentsSearchPanelComponent {
  @Input() tabContext! : TabContext;

  searchCriterias : PatentCriteria = new PatentCriteria();

  constructor(public translateService: EpisoftTranslateService) {}

  ngOnInit(){
    if (this.tabContext != undefined){
      this.tabContext.resetSearchCriterias();
      this.searchCriterias = this.tabContext.searchCriterias as PatentCriteria;
      this.searchCriterias.DepNum.Alphanum = true;
      this.searchCriterias.PrioNum.Alphanum = true;
      this.searchCriterias.PubNum.Alphanum = true;
    }
  }

  updateExactMatch(newValue: boolean){
    this.searchCriterias.Family.ExactMatch = newValue;
  }

  // Méthodes gérant la liste déroulante "Titulaire"
  ownerLabelSelector : (owner : Owner) => string = owner => owner.Name;
  
  ownerIsActiveSelector : (owner : Owner) => boolean = owner => owner.HasActiveItems; 

  ownerValueSelector : (owner : Owner) => Number = owner => owner.Code;

  updateOwner(newValue: string) {
    let newValueAsNumber: number = Number(newValue);
    newValueAsNumber = isNaN(newValueAsNumber) ? 0 : newValueAsNumber;
    this.searchCriterias.Owner = newValueAsNumber;
  }

  // Méthodes gérant la liste déroulante "Pays" 
  countryLabelSelector : (country: any) => string = country => country.CurrentCountryLabel;

  countryIsActiveSelector: Function = (a: any): boolean => true; //Tous les pays sont toujours proposés par défaut

  countryValueSelector: (country: any) => string = country => String(country.Code);

  updateCountry(newValue: string){
    this.searchCriterias.Country = newValue;
  }

  // Méthodes gérant la liste déroulante "Inventeurs"
  inventorLabelSelector : (inventor : any) => string = inventor => inventor.Name;
  
  inventorIsActiveSelector : Function = (a: any): boolean => true; //Tous les intenteurs sont toujours actifs par défaut

  inventorValueSelector : (inventor : any) => string = inventor => inventor.Name;

  updateInventor(newValue: string) {
    this.searchCriterias.Inventor = newValue;
  }

  updateIncludeInactive(newValue: boolean){
    this.searchCriterias.IncludeInactive = newValue;
  }
}