import { Injectable } from '@angular/core';
import { Observable, catchError, map} from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { LogService } from '@core/services/log.service';
import { EpisoftTranslateService } from './translate.service';
import { FEDUserProfileEntity } from '@core/models/entities/front-end-displayable-entities/fed-user-profile-entity';
import { BERUserProfileEntity } from '@core/models/entities/back-end-raw-entities/ber-user-profile-entity';
import { FEDWalletEntity } from '@core/models/entities/front-end-displayable-entities/fed-wallet-entity';
import { BERWalletEntity } from '@core/models/entities/back-end-raw-entities/ber-wallet-entity';

@Injectable({
    providedIn: 'root'
})

export class WalletService {

    constructor(
        private httpApiService: HttpApiService, 
        private logService: LogService) { }

    public GetWallet(eid : string): Observable<FEDWalletEntity[]>  {
        return this.httpApiService.get<BERWalletEntity[]>("API-Wallet-GET", new Map<string, string>([ [ "episoftid", eid ] ]))
            .pipe(map(wallet => wallet.map(e => FEDWalletEntity.fromBERWalletEntity(e))));
    }
} 