import { BERBaseEntity } from "../back-end-raw-entities/ber-base-entity";

export class FEDBaseEntity implements BERBaseEntity {
    constructor(source : BERBaseEntity) {
        Object.assign(this, source);
    }
    // Données de BERBaseEntity
    RefClient!: string;
    RefGM!: string;
    CodeZen!: string;
    Active!: boolean;
    Match!: boolean;
    Situation!: { $ref: string; FR: string; EN: string; };
    Type!: string;
    // Données connues uniquement côté Front End
    IsSelected: boolean = false;
    SituationLabel: string = "";
    CountryLabel: string = "";
    TypeLabel: string = "";
}
