import { Component, Inject, Input } from '@angular/core';
import { FEDTrademarkEntity } from '@core/models/entities/front-end-displayable-entities/fed-trademark-entity';
import { AuthService } from '@core/services/auth.service';
import { GenericDetailsCardComponent } from '../generic-details-card/generic-details-card.component';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { TabContentPanelComponent } from '@shared/tab-content-panel/tab-content-panel.component';
import { TrademarksService } from '@core/services/trademarks.service';
import { FEDTrademarkFractionEntity } from '@core/models/entities/front-end-displayable-entities/fed-trademark-fraction-entity';
import { CustomTooltipComponent } from '@shared/custom-tooltip/custom-tooltip.component';
import { Router } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'trademark-details-card',
  templateUrl: './trademark-details-card.component.html',
  styleUrls: ['./trademark-details-card.component.scss']
})
export class TrademarkDetailsCardComponent extends GenericDetailsCardComponent {
  @Input({ required: true }) details!: FEDTrademarkEntity;
  @Input({ required: true }) parent!: TabContentPanelComponent;

  showDesignatedCountryToolTip : boolean = false;

  constructor(@Inject(APP_BASE_HREF) public baseHref:string, private router: Router, public translateService: EpisoftTranslateService, authService: AuthService, public trademarksService: TrademarksService) {
    super(authService);
  }

  public selectItem() {
    // On transmet l'info au parent pour mettre à jour la check box de sélection globale
    // On passe par un "input" plutôt que par un évènement classique car le composant est généré dynamiquement et output n'est pas encore géré par ngComponentOutlet
      this.parent.tabContext.selectItem(this.details, !this.details.IsSelected)
      this.parent.itemSelectionStateChangedCallback(this.details.IsSelected);

  }

  public selectFraction(ref: string) {
    // On transmet l'info au parent pour mettre à jour la check box de sélection globale
    // On passe par un "input" plutôt que par un évènement classique car le composant est généré dynamiquement et output n'est pas encore géré par ngComponentOutlet
      let fraction: FEDTrademarkFractionEntity | undefined = this.details.Fractions.find(x => x.RefGM === ref);
      if (fraction !== undefined) {
        fraction.IsSelected = !fraction.IsSelected;
        // La (dé)sélection d'une fraction d'une marque inter entraîne sa désélection globale
        // La sélection de toutes les fractions d'une marque inter entraîne sa sélection globale
        let allSelected: boolean = this.details.Fractions.find(x => x.IsSelected !== true) === undefined;
        if((this.details.IsSelected !== allSelected)) {
          this.details.IsSelected = !this.details.IsSelected;
          this.parent.itemSelectionStateChangedCallback(this.details.IsSelected);
        }
      }
  }

  public openDetails(refgm: string, GUID: string) {
    const url = this.router.createUrlTree([this.baseHref, "home", `ma`, refgm, GUID]).toString();
    window.open(url, '_blank');
  }

  public getDesignatedCountriesLabels(label: string): string {
    const currentLang = this.translateService.getCurrentLanguage();
    let popupText: string = "";
    this.translateService.acquire('common.shared.countryDes').subscribe((res: string) => {
      popupText = res + '\n' + label;
    }); 
    return popupText;
   }

  public getImageUrl(e: FEDTrademarkEntity): string {
    if(e.Root.Type === "VB") return "";
    return this.trademarksService.GetImageUrl(e.Root.RefGM);
  }

  // Connexion ZenWeb
  public openDocuments(codeZen : string) : void {   
    let url: string = `${environment.zenWebUrl}/content/B/${codeZen}?token=${this.authService.getToken()}`;
    window.open(url);
  };

}
