<mat-expansion-panel class="search-panel mat-elevation-z0" expanded="true">
    <mat-expansion-panel-header>
        <div class="panel-header">
            <label class="epw-label bold title">{{ translateService.getLabel('common.groups.general') }}</label>
        </div>
    </mat-expansion-panel-header>
    <div class="panel-content">
        <search-panel-input-text 
            headerText="{{ translateService.getLabel('soleau.props.name') }}" 
            [showTextFilter]="true" 
            [criteria]="this.searchCriterias.Title"
        />
        <search-panel-input-combo-box 
            headerText="{{ translateService.getLabel('common.shared.owner') }}"
            [items]="this.tabContext.parameters.owners"
            [labelSelector]="ownerLabelSelector"
            [valueSelector]="ownerValueSelector"
            [activeSelector]="ownerIsActiveSelector"
            (criteriaValueChanged)="this.updateOwner($event)"
        />
        <div class="panel-row-container">
            <div class="split-50">
                <search-panel-input-date-range 
                    headerText="{{ translateService.getLabel('common.shared.depDate') }}" 
                    [criteria]="this.searchCriterias.DepDate"
                    />
                </div>
                <div class="gm-margin-right-15px"></div>
                <div class="split-50">        
                <search-panel-input-text 
                    headerText="{{ translateService.getLabel('common.shared.depNum') }}" 
                    [showTextFilter]="true" 
                    [criteria]="this.searchCriterias.DepNum"
                />
            </div>
        </div>              
        <search-panel-input-dual-refs 
            headerTextRefGM="{{ translateService.getLabel('common.shared.refGM') }}"
            headerTextRefClient="{{ translateService.getLabel('common.shared.refClient') }}"
            [criteriaRefGM]="this.searchCriterias.RefGM"
            [criteriaRefClient]="this.searchCriterias.Client"
        />
        <search-panel-input-check-box
            headerText="{{ translateService.getLabel('common.shared.closed') }}"
            checkBoxLabel="{{ translateService.getLabel('common.shared.closedHelp') }}"
            (criteriaValueChanged)="this.updateIncludeInactive($event)"
        />
    </div>
</mat-expansion-panel>
<mat-expansion-panel class="search-panel mat-elevation-z0">
    <mat-expansion-panel-header>            
    <div class="panel-header">
        <label class="epw-label bold title">{{ translateService.getLabel('common.groups.dueDates')  }}</label>
    </div>
    </mat-expansion-panel-header>
    <search-panel-input-date-range 
    headerText="{{ translateService.getLabel('common.shared.dueDates') }}" 
    [criteria]="this.searchCriterias.DueDates"
    />
</mat-expansion-panel> 