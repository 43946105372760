import { Injectable } from '@angular/core';
import { Observable, catchError, map} from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { LogService } from '@core/services/log.service';
import { EpisoftTranslateService } from './translate.service';
import { FEDUserProfileEntity } from '@core/models/entities/front-end-displayable-entities/fed-user-profile-entity';
import { BERUserProfileEntity } from '@core/models/entities/back-end-raw-entities/ber-user-profile-entity';

@Injectable({
    providedIn: 'root'
})

export class UserProfilesService {

    constructor(
        private httpApiService: HttpApiService, 
        private logService: LogService) { }

    public GetProfiles(eid : string): Observable<FEDUserProfileEntity[]>  {
        return this.httpApiService.get<BERUserProfileEntity[]>("API-UserProfiles-GET", new Map<string, string>([ [ "episoftid", eid ] ]))
            .pipe(map(profiles => profiles.map(profile => FEDUserProfileEntity.fromBERUserProfileEntity(profile))));
    }

    public CreateProfile(profile : FEDUserProfileEntity) : void{
        let payload = {
            AuthorizationLevel : profile.AuthorizationLevel,
            Name : profile.Name,
            AccessFinancialData : profile.AccessFinancialData,
            Wallet: profile.Wallet
        };
        this.httpApiService.post<FEDUserProfileEntity>("API-UserProfiles-POST", payload, new Map<string, string>()).subscribe();
    }

    public UpdateProfile(profile : FEDUserProfileEntity) : void{
        let payload: any = {
            Id: profile.Id,
            Name: profile.Name,
            AccessFinancialData : profile.AccessFinancialData,
            AuthorizationLevel : profile.AuthorizationLevel,
            Wallet: profile.Wallet,
        };
     
        this.httpApiService.patch(
            "API-UserProfiles-PATCH", 
            payload, 
            new Map<string, string>([["profileid", String(profile.Id)]])
        ).subscribe();      
    }

    public DeleteProfile(profile : FEDUserProfileEntity) {
        let payload =  {}; // Aucun payload pour cette API
        this.httpApiService.delete(
            "API-UserProfiles-DELETE", 
            payload, 
            new Map<string, string>([["profileid", String(profile.Id)]])
        ).subscribe();   
    }

} 