export abstract class DateHelper {
    public static compareDates(dateA: Date, dateB: Date): number {
        return new Date(dateA).getTime() - new Date(dateB).getTime();
    }

    public static formatDate(date: Date): string {
        // Récupère les composants de la date (année, mois et jour)
        const year = date.getFullYear();
        const month = this.padZero(date.getMonth() + 1);
        const day = this.padZero(date.getDate());
        // Formatte la date au format "YYYY-MM-DD"
        return `${year}-${month}-${day}`;
    }

    public static padZero(value: number): string {
        // Ajoute un zéro au début si la valeur est inférieure à 10
        return value < 10 ? `0${value}` : `${value}`;
    }
}