import { BERTrademarkEntity } from "../back-end-raw-entities/ber-trademark-entity";
import { FEDBaseEntity } from "./fed-base-entity";
import { FEDTrademarkFractionEntity } from "./fed-trademark-fraction-entity";
import { FEDTrademarkRootEntity } from "./fed-trademark-root-entity";

export class FEDTrademarkEntity extends FEDBaseEntity implements BERTrademarkEntity {
    constructor(source : BERTrademarkEntity) {
        super(source);
        Object.assign(this, source);
        // Transtypage manuel des objets composés depuis le type Back vers le pendant Front
        this.Root = new FEDTrademarkRootEntity(source.Root);
        this.Fractions = source.Fractions.map(e => new FEDTrademarkFractionEntity(e));
    }
    // Données de BERTrademarkEntity
    GUID!: string;
    Root!: FEDTrademarkRootEntity;              // Transtypage vers le type Front End
    Fractions! : FEDTrademarkFractionEntity[];  // Transtypage vers le type Front End
    // Données connues uniquement côté Front End
    override Active: boolean = this.Root.Active; // Le statut "Actif" est déterminé par la marque mère
}
