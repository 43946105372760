import { Injectable, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError, of } from 'rxjs';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApiPathsService } from '@core/services/api-paths.service';
import { LogService } from '@core/services/log.service';

@Injectable({
  providedIn: 'root'
})
export class HttpApiService {

  constructor(private http: HttpClient, private router: Router, private apiPathsService: ApiPathsService, private logService: LogService) { }

  public get<T>(apiId: string, keysDictionary: Map<string, string> = new Map<string, string>()): Observable<T> {
    let url: string = "";

    url = this.apiPathsService.GetUrl(apiId, keysDictionary); 
    return this.http.get<T>(url).pipe(
      map((response: any) => {
        return response;
      })      
    );
  }

  public post<T>(apiId: string, data: any, keysDictionary: Map<string, string> = new Map<string, string>(), handleError: boolean = true): Observable<T> {
    const url: string = this.apiPathsService.GetUrl(apiId, keysDictionary);
    return this.http.post<T>(url, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        this.logService.error(`Erreur ${error.status} - ${error.statusText}`);
        if (!handleError)
          throw error;
        if (error.status === 401) {
          // Redirection vers la page d'authentification (login)
          this.router.navigate(['/login']);
        }
        // Retourne un observable d'erreur pour indiquer que l'erreur a été gérée
        return of(error as T);

      }));
  }
      
  public patch(apiId: string, data: any, keysDictionary: Map<string, string> = new Map<string, string>(), handleError: boolean = true): Observable<any> {
    const url: string = this.apiPathsService.GetUrl(apiId, keysDictionary);
    return this.http.patch(url, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        this.logService.error(`Erreur ${error.status} - ${error.statusText}`);
        if (!handleError)
          throw error;
        if (error.status === 401) {
          // Redirection vers la page d'authentification (login)
          this.router.navigate(['/login']);
        }
        return of(error);

      }));
  }

  public delete(apiId: string, data: any, keysDictionary: Map<string, string> = new Map<string, string>(), handleError: boolean = true): Observable<any> {
    const url: string = this.apiPathsService.GetUrl(apiId, keysDictionary);
    return this.http.delete(url, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        this.logService.error(`Erreur ${error.status} - ${error.statusText}`);
        if (!handleError)
          throw error;
        if (error.status === 401) {
          // Redirection vers la page d'authentification (login)
          this.router.navigate(['/login']);
        }
        return of(error);

      }));
  }

  public resolve(route: ActivatedRouteSnapshot): string {
    let refgm: string = route.paramMap.get('id') ?? '';
    return refgm;
  }

  public downloadFile(apiId: string, keysDictionary: Map<string, string> = new Map<string, string>(), fileName: string): void {
    const url: string = this.apiPathsService.GetUrl(apiId, keysDictionary);
    console.log("Downloading file:", url);
    // Effectuer la requête GET pour télécharger le fichier
    this.http.get(url, { responseType: 'blob' }).subscribe((response: Blob) => {
      // Créer un objet URL à partir du contenu binaire du fichier
      const fileURL = URL.createObjectURL(response);

      // Créer un lien de téléchargement
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = fileName;

      // Ajouter le lien au corps du document
      document.body.appendChild(link);

      // Déclencher automatiquement le téléchargement du fichier
      link.click();

      // Nettoyer l'objet URL et supprimer le lien du corps du document
      URL.revokeObjectURL(fileURL);
      document.body.removeChild(link);
    });
  }
}
