import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { SearchString, SearchStringComparison } from '@core/models/criterias/search-string';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'search-panel-input-text-autocomplete',
  templateUrl: './search-panel-input-text-autocomplete.component.html',
  styleUrls: ['./search-panel-input-text-autocomplete.component.scss']
})
export class SearchPanelInputTextAutocompleteComponent {
    @Input({ required: true }) headerText : string = '';
    @Input() showTextFilter  : boolean = false;
    @Input() criteria: SearchString = new SearchString();
    @Input({ required: true }) typeAhead : string[] = [];

    constructor(public translateService: EpisoftTranslateService) {
    }

    getComparisonOptions : Function = () => SearchString.ComparisonOptions;

    getGlyphiconClass : Function = (comp: SearchStringComparison) => SearchString.GetGlyphiconClass(comp);  

    updateCriteria(comp: SearchStringComparison): void {
      this.criteria.Comp = comp;
    }

    getValues(): string[]{  
      // On affiche le filtre uniquement si on a saisi au moins deux caractères
      if(this.criteria.Txt.length < 2) return [];
      let filteredValues : string[] = this.typeAhead.filter(option => option.toLowerCase().includes(this.criteria.Txt));
      return filteredValues;
    }

    updateValue(newvalue: string){
      this.criteria.Txt = newvalue;
    }
}

// Ce pipe permet de surligner le critère de recherche au sein de la liste proposée en combo (aide à la saisie)
@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    if (!search || !text || typeof search !== 'string' || typeof text !== 'string') {
      return text;
    }

    // Escape des caractères spéciaux dans la recherche
    const escapedSearch = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

    // Création d'une expression régulière pour repérer le critère de recherche
    const regex = new RegExp(`(${escapedSearch})`, 'gi');

    // Remplacement des éléments trouvés en ajoutant une balise "strong" pour mettre en gras
    // On ajoute aussi des span, ce qui permet de conserver les éventuels espaces (qui sautent sinon)
    return "<span><span>" + text.replace(regex, match => `</span><strong>${match}</strong><span>`) + "</span></span>";
  }
}



