import { Component, EventEmitter, Input, Output } from '@angular/core';
import { timestamp } from 'rxjs';

@Component({
  selector: 'search-panel-input-check-box',
  templateUrl: './search-panel-input-check-box.component.html',
  styleUrls: ['./search-panel-input-check-box.component.scss']
})
export class SearchPanelInputCheckBoxComponent {
  @Input({ required: false }) headerText : string = '';
  @Input({ required: true }) checkBoxLabel : string = '';
  @Output() criteriaValueChanged = new EventEmitter<boolean> ();
  id : string = Date.now().toLocaleString();
 
  selectedValue: boolean = false;

  onCriteriaValueChanged() {
    this.criteriaValueChanged.emit(this.selectedValue);
}
}
