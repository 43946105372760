import { DomainNamesService } from "@core/services/domain-names.service";
import { EpisoftTranslateService } from '@core/services/translate.service';
import { TabContext } from "./tab-context";
import { DateHelper } from "@core/helpers/date-helper";
import { StringHelper } from "@core/helpers/string-helper";
import { Observable, switchMap } from "rxjs";
import { ParametersService } from "@core/services/parameters.service";
import { DomainNameCriteria } from "../criterias/domain-name-criteria";
import { GenericCriteria } from "../criterias/generic-criteria";
import { FEDDomainNameEntity } from "../entities/front-end-displayable-entities/fed-domain-name-entity";
import { ExportActionType } from "../export-action";

export class DomainNamesTabContext extends TabContext {

  constructor(override translateService: EpisoftTranslateService, override parametersService : ParametersService, private domainNamesService : DomainNamesService) {
        super(translateService, parametersService);
    }
    
    static readonly moduleId = "domainNames";

    override getModuleId(): string {
      return DomainNamesTabContext.moduleId;
    }

    protected override customLoadData(searchCriterias : GenericCriteria | undefined): Observable<any> {
      let customSearchCriterias = searchCriterias as DomainNameCriteria;
      return this.domainNamesService.GetDomainNames(customSearchCriterias);
    }

    override loadParameters() : void {
      this.domainNamesService.GetParams().subscribe(r => {
          this.parameters.owners = r.Owners;
          this.sortEntityParameters();
        }
      ); 
    }

    override sortData() {
        let rawData: FEDDomainNameEntity[] = this.data;
        rawData.sort((a, b) => {
            // Fonction de comparaison en fonction du sortCode
            switch (this.sort.selectedOptionCode) {
              case 'BookingDate':
                return DateHelper.compareDates(new Date(a.BookingDate), new Date(b.BookingDate));
              case 'RenewDueDates':
                return DateHelper.compareDates( (a.RenewDueDates.length > 0 ? new Date(a.RenewDueDates[0].Date) : new Date(1970, 1, 1)), 
                                          (b.RenewDueDates.length > 0 ? new Date(b.RenewDueDates[0].Date) : new Date(1970, 1, 1)));
              case 'DNS':
                return StringHelper.compareStrings(a.DNS, b.DNS);
              case 'Owner':
                return StringHelper.compareStrings(a.Owner.Name, b.Owner.Name);
              case 'SituationLabel':
                return StringHelper.compareStrings(a.SituationLabel, b.SituationLabel);
              default:
                return 0; // Aucun tri par défaut
            }
          } );
      
          if(!this.sort.useSortOrderASC) {
            rawData.reverse();
          }
      
          this.data = rawData;
    }

    public override resetSearchCriterias(): void {
      if (this.searchCriterias === null) this.searchCriterias = new DomainNameCriteria();
      this.searchCriterias?.resetToDefault();
    }

    public override export(exportType: ExportActionType): void {
      this.domainNamesService.Export(exportType, this.data.filter(d => d.IsSelected));
    }

    public override getAvailableExportTypes(): ExportActionType[] {
      return [ExportActionType.List];
    }
  
}