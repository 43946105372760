import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api.service';
import { ApiPathsService } from '@core/services/api-paths.service';
import { EntityRef } from '@core/models/entity-ref';
import { Owner } from '@core/models/owner';
import { EpisoftTranslateService } from './translate.service';
import { AuthService } from './auth.service';
import { ParametersService } from './parameters.service';
import { Observable, map, switchMap } from 'rxjs';
import { HydratationHelper } from '@core/helpers/hydratation-helper';
import { FEDPatentFamilyEntity } from '@core/models/entities/front-end-displayable-entities/fed-patent-family-entity';
import { BERPatentFamilyEntity } from '@core/models/entities/back-end-raw-entities/ber-patent-family-entity';
import { PatentCriteria } from "@core/models/criterias/patent-criteria";
import { FEDPatentDetails } from '@core/models/details/front-end-displayable-details/fed-patent-details';
import { ExportActionType } from '@core/models/export-action';
import { ExportService } from './export.service';
import { environment } from 'src/environments/environment';

  export interface PatentData {
    Refs: EntityRef[];
    Entities: BERPatentFamilyEntity[];
  }
  
  export interface PatentParamsData {
    TypeTitles: EntityRef[];
    Owners: Owner[];
    Inventors: { Name: string;}[];
    typeDueDates: EntityRef[];
  }

  @Injectable({
    providedIn: 'root'
  })
  export class PatentsService {
  
    constructor(
        private httpApiService: HttpApiService, 
        private translateService: EpisoftTranslateService, 
        private parametersService : ParametersService, 
        private apiPathsService: ApiPathsService, 
        private authService: AuthService, 
        private exportService: ExportService) { }
    
    public GetPatents(searchCriterias: PatentCriteria | null = null): Observable<FEDPatentFamilyEntity[]> {
      let specificData: PatentParamsData;
        // Chargement des paramètres globaux
        return this.parametersService.Get().pipe(
          // Chargement des paramètres spécifiques
          switchMap( _ => this.GetParams()),
          // Chargement des données
          switchMap( data => {
            specificData = data;
            return this.httpApiService.post<PatentData>("API-Patents-POST", searchCriterias);
          }),
          // Hydratation des données
          map((data: PatentData) => { 
            let entities : FEDPatentFamilyEntity[] = (data.Entities ?? []).map(e => new FEDPatentFamilyEntity(e));
            HydratationHelper.HydratePatents(entities, data.Refs, specificData.TypeTitles, this.parametersService, this.translateService.getCurrentLanguage());
            return entities;
          })
        );       
    }
  
    public GetParams(): Observable<PatentParamsData> {
      let data: Observable<PatentParamsData> = this.httpApiService.get<PatentParamsData>("API-PatentsParams-GET");
      return data.pipe(map((data: PatentParamsData) => { 
        return data;
      }));
    }

    public getDetails(refgm: string): Observable<FEDPatentDetails>{
      let data: Observable<FEDPatentDetails> = this.httpApiService.get<FEDPatentDetails>("API-PatentsDetails-GET", new Map<string, string>([["refgm", refgm]]));
      return data.pipe(map((data: FEDPatentDetails) => {
        HydratationHelper.HydratePatentDetails(data, this.translateService.getCurrentLanguage());
        return data;
      }));
    }
  
    public GetImageUrl(refgm: string) : string {
      // L'URL d'une image est du type : https://episoft-test.germainmaureau.com/api/zen/epi/patents/BR045730/image?token=FOO
      return environment.backendUrl + 
            this.apiPathsService.GetUrl("API-PatentsImage-GET", new Map<string, string>([["refgm", refgm], ["token", this.authService.getToken()] ]));
    }

    public Export(exportType: ExportActionType, items: FEDPatentFamilyEntity[]) : void {
      // Export brevets : mode liste ou famille
      // Les colonnes sont les mêmes, mais pas l'API
      let api : string;
      switch(exportType) {
        case ExportActionType.List : 
          api = "API-PatentsExport-POST";
          break;
        case ExportActionType.Family : 
          api = "API-PatentsExportFamily-POST";
          break;
        default: 
          throw new Error("Invalid export type");
      }

      // Appel du service générique d'export
      this.exportService.Export(
        // API
        api,
        // Références des numéros de dossier
        // Attention, on a des familles en entrée ! 
        // Il faut donc récupérer tous les numéros de dossiers de tous les membres de toutes les familles
        items.flatMap(f => f.Patents.map(p => p.RefGM)),
        // Colonnes
        [ 
          { Code: "RefGM", Text: "common.shared.refGM" },         
          { Code: "RefClient", Text: "common.shared.refClient" },
          { Code: "Title", Text: "patent.props.name" },
          { Code: "Image", Text: "" },
          { Code: "Owners", Text: "common.shared.owners" },
          { Code: "Inventors", Text: "common.shared.inventors" },
          { Code: "DepositCountry", Text: "common.shared.countryDep" },
          { Code: "Situation", Text: "common.shared.status" },
          { Code: "OriginDepositNum", Text: "common.shared.depNum"},
          { Code: "OriginDepositDate", Text: "common.shared.depDate"},
          { Code: "PublicationNum", Text: "common.shared.pubNum"},
          { Code: "PublicationDate", Text: "common.shared.pubDate"},
          { Code: "PatentNum", Text: "patent.props.patentNum"},
          { Code: "PatentDate", Text: "patent.props.patentDate"}
        ],
        // Textes
        [
          { Code: "Title", Text: "patent.name" }
        ],
        // Onglets
        []
      );
    }
  }  