import { Component } from '@angular/core';
import { TrademarksTabContext } from '@core/models/tab-contexts/trademarks-tab-context';
import { ParametersService } from '@core/services/parameters.service';
import { TrademarksService } from '@core/services/trademarks.service';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'trademarks-tab',
  templateUrl: './trademarks-tab.component.html',
  styleUrls: ['./trademarks-tab.component.scss']
})
export class TrademarksTabComponent {
  tabContext: TrademarksTabContext = new TrademarksTabContext(this.translateService, this.parametersService, this.trademarksService);

  constructor(public translateService: EpisoftTranslateService, private parametersService: ParametersService, private trademarksService: TrademarksService) { 
    this.translateService.onChangeCallback = () => { this.initOptionAddedSubscription(); };
    this.tabContext.sort.selectedOptionCode = "Name";
  };


  ngOnInit() {
    this.initOptionAddedSubscription();
    this.tabContext.loadTypeAhead();
  }

  public async initOptionAddedSubscription() {  
    this.tabContext.sort.options = [
      { Code: "CurrRegDate", Label: await this.translateService.getAsyncTrad('common.shared.regDate').toPromise() || '' },  
      { Code: "DepDate", Label: await this.translateService.getAsyncTrad('common.shared.depDate').toPromise() || '' }, 
      { Code: "Name", Label: await this.translateService.getAsyncTrad('trademark.props.name').toPromise() || '' },     
      { Code: "RenewDueDates", Label: await this.translateService.getAsyncTrad('common.shared.renewDates').toPromise() || '' },
      { Code: "DepositCountryLabel", Label: await this.translateService.getAsyncTrad('common.shared.country').toPromise() || '' },
      { Code: "SituationLabel", Label: await this.translateService.getAsyncTrad('common.shared.status').toPromise() || '' },
      { Code: "Owner", Label: await this.translateService.getAsyncTrad('common.shared.owner').toPromise() || '' },
      { Code: "TypeLabel", Label: await this.translateService.getAsyncTrad('common.shared.type').toPromise() || '' },
    ];
  };
}
