import { Component, Input, OnInit } from '@angular/core';
import { TabContext } from '@core/models/tab-contexts/tab-context';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { PatentsSearchPanelComponent } from '@shared/search-panels/patents-search-panel/patents-search-panel.component';
import { TrademarksSearchPanelComponent } from '@shared/search-panels/trademarks-search-panel/trademarks-search-panel.component';
import { DrawingModelsSearchPanelComponent } from '@shared/search-panels/drawing-models-search-panel/drawing-models-search-panel.component';
import { SoleauSearchPanelComponent } from '@shared/search-panels/soleau-search-panel/soleau-search-panel.component';
import { DomainNamesSearchPanelComponent } from '@shared/search-panels/domain-names-search-panel/domain-names-search-panel.component';
import { WatchingsSearchPanelComponent } from '@shared/search-panels/watchings-search-panel/watchings-search-panel.component'
import { OffTitlesSearchPanelComponent } from '@shared/search-panels/off-titles-search-panel/off-titles-search-panel.component';

  // Dictionnaire pour mapper les modules aux composants correspondants
  const moduleComponentMap: { [moduleId: string]: any } = {
    'patents': PatentsSearchPanelComponent,
    'trademarks': TrademarksSearchPanelComponent,
    'drawingModels': DrawingModelsSearchPanelComponent,
    'soleau': SoleauSearchPanelComponent,
    'domainNames': DomainNamesSearchPanelComponent,
    'watchings' : WatchingsSearchPanelComponent,
    'offTitles': OffTitlesSearchPanelComponent
  };

@Component({
  selector: 'tab-search-panel',
  templateUrl: './tab-search-panel.component.html',
  styleUrls: ['./tab-search-panel.component.scss']
})
export class TabSearchPanelComponent implements OnInit {
  @Input() tabContext! : TabContext;

  selectedComponent : any; 

  constructor(public translateService: EpisoftTranslateService) {}
  
  panelOpenState = false;

  selectOwner() {

  }

  openCalendar() {

  }

  ngOnInit() {   
    if(this.tabContext != undefined) {
      // Chargement des paramètres de recherche propre au module
      this.tabContext.loadParameters();
      // Initialisation du composant de recherche propre au module
      this.selectedComponent = moduleComponentMap[this.tabContext.getModuleId()];
      // Permet au composant gérant l'affichage du portefeuille de forcer la réinitialisation des critères
      this.tabContext.resetSearchPanelCallback = () => {
        this.ngOnInit();
      }
    }
  }

  search() {
    // On passe le booléen à true pour indiquer que l'on souhaite filtrer avec les critères de recherche
    this.tabContext.loadData(true);
  }

}
