import { Injectable } from "@angular/core";
import { HttpApiService } from "./http-api.service";
import { EpisoftTranslateService } from "./translate.service";
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";

interface ExportPayloadTuple{
    Code: string;
    Text: string
}

interface ExportPayload {
    Language: string;
    RefsGM: string[];
    Columns: ExportPayloadTuple[];
    Texts: ExportPayloadTuple[];
    SheetNames: ExportPayloadTuple[] | null;
    StateDate: Date;
  }

@Injectable({
    providedIn: 'root'
  })
  export class ExportService {
  
    constructor(private httpApiService: HttpApiService, private authService : AuthService,  private translateService: EpisoftTranslateService) { 

    }

    public Export(exportApiName: string, refs: string[], columns: ExportPayloadTuple[], texts: ExportPayloadTuple[], sheetNames: ExportPayloadTuple[]) : void {
      // Traduction des colonnes et des textes
        columns.forEach(c => this.translateService.getAsyncTrad(c.Text).subscribe((res: string) => {c.Text = res;}));
        texts.forEach(t => this.translateService.getAsyncTrad(t.Text).subscribe((res: string) => {t.Text = res;}));
        sheetNames.forEach(t => this.translateService.getAsyncTrad(t.Text).subscribe((res: string) => {t.Text = res;}));
        // Construction du payload
        const payload: ExportPayload = {
          Language: this.translateService.getCurrentLanguage(),
          RefsGM: refs,
          Columns: columns,
          Texts: texts,
          SheetNames: sheetNames,
          StateDate: new Date()
      };
      // Appel de l'API dédiée - elle va renvoyer un identifiant unique que l'on va utiliser pour télécharger le fichier via une API commune à tous les modules et tous les exports
      this.httpApiService.post<string>(exportApiName, payload).pipe(
          map(d => { 
            this.httpApiService.downloadFile("API-Export-GET", new Map<string, string>([["refexp", d],["token", this.authService.getToken()]]), "export.xlsx");
          })).subscribe();
    }
  }  