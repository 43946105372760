import { BERPatentFamilyEntity } from "../back-end-raw-entities/ber-patent-family-entity";
import { FEDBaseEntity } from "./fed-base-entity";
import { FEDPatentMemberEntity } from "./fed-patent-member-entity ";

export class FEDPatentFamilyEntity extends FEDBaseEntity implements BERPatentFamilyEntity {
    constructor(source : BERPatentFamilyEntity) {
        super(source);
        Object.assign(this, source);
        // Transtypage manuel des objets composés depuis le type Back vers le pendant Front
        this.Patents = source.Patents.map(e => new FEDPatentMemberEntity(e));
    }
    // Données de BERPatentFamilyEntity
    FamilyId!: string;
    FamilyNum!: string;
    Title!: { FR: string; EN: string; };
    TitleShort!: { FR: string; EN: string; };
    Summary!: string;
    RefImage!: string;
    DatePri!: Date;
    Inventors!: { Name: string;}[];
    Owners!: { Name: string; }[];
    Patents!: FEDPatentMemberEntity[];

    // Données connues uniquement côté Front End
    OwnersLabel!: string;
    InventorsLabel!: string;
    TitleLabel!: string;
    FamilyTitleLabel!: string;
}
