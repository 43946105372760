import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { ApiPathsService } from '@core/services/api-paths.service';
import { EntityRef } from '@core/models/entity-ref';
import { Owner } from '@core/models/owner';
import { HydratationHelper } from '@core/helpers/hydratation-helper';
import { EpisoftTranslateService } from './translate.service';
import { AuthService } from './auth.service';
import { ParametersService } from './parameters.service';
import { FEDDrawingModelEntity } from '@core/models/entities/front-end-displayable-entities/fed-drawing-model-entity';
import { BERDrawingModelEntity } from '@core/models/entities/back-end-raw-entities/ber-drawing-model-entity';
import { DrawingModelCriteria } from "@core/models/criterias/drawing-model-criteria";
import { FEDBaseEntity } from '@core/models/entities/front-end-displayable-entities/fed-base-entity';
import { ExportActionType } from '@core/models/export-action';
import { ExportService } from './export.service';
import { FEDDrawingModelDetails } from '@core/models/details/front-end-displayable-details/fed-drawing-model-details';
import { environment } from 'src/environments/environment';

export interface DrawingModelData {
  Refs: EntityRef[];
  Entities: BERDrawingModelEntity[];
}

export interface DrawingModelParamsData {
  TypeTitles: EntityRef[];
  Owners: Owner[];
  typeDueDates: EntityRef[];
}

@Injectable({
  providedIn: 'root'
})
export class DrawingModelsService {

  constructor(
    private httpApiService: HttpApiService, 
    private translateService: EpisoftTranslateService, 
    private parametersService : ParametersService, 
    private apiPathsService: ApiPathsService, 
    private authService: AuthService,
    private exportService: ExportService) { }

  public GetDrawingModels(searchCriterias: DrawingModelCriteria | null = null): Observable<FEDDrawingModelEntity[]> {
    let specificData: DrawingModelParamsData;
      // Chargement des paramètres globaux
      return this.parametersService.Get().pipe(
        // Chargement des paramètres spécifiques
        switchMap( _ => this.GetParams()),
        // Chargement des données
        switchMap( data => {
          specificData = data;
          return this.httpApiService.post<DrawingModelData>("API-DrawingModels-POST", searchCriterias);
        }),
        // Hydratation des données
        map((data: DrawingModelData) => { 
          let entities : FEDDrawingModelEntity[] = (data.Entities ?? []).map(e => new FEDDrawingModelEntity(e));
          HydratationHelper.HydrateDrawingModels(entities, data.Refs, specificData.TypeTitles, this.parametersService, this.translateService.getCurrentLanguage());
          return entities;
        })
      );       
  }

  public GetParams(): Observable<DrawingModelParamsData> {
    let data: Observable<DrawingModelParamsData> = this.httpApiService.get<DrawingModelParamsData>("API-DrawingModelsParams-GET");
    return data.pipe(map((data: DrawingModelParamsData) => { 
      return data;
    }));
  }

  public getDetails(refgm: string, GUID: string): Observable<FEDDrawingModelDetails>{
    let data: Observable<FEDDrawingModelDetails> = this.httpApiService.get<FEDDrawingModelDetails>("API-DrawingModelsDetails-GET", new Map<string, string>([["refgm", refgm],["guid", GUID]]), );
    return data.pipe(map((data: FEDDrawingModelDetails) => { 
       HydratationHelper.HydrateDrawingModelDetails(data, this.translateService.getCurrentLanguage());
       return data;
     })); 
   }

  public getTypeAhead(): Observable<any>{
    let data: Observable<any> = this.httpApiService.get<any>("API-DrawingModelsTypeAhead-GET");
    return data.pipe(map((data: any) => { 
      return data;
    }));
  }

  public GetImageUrl(refgm: string, idx: number = 0) : string {  
    // Construction des paramètres de l'url: idx n'est utile que si le numéro de vue est renseigné
    let params = new Map<string, string>([["refgm", refgm], ["token", this.authService.getToken()], 
      ["idx", idx > 0 ? idx.toString() : ""], 
    ])
    return environment.backendUrl + this.apiPathsService.GetUrl("API-DrawingModelsImage-GET", params);
  }

  public Export(exportType: ExportActionType, items: FEDBaseEntity[]) : void {
    // Export modèles : mode dépôt, design ou carto
    // Les colonnes sont les mêmes, mais pas l'API
    // Certains paramètres changent également pour l'export carto
    let api : string;
    let texts : any;
    let sheets : any;
    switch(exportType) {
      case ExportActionType.ListByDesign : 
        api = "API-DrawingModelsExportByDesign-POST";
        texts = [
          { Code: "Title", Text: "model.name" },
          { Code: "ExtraHeader", Text: "design.export.design"}
        ];
        sheets = []; // Pas d'onglet
        break;
        case ExportActionType.ListByFiling : 
        api = "API-DrawingModelsExportByFiling-POST";
        texts = [
          { Code: "Title", Text: "model.name" },
          { Code: "ExtraHeader", Text: "design.export.design"}
        ];
        sheets = []; // Pas d'onglet
        break;
      case ExportActionType.Map : 
        api = "API-DrawingModelsExportMap-POST";
        texts = [
          { Code: "TXT_LEGEND", Text: "common.exportMap.texts.TXT_LEGEND" },
          { Code: "TXT_LEGEND_COUNTRY", Text: "common.exportMap.texts.TXT_LEGEND_COUNTRY" },
          { Code: "TXT_LEGEND_LABEL_ON", Text: "common.exportMap.texts.TXT_LEGEND_LABEL_ON" },
          { Code: "TXT_LEGEND_LABEL_OFF", Text: "common.exportMap.texts.TXT_LEGEND_LABEL_OFF" }
        ];
        sheets = [
          { Code: "TXT_WORLD", Text: "common.exportMap.sheets.TXT_WORLD"},
          { Code: "TXT_AFRICA", Text: "common.exportMap.sheets.TXT_AFRICA"},
          { Code: "TXT_AMERICA", Text: "common.exportMap.sheets.TXT_AMERICA"},
          { Code: "TXT_ASIA", Text: "common.exportMap.sheets.TXT_ASIA"},
          { Code: "TXT_EUROPE", Text: "common.exportMap.sheets.TXT_EUROPE"}
        ];
        break;
      default: 
        throw new Error("Invalid export type");
    }

  // Appel du service générique d'export
  this.exportService.Export(
    // API
    api,
    // Références des dossiers à exporter
    items.map(i => i.RefGM),
    // Colonnes
    [ 
      { Code: "RefGM", Text: "common.shared.refGM" },
      { Code: "Title", Text: "model.props.name"},
      { Code: "Design", Text: "design.export.design"},
      { Code: "Owners", Text: "common.shared.owners"},
      { Code: "DepositCountry", Text: "common.shared.countryDep"},
      { Code: "DepositKind", Text: "common.shared.depKind"},
      { Code: "OriginDepositDate", Text: "common.shared.depDate"},
      { Code: "CurrentRegistrationNum", Text: "common.shared.regNum"},
      { Code: "CurrentRegistrationDate", Text: "common.shared.date"},
      { Code: "Situation", Text: "common.shared.status" },
      { Code: "RenewDueDates", Text: "common.shared.renewDates" },
      { Code: "OtherDueDates", Text: "common.shared.otherDueDates" }       
    ],
    // Textes
    texts,
    // Onglets
    sheets
  );
}
}
