import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Imports des composants externes
import { AuthGuard } from '@core/services/auth.guard';
import { LoginComponent } from './features/auth/login/login.component';
import { DashboardComponent } from './features/dashboard/dashboard/dashboard.component';
import { AdminTabComponent } from  './features/tabs/admin-tab/admin-tab.component';
import { HomeTabComponent } from 'src/app/features/tabs/home-tab/home-tab.component';
import { PatentsTabComponent } from 'src/app/features/tabs/patents-tab/patents-tab.component';
import { DrawingsModelsTabComponent } from 'src/app/features/tabs/drawings-models-tab/drawings-models-tab.component';
import { OffTitlesTabComponent } from 'src/app/features/tabs/off-titles-tab/off-title-tab.component';
import { DomainNamesTabComponent } from 'src/app/features/tabs/domain-names-tab/domain-names-tab.component';
import { SoleauTabComponent } from 'src/app/features/tabs/soleau-tab/soleau-tab.component';
import { TrademarksTabComponent } from 'src/app/features/tabs/trademarks-tab/trademarks-tab.component';
import { WatchingsTabComponent } from 'src/app/features/tabs/watchings-tab/watchings-tab.component';
import { PatentDetailsPageComponent } from '@shared/details-pages/patent-details-page/patent-details-page.component';
import { TrademarkDetailsPageComponent } from '@shared/details-pages/trademark-details-page/trademark-details-page.component';
import { DrawingModelDetailsPageComponent } from '@shared/details-pages/drawing-model-details-page/drawing-model-details-page.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'home', component: DashboardComponent, canActivate: [AuthGuard], children: [
      // Gestion des onglets
      { path: '', redirectTo: 'home', pathMatch: 'full' }, // Redirige vers 'home' par défaut
      { path: 'home', component: HomeTabComponent },
      { path: 'admin', component: AdminTabComponent },
      { path: 'br', component: PatentsTabComponent },
      { path: 'ma', component: TrademarksTabComponent },
      { path: 'dm', component: DrawingsModelsTabComponent },
      { path: 'do-es', component: SoleauTabComponent },
      { path: 'do-dn', component: DomainNamesTabComponent },
      { path: 'do', component: OffTitlesTabComponent },
      { path: 'su', component: WatchingsTabComponent },
    ]
  },
  { path: 'home/br/:id', component: PatentDetailsPageComponent },
  { path: 'home/ma/:id/:guid', component: TrademarkDetailsPageComponent },
  { path: 'home/dm/:id/:guid', component: DrawingModelDetailsPageComponent  },
  { path: '', redirectTo: '/login', pathMatch: 'full' }, // Redirige vers /login au démarrage
  { path: '**', redirectTo: '/login' } // Redirige vers /login pour les routes inconnues
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
