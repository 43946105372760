<mat-drawer-container class="generic-component">
    <mat-drawer #searchPanel class="generic-component__sidebar" mode="side" opened="true" [opened]="isOpen">
    <tab-search-panel [tabContext]="this.tabContext" />
    </mat-drawer>
    <tab-content-panel 
        (toggleSearchPanel)="this.toggleSearchPanel()"
        [tabContext]="this.tabContext" />
        <div *ngIf="!this.tabContext.isIdle">
            {{ translateService.getLabel('common.loading') }}
        </div>
</mat-drawer-container>