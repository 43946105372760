import { Component } from '@angular/core';
import { SoleauTabContext } from '@core/models/tab-contexts/soleau-tab-context';
import { ParametersService } from '@core/services/parameters.service';
import { SoleauService } from '@core/services/soleau.service';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'soleau-tab',
  templateUrl: './soleau-tab.component.html',
  styleUrls: ['./soleau-tab.component.scss']
})
export class SoleauTabComponent {
  tabContext: SoleauTabContext = new SoleauTabContext(this.translateService, this.parametersService, this.SoleauService);

  constructor(public translateService: EpisoftTranslateService, private parametersService: ParametersService, private SoleauService : SoleauService) {
    this.translateService.onChangeCallback = () => { this.initOptionAddedSubscription(); };
    this.tabContext.sort.selectedOptionCode = "TitleLabel";
  };

  async ngOnInit() {
    this.initOptionAddedSubscription();
  };

  public async initOptionAddedSubscription() {  
    this.tabContext.sort.options = [
      { Code: "DepDate", Label: await this.translateService.getAsyncTrad('common.shared.depDate').toPromise() || '' },      
      { Code: "RenewDueDates", Label: await this.translateService.getAsyncTrad('common.shared.renewDates').toPromise() || '' },
      { Code: "TitleLabel", Label: await this.translateService.getAsyncTrad('soleau.props.name').toPromise() || '' },
      { Code: "SituationLabel", Label: await this.translateService.getAsyncTrad('common.shared.status').toPromise() || '' },
      { Code: "Owner", Label: await this.translateService.getAsyncTrad('common.shared.owner').toPromise() || '' },
    ];
  };
}