import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessagesService } from '@core/services/messages.service';
import { AuthService } from '@core/services/auth.service';
import { LogService } from '@core/services/log.service';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { UsersService } from '@core/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  messageText: string = '';
  login: string = '';
  password: string = '';
  showErrorMessage: boolean = false;
  errorMessage: string = '';

  constructor(
    public translateService: EpisoftTranslateService, 
    private router: Router, 
    private messagesService: MessagesService, 
    private authService: AuthService, 
    private logService : LogService, 
    private usersService : UsersService) { }

  user = { login: '', password: '' };

  public onSubmit() {
    this.showErrorMessage = false;
    this.usersService.currentUser = null;
    this.authService.AttemptLogin(this.login, this.password).subscribe(
      (_) => { 
        this.router.navigate(['/home']);
       }, 
      (error) => {
        this.logService.log("Connexion refusee");
        this.showErrorMessage = true;
        this.errorMessage = error;
      }
      );

  }

  ngOnInit() {
    // Affichage du message d'information éventuel
    this.messagesService.GetMessage().subscribe((response) => {
      this.messageText = (response != null) ?response.Text : "";
    });
    console.log(environment);
  }

}
