import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Imports des composants internes
import { NavigationBarComponent } from './components/navigationBar/navigation.bar.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { EpisoftTranslateService } from '@core/services/translate.service';

@NgModule({
  declarations: [
    NavigationBarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    NavigationBarComponent
  ],
  providers: [
    AuthInterceptor,
    EpisoftTranslateService
  ]
})
export class CoreModule {
  // Ce module doit être importé une seule fois, dans AppModule
  // Les paramètres du constructeur permettent de s'en assurer : 
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
