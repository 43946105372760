import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';

// Imports de modules externes
import { CoreModule } from '@core/core.module';

// Imports des composants internes
import { LoginComponent } from './login/login.component';

import { EpisoftTranslateService } from '@core/services/translate.service';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
  ],
  providers: [
    EpisoftTranslateService
  ]
})
export class AuthModule { }
