<div class="row-container">
    <div class="split-50">
        <label class="epw-label blue bold">{{ this.headerTextRefGM }}</label>
        <input type="text" class="epw-search-panel-input" [(ngModel)]="this.criteriaRefGM.Txt">
    </div>
    <div class="gm-margin-right-15px"></div>
    <div class="split-50">
        <label class="epw-label blue bold">{{ this.headerTextRefClient }}</label>
        <div class="row-container">
            <input type="text" class="epw-search-panel-input"  [(ngModel)]="this.criteriaRefClient.Txt">
            <button [matMenuTriggerFor]="menu" class="epw-white-button sort-button">
                <span class="glyphicon"
                [ngClass]="getGlyphiconClass(this.criteriaRefClient.Comp)"></span>
            </button>
            <mat-menu #menu="matMenu">
                <button *ngFor="let comp of this.getComparisonOptions()" (click)="updateCriteria(comp)" mat-menu-item>
                    <span class="filter-menu-item glyphicon" [ngClass]="this.getGlyphiconClass(comp)"></span>
                    {{ translateService.getLabel('common.searchComparison.' + comp) }}
                </button>
            </mat-menu>
        </div>
    </div>
</div>
