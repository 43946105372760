import { DateHelper } from "@core/helpers/date-helper";

export class DateCriteria {
    
    public StateDate: Date = new Date();

    // On surcharge le formatteur JSON afin de ne garder que la partie date, sans heure
    public toJSON() {
        const json: string | null =  this.StateDate ? DateHelper.formatDate(this.StateDate) : null ;
        return json;
    }
}