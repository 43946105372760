import { DateRangeCriteria } from "./date-range-criteria";
import { GenericCriteria } from "./generic-criteria";
import { SearchString } from "./search-string";

export class SoleauCriteria extends GenericCriteria {
    public Title: SearchString = new SearchString();
    public Owner: number = 0;
    public DepDate: DateRangeCriteria = new DateRangeCriteria();
    public DepNum: SearchString = new SearchString(); 
    public RefGM: SearchString = new SearchString();
    public Client: SearchString = new SearchString();  
    public IncludeInactive: boolean = false;
    public DueDates: DateRangeCriteria = new DateRangeCriteria();

    public override resetToDefault(): void {
        const defaultValues = new SoleauCriteria();
        Object.assign(this, defaultValues);
    }
}