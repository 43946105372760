import { BERWalletEntity } from "../back-end-raw-entities/ber-wallet-entity";

export class FEDWalletEntity implements BERWalletEntity {
    private constructor(source : BERWalletEntity) {
        Object.assign(this, source);
    }

    static fromBERWalletEntity(source: BERWalletEntity): FEDWalletEntity {
        return new FEDWalletEntity(source);
    }

    // Données de BERUserEntity
    Id: number = 0;
    Name: string = '';
    City: string = '';
    Label: string = '';
    HasActiveItems: boolean = false;
    // Données connues uniquement côté Front End
}
