<div class="patent-details-card-component">
    <div class="patent-details-card-component__header">
        <input class="epw-checkbox" id="checkboxSelectItem{{ details.FamilyId }}" type="checkbox" value="on" [checked]="details.IsSelected" (click)="selectItem()">
        <label class="epw-label bigger bold" for="checkboxSelectItem{{ details.RefGM }}">{{ details.FamilyTitleLabel }}</label>
    </div>
    <div class="patent-details-card-component__grid">
        <div class="patent-details-card-component__grid-column-text">
            <!-- 1ère ligne -->
            <div class="patent-details-card-component__grid-cell-title">{{ translateService.getLabel('patent.props.name') }}</div>
            <div class="patent-details-card-component__grid-cell-value">{{ details.TitleLabel }}&nbsp;</div>    
                    <!-- 2ème ligne -->
            <div class="patent-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.owner') }}</div>
            <div class="patent-details-card-component__grid-cell-value">{{ details.OwnersLabel }}&nbsp;</div>
            <!-- 3ème ligne -->
            <div class="patent-details-card-component__grid-cell-title" *ngIf="details.Inventors.length > 0">{{ translateService.getLabel('common.shared.inventors') }}</div>
            <div class="patent-details-card-component__grid-cell-value">{{ details.InventorsLabel }}&nbsp;</div>
        </div>
        <div class="patent-details-card-component__grid-column-image"><image-with-popover [imageUrl]="this.getImageUrl(details)"/></div>    
    </div>
    <!-- Abrégé-->
    <div *ngIf="details.Summary.length > 0">
        <blockquote class="patent-details-card-component__abstract">
            <small>{{ details.Summary }}</small>
        </blockquote>
    </div>
    <!-- Membres - entête -->
    <div class="patent-details-card-component__grid-members patent-details-card-component__grid-members-header">
        <div class="patent-details-card-component__grid-members-column-buttons"><span class="patent-details-card-component__badge">{{details.Patents.length}}</span></div>
        <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-title">{{ translateService.getLabel('common.shared.countryDep') }}&nbsp;</div>
        <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-title">{{ translateService.getLabel('common.shared.refGM') }}&nbsp;</div>       
        <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-title">{{ translateService.getLabel('common.shared.refClient') }}&nbsp;</div>       
        <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-title">{{ translateService.getLabel('common.shared.depNum') }}&nbsp;</div>      
        <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-title">{{ translateService.getLabel('common.shared.depDate') }}&nbsp;</div>      
        <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-title">{{ translateService.getLabel('common.shared.pubNum') }}&nbsp;</div>      
        <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-title">{{ translateService.getLabel('common.shared.pubDate') }}&nbsp;</div>       
        <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-title">{{ translateService.getLabel('common.shared.status') }}&nbsp;</div>  
    </div>
    <!-- Membres - lignes -->
    <div *ngFor="let patent of this.details.Patents">
        <div class="patent-details-card-component__grid-members patent-details-card-component__grid-members-data"
            [class.patent-details-card-component__grid-members-highlighted]="patent.Match">
            <div class="patent-details-card-component__grid-members-column-buttons">
                <button class="patent-details-card-component__grid-members-button-open-documents" [disabled]="patent.CodeZen === ''" (click)="openDocuments(patent.CodeZen)" [title]="this.translateService.getLabel('common.documents')">
                    <span class="glyphicon glyphicon-duplicate"></span>
                </button>&nbsp;
                <button class="patent-details-card-component__grid-members-button-open-details" (click)="openDetails(patent.RefGM)" [title]="this.translateService.getLabel('common.details')">
                    <span class="glyphicon glyphicon-new-window"></span>
                </button>                            
            </div>
            <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-value">{{ patent.CountryLabel }}&nbsp;</div>
            <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-value">{{ patent.RefGM }}&nbsp;</div>
            <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-value">{{ patent.RefClient }}&nbsp;</div>
            <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-value">{{ patent.DepNum }}&nbsp;</div>
            <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-value">{{ patent.DepDateLabel}}&nbsp;</div>
            <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-value">{{ patent.PubNum }}&nbsp;</div>            
            <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-value">{{ patent.PubDateLabel }}&nbsp;</div>
            <div class="patent-details-card-component__grid-members-column-text patent-details-card-component__grid-members-cell-value">{{ patent.SituationLabel }}</div>
        </div>
    </div>
</div>