// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
// Interne
import { TrademarkDetailsCardComponent } from '@shared/details-cards/trademark-details-card/trademark-details-card.component';
import { DrawingModelDetailsCardComponent } from '@shared/details-cards/drawing-model-details-card/drawing-model-details-card.component';
import { PatentDetailsCardComponent } from '@shared/details-cards/patent-details-card/patent-details-card.component';
import { SoleauDetailsCardComponent } from './details-cards/soleau-details-card/soleau-details-card.component';
import { DomainNameDetailsCardComponent } from './details-cards/domain-name-details-card/domain-name-details-card.component';
import { OffTitleDetailsCardComponent } from './details-cards/off-title-details-card/off-title-details-card.component';
import { WatchingDetailsCardComponent } from '@shared/details-cards/watching-details-card/watching-details-card.component';
import { PatentDetailsPageComponent } from './details-pages/patent-details-page/patent-details-page.component';
import { TrademarkDetailsPageComponent } from './details-pages/trademark-details-page/trademark-details-page.component';
import { DrawingModelDetailsPageComponent } from './details-pages/drawing-model-details-page/drawing-model-details-page.component';
import { TabSearchPanelComponent } from './tab-search-panel/tab-search-panel.component';
import { TabContentPanelComponent } from './tab-content-panel/tab-content-panel.component';
import { TabContentPanelHeaderComponent } from './tab-content-panel-header/tab-content-panel-header.component';
import { TabGenericComponent } from './tab-generic/tab-generic.component';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { SearchPanelInputTextComponent } from './search-panel-input-fields/search-panel-input-text/search-panel-input-text.component';
import { SearchPanelInputTextAutocompleteComponent } from './search-panel-input-fields/search-panel-input-text-autocomplete/search-panel-input-text-autocomplete.component';
import { HighlightPipe } from './search-panel-input-fields/search-panel-input-text-autocomplete/search-panel-input-text-autocomplete.component';
import { SearchPanelInputDateRangeComponent } from './search-panel-input-fields/search-panel-input-date-range/search-panel-input-date-range.component';
import { SearchPanelInputDateSelectorComponent } from './search-panel-input-fields/search-panel-input-date-selector/search-panel-input-date-selector.component';
import { SearchPanelInputClassesComponent } from './search-panel-input-fields/search-panel-input-classes-selector/search-panel-input-classes-selector.component';
import { SearchPanelInputComboBoxComponent } from './search-panel-input-fields/search-panel-input-combo-box/search-panel-input-combo-box.component';
import { SearchPanelInputDualRefsComponent } from './search-panel-input-fields/search-panel-input-dual-refs/search-panel-input-dual-refs.component';
import { SearchPanelInputCheckBoxComponent } from './search-panel-input-fields/search-panel-input-check-box/search-panel-input-check-box.component';
import { PatentsSearchPanelComponent } from './search-panels/patents-search-panel/patents-search-panel.component';
import { TrademarksSearchPanelComponent } from './search-panels/trademarks-search-panel/trademarks-search-panel.component';
import { DrawingModelsSearchPanelComponent } from './search-panels/drawing-models-search-panel/drawing-models-search-panel.component';
import { SoleauSearchPanelComponent } from './search-panels/soleau-search-panel/soleau-search-panel.component';
import { DomainNamesSearchPanelComponent } from './search-panels/domain-names-search-panel/domain-names-search-panel.component';
import { WatchingsSearchPanelComponent } from './search-panels/watchings-search-panel/watchings-search-panel.component'
import { OffTitlesSearchPanelComponent } from './search-panels/off-titles-search-panel/off-titles-search-panel.component';
import { ImageWithPopoverComponent } from "./image-with-popover/image-with-popover.component";
import { CarouselSelectorComponent } from "./carousel-selector/carousel-selector.component";
import { CustomDateAdapter } from './search-panel-input-fields/search-panel-input-date-range/custom-date-adapter';
import { FR_DATE_FORMATS } from './search-panel-input-fields/search-panel-input-date-range/custom-date-formats';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
import { UserDialogComponent } from './dialogs/user-dialog/user-dialog.component';
import { ProfileDialogComponent } from './dialogs/profile-dialog/profile-dialog.component';
import { CustomTooltipComponent } from "./custom-tooltip/custom-tooltip.component";

@NgModule({
    declarations: [
        TrademarkDetailsCardComponent,
        DrawingModelDetailsCardComponent,
        PatentDetailsCardComponent,
        SoleauDetailsCardComponent,
        DomainNameDetailsCardComponent,
        OffTitleDetailsCardComponent,
        WatchingDetailsCardComponent,
        PatentDetailsPageComponent,
        TrademarkDetailsPageComponent,
        DrawingModelDetailsPageComponent,
        TabSearchPanelComponent,
        TabContentPanelComponent,
        TabContentPanelHeaderComponent,
        TabGenericComponent,
        SearchPanelInputTextComponent,
        SearchPanelInputTextAutocompleteComponent,
        SearchPanelInputDateRangeComponent,
        SearchPanelInputDateSelectorComponent,
        SearchPanelInputClassesComponent,
        SearchPanelInputComboBoxComponent,
        SearchPanelInputDualRefsComponent,
        SearchPanelInputCheckBoxComponent,
        PatentsSearchPanelComponent,
        TrademarksSearchPanelComponent,
        DrawingModelsSearchPanelComponent,
        SoleauSearchPanelComponent,
        DomainNamesSearchPanelComponent,
        WatchingsSearchPanelComponent,
        OffTitlesSearchPanelComponent,
        ChangePasswordDialogComponent,
        UserDialogComponent,
        ProfileDialogComponent,
        HighlightPipe,
    ],
    exports: [
        TrademarkDetailsCardComponent,
        DrawingModelDetailsCardComponent,
        PatentDetailsCardComponent,
        SoleauDetailsCardComponent,
        DomainNameDetailsCardComponent,
        OffTitleDetailsCardComponent,
        WatchingDetailsCardComponent,
        PatentDetailsPageComponent,
        TrademarkDetailsPageComponent,
        DrawingModelDetailsPageComponent,
        TabSearchPanelComponent,
        TabContentPanelComponent,
        TabContentPanelHeaderComponent,
        TabGenericComponent,
        HighlightPipe,
    ],
    providers: [
        EpisoftTranslateService, 
        { provide: MAT_DATE_FORMATS, useValue: FR_DATE_FORMATS },
        { provide: DateAdapter, useClass: CustomDateAdapter },
    ],
    imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatCardModule,
    ImageWithPopoverComponent,
    CarouselSelectorComponent,
    CustomTooltipComponent
]
})
export class SharedModule { }
