import { Component } from '@angular/core';
import { DrawingModelsTabContext } from '@core/models/tab-contexts/drawing-models-tab-context';
import { ParametersService } from '@core/services/parameters.service';
import { DrawingModelsService } from '@core/services/drawing-models.service';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'drawings-models-tab',
  templateUrl: './drawings-models-tab.component.html',
  styleUrls: ['./drawings-models-tab.component.scss']
})
export class DrawingsModelsTabComponent {
  tabContext: DrawingModelsTabContext = new DrawingModelsTabContext(this.translateService, this.parametersService, this.drawingModelsService);

  constructor(public translateService: EpisoftTranslateService, private parametersService: ParametersService, private drawingModelsService: DrawingModelsService) {
    this.translateService.onChangeCallback = () => { this.initOptionAddedSubscription(); };
    this.tabContext.sort.selectedOptionCode = "TitleLabel";
  };

  ngOnInit() {
    this.initOptionAddedSubscription();
    this.tabContext.loadTypeAhead();
  }

  public async initOptionAddedSubscription() {
    this.tabContext.sort.options = [
      { Code: "CurrRegDate", Label: await this.translateService.getAsyncTrad('common.shared.regDate').toPromise() || '' },
      { Code: "DepDate", Label: await this.translateService.getAsyncTrad('common.shared.depDate').toPromise() || '' },
      { Code: "RenewDueDates", Label: await this.translateService.getAsyncTrad('common.shared.renewDates').toPromise() || '' },
      { Code: "DepositCountryLabel", Label: await this.translateService.getAsyncTrad('common.shared.country').toPromise() || '' },
      { Code: "SituationLabel", Label: await this.translateService.getAsyncTrad('common.shared.status').toPromise() || '' },
      { Code: "TitleLabel", Label: await this.translateService.getAsyncTrad('model.props.name').toPromise() || '' },      
      { Code: "Owner", Label: await this.translateService.getAsyncTrad('common.shared.owner').toPromise() || '' },
      { Code: "TypeLabel", Label: await this.translateService.getAsyncTrad('common.shared.type').toPromise() || '' },
    ];
  };

}