<div class="navigation-bar-component__header">
    <div class="navigation-bar-component__main-bar">
        
        <a class="navigation-bar-component__logo-box" routerLink="/home/home">
            <img src="./assets/images/logo2020.png" alt="Logo" class="epw-logo-image">
        </a>

        <div class="navigation-bar-component__tabs">
            <a class="navigation-bar-component__tab" routerLink="/home/br" routerLinkActive="selected">{{ translateService.getLabel('patent.name') }}</a>
            <a class="navigation-bar-component__tab" routerLink="/home/ma" routerLinkActive="selected">{{ translateService.getLabel('trademark.name') }}</a>
            <a class="navigation-bar-component__tab" routerLink="/home/dm" routerLinkActive="selected">{{ translateService.getLabel('model.name') }}</a>
            <a class="navigation-bar-component__tab" routerLink="/home/do-es" routerLinkActive="selected">{{ translateService.getLabel('soleau.name') }}</a>
            <a class="navigation-bar-component__tab" routerLink="/home/do-dn" routerLinkActive="selected">{{ translateService.getLabel('domain.name') }}</a>
            <a class="navigation-bar-component__tab" routerLink="/home/su" routerLinkActive="selected">{{ translateService.getLabel('watching.name') }}</a>
            <a class="navigation-bar-component__tab" routerLink="/home/do" routerLinkActive="selected">{{ translateService.getLabel('other.name') }}</a>
            <a *ngIf="this.isCurrentUserAdmin()" class="navigation-bar-component__tab" routerLink="/home/admin" routerLinkActive="selected">{{ translateService.getLabel('admin.name') }}</a>
        </div>

        <div class="navigation-bar-component__right-box-languages">
            <div ><a #langSelect1 [class.selected]="this.getCurrentLanguageCode() === 'fr'" name="fr" (click)="updateLanguageSelection(langSelect1.name)">FR</a></div>
            <div><a #langSelect2 [class.selected]="this.getCurrentLanguageCode() === 'en'" name="en" (click)="updateLanguageSelection(langSelect2.name)">EN</a></div>
        </div>

        <div class="navigation-bar-component__right-box-login-info">
            <div class="navigation-bar-component__login-info">{{getCurrentUserName()}}</div>
            <div>
                <button class="navigation-bar-component__password-button" (click)="openChangePasswordDialog()">
                    <span class="glyphicon glyphicon-lock"></span></button>
                <button class="navigation-bar-component__logout-button" (click)="logout()">
                    <span class="glyphicon glyphicon-off"></span>
                    {{ translateService.getLabel('common.exit') }}</button>
            </div>
        </div>

    </div>
    <div class="navigation-bar-component__yellow-border"></div>
</div>