export interface ExportAction {
    label: string;
    class: string;
    type: ExportActionType;
  }

  export enum ExportActionType {
    List,
    ListByFiling,
    ListByDesign,
    Map,
    Family
  }