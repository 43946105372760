import { Injectable, EventEmitter, SkipSelf } from '@angular/core';
import { Observable, catchError, map, throwError, switchMap, of } from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { LogService } from '@core/services/log.service';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { UsersService } from './users.service';
import { BERUserEntity } from '@core/models/entities/back-end-raw-entities/ber-user-entity';
import { environment } from 'src/environments/environment';

export interface EpiUserCredentials {
    Instance: string
    Login: string;
    Password: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private translateService: EpisoftTranslateService, 
        private usersService: UsersService, 
        private httpApiService: HttpApiService, 
        private logService: LogService) { }

    // Gestion du token de session 
    private tokenKey = 'token';

    tokenChanged = new EventEmitter<string>();

    public setToken(token: string) {
        this.logService.log("MAJ du token de connexion")
        localStorage.setItem(this.tokenKey, token);
        this.tokenChanged.emit(token);
    }

    public getToken(): string {
        return localStorage.getItem(this.tokenKey) ?? "";
    }

    public AttemptLogin(login: string, password: string): Observable<string> {
        this.logService.log(`Login de ${login} / ${btoa(password)}`);
        this.usersService.currentUser = null;
        let credentials: EpiUserCredentials = {
            Instance: environment.zenInstance,
            Login: login,
            Password: btoa(password) // Encodage du mot de passe en base 64
        };
        
        let ErrMessage: string;
        let authResult: Observable<string>;
        authResult = this.httpApiService.post<string>("API-Auth-POST", credentials, new Map<string, string>(), false);
        return authResult.pipe(
            map((response: string) => {
                this.setToken(response);
                this.usersService.LoadCurrentUserData();
                this.logService.log("Utilisateur connecté : ", login);
                return response;
             }),
             catchError((error) => {
                 if (error.status === 401) {
                    this.logService.error("Probleme d'authentification");
                    this.translateService.acquire('messages.3001').subscribe((res: string) => {
                        ErrMessage = res;
                    });
                    return throwError(ErrMessage);
                 }
                 // Retourner un observable d'erreur pour indiquer que l'erreur a été gérée
                 return of (error as string);

             })
         );
    }

    public isLoggedIn(): boolean {
        console.log("isLoggedIn");
        let result: boolean = this.getToken() != "";
        // Pas de token => pas de connexion
        if(!result) return false;
        if (result && this.usersService.currentUser == null) {
            // Rechargement de l'utilisateur courant : nécessaire en cas de refresh
            this.usersService.LoadCurrentUserData();
        }
        return result;
    }

    public logout(): void {
        // Supprimer le token
        this.setToken("");
    }

    public isAuthorizedToAccessFinancialData(): boolean  {
        if (this.isLoggedIn() && this.usersService.currentUser !== null) {
            return this.usersService.currentUser.Profile.AccessFinancialData
        }
        return false;
    }
}
