import { Component, Input, ViewChild } from '@angular/core';
import { WatchingCriteria } from '@core/models/criterias/watching-criteria';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { StringHelper } from '@core/helpers/string-helper';
import { WatchingsTabContext } from '@core/models/tab-contexts/watchings-tab-context';
import { SearchPanelInputComboBoxComponent } from '@shared/search-panel-input-fields/search-panel-input-combo-box/search-panel-input-combo-box.component';

@Component({
  selector: 'app-watchings-search-panel',
  templateUrl: './watchings-search-panel.component.html',
  styleUrls: ['./watchings-search-panel.component.scss']
})
export class WatchingsSearchPanelComponent {
  @Input() tabContext!: WatchingsTabContext;
  @ViewChild('comboBoxOptions') comboBoxOptionsComponent!: SearchPanelInputComboBoxComponent;
  @ViewChild('comboBoxCountries') comboBoxCountriesComponent!: SearchPanelInputComboBoxComponent;

  searchCriterias: WatchingCriteria = new WatchingCriteria();

  public getSearchCriterias(): WatchingCriteria {
    return this.searchCriterias;
  }

  constructor(public translateService: EpisoftTranslateService){ }

  ngOnInit(){
    if (this.tabContext != undefined){
      this.tabContext.searchCriterias = this.searchCriterias;
    }
    // Désactivation sélecteurs options et pays pour les surveillances
    this.tabContext.disableOptions = true;
    this.tabContext.disableCountries = true;
  }

  updateExactMatch(newValue: boolean){
    this.searchCriterias.Denomination.ExactMatch = newValue;
  }

  getObjectsFiltered(filterValue : string, objectFilter : any[], objectFiltered : any[]){
    objectFilter.forEach(item =>
      {
        if (item.Code === filterValue){
          var pair: any = {};
          pair.Code = item.Code;
          pair.ValueAlpha = item.ValueAlpha
          objectFiltered.push(pair);
        }
      }
    );
  };

  // Méthodes gérant la liste déroulante "Type de surveillances"
  typeLabelSelector : (WatchingType: any) => string = WatchingType => WatchingType.CurrentTypeLabel;

  typeIsActiveSelector: Function = (a: any): boolean => true; //Tous les types de surveillances sont toujours proposés par défaut

  typeValueSelector: (WatchingType: any) => string = WatchingType => String(WatchingType.Code);

  updateType(newValue: any){
    this.searchCriterias.WatchingType = newValue;

    // En cas de nouvelle sélection avec critère de recherche
    if (newValue != ''){
      // Remise à blanc sélecteurs classes, options et pays selon type de surveillance sélectionné
      this.tabContext.parameters.classesFiltered = [];
      this.tabContext.parameters.filteredOptions = [];
      this.tabContext.parameters.filteredCountries = [];
      // Remise à blanc des critères de recherche associés
      this.searchCriterias.WatchingOption = '';
      this.searchCriterias.Country = '';
      this.tabContext.disableCountries = true;

      this.getObjectsFiltered(newValue, this.tabContext.parameters.typeTitlesFilter, this.tabContext.parameters.classesFiltered);

      // Si existence de propositions de paramètres supportant le critère des classes, autorisation ou non d'accès au sélecteur de classes
      if (this.tabContext.parameters.classesFiltered.length !== 0 && this.tabContext.parameters.classesFiltered[0].ValueAlpha === 'N'){
        this.tabContext.disableClasses = true;
        this.searchCriterias.Classes = [];
      }
      else if (this.tabContext.parameters.classesFiltered.length === 0 || this.tabContext.parameters.classesFiltered[0].ValueAlpha === 'O'){
        this.tabContext.disableClasses = false;
      }

      this.tabContext.parameters.optionsFilter.forEach(option => {
        if (option.ValueAlpha.includes(newValue) && this.tabContext.parameters.options.some(wo => wo.Code === option.Code)){
          var tuple = this.tabContext.parameters.options.find(wo => wo.Code === option.Code);
          this.tabContext.parameters.filteredOptions.push(tuple);
        }
      });

      // Si la liste d'options filtrées correspond en longueur à l'ensemble des options possibles, verrouillage ou non sélecteurs options et pays 
      if (this.tabContext.parameters.filteredOptions.length < this.tabContext.parameters.optionsFilter.length){
        this.tabContext.disableOptions = false;
        this.tabContext.parameters.filteredOptions.splice(0, 0, { Code: "", EN: "", FR: "", CurrentOptionLabel: "" })
        this.tabContext.parameters.filteredOptions.sort((t1, t2) => StringHelper.compareStrings(t1.CurrentOptionLabel, t2.CurrentOptionLabel));
      }
      else if (this.tabContext.parameters.filteredOptions.length === this.tabContext.parameters.optionsFilter.length){
        this.tabContext.parameters.filteredOptions = this.tabContext.parameters.options;
        this.tabContext.disableOptions = true;
        this.tabContext.disableCountries = true;
      }

    } else {
      // Réinitialisation à zéro sélecteurs types options et pays disponibles pour critères de recherche
      this.tabContext.parameters.filteredOptions = this.tabContext.parameters.options;
      this.tabContext.parameters.filteredCountries = this.tabContext.parameters.countries;
      this.tabContext.disableOptions = true;
      this.tabContext.disableCountries = true;
    }
    if(this.tabContext.disableOptions) this.comboBoxOptionsComponent.resetSelectedValue();
    if(this.tabContext.disableCountries) this.comboBoxCountriesComponent.resetSelectedValue();
  }

  // Méthodes gérant la liste déroulante "Options de surveillances"
  optionLabelSelector : (WatchingOption: any) => string = WatchingOption => WatchingOption.CurrentOptionLabel;

  optionIsActiveSelector: Function = (a: any): boolean => true; //Tous les options de surveillances sont toujours proposés par défaut (à retravailler avec filtre)

  optionValueSelector: (WatchingOption: any) => string = WatchingOption => String(WatchingOption.Code);

  updateOption(newValue: any){
    this.searchCriterias.WatchingOption = newValue;

    // Remise à blanc sélecteurs pays selon option de surveillance sélectionnée
    this.tabContext.parameters.filteredCountries = [];
    // Remise à blanc du critère de recherche associé
    this.searchCriterias.Country = '';
    this.comboBoxCountriesComponent.resetSelectedValue();

    this.getObjectsFiltered(newValue, this.tabContext.parameters.countriesFilter, this.tabContext.parameters.filteredCountries);
    
    // Si existence de propositions de paramètres supportant le critère des pays, autorisation ou non d'accès au sélecteur de pays
    if (this.tabContext.parameters.filteredCountries.length !== 0 && this.tabContext.parameters.filteredCountries[0].ValueAlpha === 'N' || this.tabContext.parameters.filteredCountries.length === 0){
      this.tabContext.disableCountries = true;
    }
    else if (this.tabContext.parameters.filteredCountries.length !== 0 && this.tabContext.parameters.filteredCountries[0].ValueAlpha === 'O'){
      this.tabContext.disableCountries = false;
    }
  }

  // Méthodes gérant la liste déroulante "Pays option individuelle"
  countryLabelSelector : (country: any) => string = country => country.CurrentCountryLabel;

  countryIsActiveSelector: Function = (a: any): boolean => true; //Tous les pays sont toujours proposés par défaut

  countryValueSelector: (country: any) => string = country => String(country.Code);

  updateCountry(newValue: string){
    this.searchCriterias.Country = newValue;
  }

  updateClasses(newValue: any){
    // Ajout du numéro de classe coché en liste de critères de recherche si inexistant
    // Retrait du numéro de classe décoché si existant dans liste de critères de recherche
    if (!(this.searchCriterias.Classes.find(c => c === newValue.Num)) && newValue.isChecked){
      this.searchCriterias.Classes.push(newValue.Num);
      this.searchCriterias.Classes.sort();
    } else if (this.searchCriterias.Classes.find(c => c === newValue.Num) && !newValue.isChecked){
      this.searchCriterias.Classes.splice(this.searchCriterias.Classes.findIndex(c => { return c ===newValue.Num }), 1)     
    }    
  }

  updateIncludeInactive(newValue: boolean){
    this.searchCriterias.IncludeInactive = newValue;
  }
}
