import { GenericCriteria } from "./generic-criteria";
import { SearchString } from "./search-string";

export class OffTitleCriteria extends GenericCriteria {
    public HTType: string = '';
    public Title: SearchString = new SearchString();
    public Country: string = '';
    public Situation: string = '';
    public RefGM: SearchString = new SearchString();
    public IncludeInactive: boolean = false;

    public override resetToDefault(): void {
        const defaultValues = new OffTitleCriteria();
        Object.assign(this, defaultValues);
    }
}