import { Component, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { ExportAction, ExportActionType } from '@core/models/export-action';
import { TabContext } from '@core/models/tab-contexts/tab-context';
import { EpisoftTranslateService } from '@core/services/translate.service';


@Component({
  selector: 'tab-content-panel-header',
  templateUrl: './tab-content-panel-header.component.html',
  styleUrls: ['./tab-content-panel-header.component.scss']
})
export class TabContentPanelHeaderComponent {
  @Output() toggleSearchPanel = new EventEmitter<void>();
  @Output() selectAllValueChanged = new EventEmitter<void>();
  @Input() tabContext! : TabContext;

  exportActions: ExportAction[] = [
    { label: "common.exportKinds.List", class:"glyphicon glyphicon-th-list", type: ExportActionType.List},
    { label: "common.exportKinds.ListByFiling", class:"glyphicon glyphicon-th-list", type: ExportActionType.ListByFiling},
    { label: "common.exportKinds.ListByDesign", class:"glyphicon glyphicon-th-list", type: ExportActionType.ListByDesign},
    { label: "common.exportKinds.Map", class:"glyphicon glyphicon-globe", type: ExportActionType.Map},
    { label: "common.exportKinds.Family", class:"glyphicon glyphicon-th-large", type: ExportActionType.Family},
  ];

  constructor(public translateService: EpisoftTranslateService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {   
    // On définit ici le callback appelé lors du chargement de données, afin de forcer la navigation automatique et la préselection des éléments
    // On le fait ici afin qu'il soit utilisé à la fois quand on clique sur le bouton "Afficher tout le portefeuille", défini ici (méthode loadData)
    // et aussi via le bouton de recherche du panneau latéral, qui lui se contente d'appeler le loadData de l'objet contexte sans avoir accès aux
    // fonctions utilisées dans le callback
    if(this.tabContext != undefined) {
      this.tabContext.loadDataCallback = () => { this.navigateToPage(1); this.selectAll(true);}
      // Filtrage des types d'export disponibles pour le module en cours
      this.exportActions = this.exportActions.filter((exportAction) => this.tabContext.getAvailableExportTypes().includes(exportAction.type));
    }
  }

  loadData() {
    // Chargement des données (appellera le callback défini ci-dessus)
    this.tabContext.loadData(undefined);
    this.tabContext.resetSearchCriterias();
  }

  navigateToPage(target: number) {
    if(target > 0)  {
      this.tabContext.pagination.currentPage = Math.min(target, this.getPageCount());
      // Découpage des données pour construire la page courante
      let startIndex = (this.tabContext.pagination.currentPage - 1) * this.tabContext.pageSize.selectedOption;
      this.tabContext.visibleData = this.tabContext.data.slice(startIndex, startIndex + this.tabContext.pageSize.selectedOption);
      // Forcer la détection des changements
      this.changeDetectorRef.detectChanges();
    }
}

  onSortOptionSelected() {
    this.tabContext.sortData();
    this.navigateToPage(this.tabContext.pagination.currentPage);
  }

  switchSortOrder() {
    this.tabContext.sort.useSortOrderASC = !this.tabContext.sort.useSortOrderASC;
    this.tabContext.sortData();
    this.navigateToPage(this.tabContext.pagination.currentPage);
  }

  getPageCount() : number {
    // Calcul du nombre de pages en fonction du nombre d'éléments souhaité par page
    return Math.ceil(this.tabContext.data.length / this.tabContext.pageSize.selectedOption);
  }

  getPagesToShow() : number[] {
    const pagesToShow : number[] = [];
  
    const maxPageButtonCount = 7;

    const pageCount = this.getPageCount();

    // Cas numéro 1 : nombre de pages = 1
    if(pageCount === 1) {
      pagesToShow.push(1); // On affiche un seul bouton
    }

    // Cas numéro 2 : nombre de pages compris entre 2 et le nombre max de boutons de pagination
    if(pageCount > 1 && pageCount <= maxPageButtonCount) {
      // On affiche un bouton par page
      for (let i = 1; i <= pageCount; i++) {
        pagesToShow.push(i);
      }
    }

    // Cas numéro 3 : le nombre de pages est supérieur au nombre de boutons disponibles
    if(pageCount > maxPageButtonCount) {

      // Helper pour ajouter des pages au tableau pagesToShow. Si index = -1 : ajout en fin de tableau, sinon insertion à l'index demandé
      const addPageIfNotPresent = (i : number, index = -1) => {
        if(i > 0 && i <= pageCount && pagesToShow.findIndex(v => v === i) === -1) {
          if(index === -1) // Ajout en fin de tableau
            pagesToShow.push(i);
          else
            pagesToShow.splice(index, 0, i); // Insertion
          return true;
        }
        return false;
      }
      // On affiche toujours la page 1
      addPageIfNotPresent(1);
      // On affiche la page courante, suivie des pages précédentes et suivantes
      addPageIfNotPresent(this.tabContext.pagination.currentPage - 1); // Page précédente
      addPageIfNotPresent(this.tabContext.pagination.currentPage); // Page courante
      addPageIfNotPresent(this.tabContext.pagination.currentPage + 1); // Page suivante
      // On affiche toujours la dernière page
      addPageIfNotPresent(pageCount);

      // On ajoute des boutons "..." entre les trous
      for(let i = pagesToShow.length - 1; i >= 0; i--) {
        if(i >= 1 && pagesToShow[i] != (pagesToShow[i-1] + 1)) {
          pagesToShow.splice(i, 0, -1);
        }
      }

      // On s'assure d'avoir le bon nombre de boutons
      while(pagesToShow.length < maxPageButtonCount) {
        for(let i = 0; i < pagesToShow.length; i++) {
          if(addPageIfNotPresent(pagesToShow[i] + 1, i + 1)) {
            break;
          }
        }
      }
    }

    return pagesToShow;
  }

  adjustPageLabel(i : number) : string {
    return i === -1 ? "..." : i.toString();
  }

  export(exportType: ExportActionType) {
    this.tabContext.export(exportType);
  }

  // Contrôle la checkbox "selectionner tout"
  // Le booléen optionnel permet de forcer une valeur (utile pour tout sélectionner lors de l'initialisation)
  // Si aucun paramètre n'est fourni, la valeur de la checkbox est "inversée"
  selectAll(forceValue?: boolean) {
    if(forceValue != undefined) {
        this.tabContext.selectAllIsChecked = forceValue;
    }
    else {
      this.tabContext.selectAllIsChecked = !this.tabContext.selectAllIsChecked;
    }
    this.selectAllValueChanged.emit();
  }
}
