import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FEDUserEntity } from '@core/models/entities/front-end-displayable-entities/fed-user-entity';
import { BERUserEntity } from "@core/models/entities/back-end-raw-entities/ber-user-entity";
import { LogService } from '@core/services/log.service';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { UsersService } from '@core/services/users.service';
import { FEDUserProfileEntity } from '@core/models/entities/front-end-displayable-entities/fed-user-profile-entity';
import { FEDWalletEntity } from '@core/models/entities/front-end-displayable-entities/fed-wallet-entity';
import { PasswordHelper } from '@core/helpers/password-helper';
import { UserProfilesService } from '@core/services/user-profiles.service';


export enum profileDialogModeEnum {
  Undefined,
  CreateProfile,
  UpdateProfile,
  ViewDefaultProfile,
}

@Component({
  selector: 'profile-password-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileDialogComponent {

  constructor(
    public profilesService : UserProfilesService,
    public translateService : EpisoftTranslateService,
    private logService : LogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public currentUser: FEDUserEntity | undefined;

  public profile!: FEDUserProfileEntity;

  public wallet: FEDWalletEntity[] = [];

  public authLevels = FEDUserProfileEntity.authLevels;

  public profileDialogModeEnum = profileDialogModeEnum;

  public profileDialogMode : profileDialogModeEnum = profileDialogModeEnum.Undefined;

  ngOnInit() {
    this.currentUser = this.data.currentUser;
    if(this.data.profile !== undefined)
      this.profile = new FEDUserProfileEntity(this.data.profile); // On fait une copie de la source pour gérer l'annulation
    this.wallet = this.data.wallet;
    // Détermination du mode d'affichage de la fenêtre
    // Le profil par défaut est limité, d'où la nécessité de le distinguer des autres cas
    if(this.profile === undefined)
      this.profileDialogMode = profileDialogModeEnum.CreateProfile
    else
      this.profileDialogMode = this.profile.IsDefault ? profileDialogModeEnum.ViewDefaultProfile : profileDialogModeEnum.UpdateProfile;

    // Si on est création, il faut allouer un objet profil vide
    if(this.profileDialogMode == profileDialogModeEnum.CreateProfile) {
      this.profile = FEDUserProfileEntity.fromBERUserProfileEntity({ 
        Id: 0,
        Name: "",
        EpisoftID: this.currentUser?.EpisoftID ?? '', // Même inscription que l'utilisateur courant
        AccessFinancialData: false,
        AuthorizationLevel: "NoDocument",
        Created : new Date(),
        Modified : new Date(),
        IsDefault : false,
        Wallet: [] = [],
      });
    }
  }

  // Le profil par défaut est en lecture seule
  public isReadOnly() : boolean { return this.profileDialogMode === profileDialogModeEnum.ViewDefaultProfile; }

  public validateName() : boolean {
    return this.profile.Name.length > 2;
  }


  save() {
    if(this.profileDialogMode == profileDialogModeEnum.CreateProfile) { 
      // Mode création
      this.profilesService.CreateProfile(this.profile);
    } else {
      // Mode MAJ
      this.profilesService.UpdateProfile(this.profile);
    }
  }

  delete() {
       this.profilesService.DeleteProfile(this.profile);
  }

  canDelete() : boolean {
    // On peut supprimer uniquement si on est en modification ...
    return this.profileDialogMode === profileDialogModeEnum.UpdateProfile;
  }

  canSave() : boolean {
    // On peut enregistrer uniquement si on est en modification ou en création
    return this.profileDialogMode === profileDialogModeEnum.UpdateProfile || this.profileDialogMode === profileDialogModeEnum.CreateProfile;
  }

  onOwnerChecked(owner : FEDWalletEntity) : void {
    if(this.profile.containsOwner(owner.Id)) {
      // Ajout du titulaire au portefeuille (élément coché)
      var index = this.profile.Wallet.indexOf(owner.Id);
      if (index !== -1) {
        this.profile.Wallet.splice(index, 1);
      }
    }
    else {
      // Suppression du titulaire du portefeuille (élément décoché)
      this.profile.Wallet.push(owner.Id);
    }
  }

}