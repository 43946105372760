import { BERDrawingModelEntity } from "../back-end-raw-entities/ber-drawing-model-entity";
import { FEDBaseEntity } from "./fed-base-entity";
import { FEDDrawingModelFractionEntity } from "./fed-drawing-model-fraction-entity";
import { FEDDrawingModelRootEntity } from "./fed-drawing-model-root-entity";

export class FEDDrawingModelEntity extends FEDBaseEntity implements BERDrawingModelEntity {
    constructor(source : BERDrawingModelEntity) {
        super(source);
        Object.assign(this, source);
        // Transtypage manuel des objets composés depuis le type Back vers le pendant Front
        this.Root = new FEDDrawingModelRootEntity(source.Root);
        this.Fractions = source.Fractions.map(e => new FEDDrawingModelFractionEntity(e));
    }
    // Données de BERDrawingModelEntity
    GUID!: string;
    Root!: FEDDrawingModelRootEntity;              // Transtypage vers le type Front End
    Fractions! : FEDDrawingModelFractionEntity[];  // Transtypage vers le type Front End
    // Données connues uniquement côté Front End
    override Active: boolean = this.Root.Active; // Le statut "Actif" est déterminé par le modèle père
}
