import { Component, EventEmitter, Input, Output } from '@angular/core';
export interface Classe {
  Num: string;
  isChecked: boolean
}

const maxClasses = 45;

@Component({
  selector: 'search-panel-input-classes-selector',
  templateUrl: './search-panel-input-classes-selector.component.html',
  styleUrls: ['./search-panel-input-classes-selector.component.scss']
})

export class SearchPanelInputClassesComponent {
  @Input({ required: true }) headerText : string = '';
  @Input({ required: false }) classes : Classe[] = this.getDefaultProps();
  @Input({ required: false }) disableSelector : boolean = false;
  @Output() criteriaValueChanged = new EventEmitter<Classe>();

  public getDefaultProps() {
    let list : Classe[] = [];
    for (var i = 1; i <= maxClasses; i++) {
      list.push({
        Num: ((i <= 9 ? '0' : '') + i),
        isChecked: false
      });
    }
    return list
  };

  onCriteriaValueChanged(currentClasse: Classe) {
      this.criteriaValueChanged.emit(currentClasse);
  };
}
