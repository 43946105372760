import { DateCriteria } from "./date-criteria";
import { GenericCriteria } from "./generic-criteria";
import { SearchString } from "./search-string";

export class WatchingCriteria extends GenericCriteria {
    public Denomination: SearchString = new SearchString();
    public WatchingType: string = '';
    public WatchingOption: string = '';
    public Country: string = '';
    public Classes: string[] = [];
    public RefGM: SearchString = new SearchString();
    public StateDate: DateCriteria = new DateCriteria();
    public IncludeInactive: boolean = false;

    public override resetToDefault(): void {
        const defaultValues = new WatchingCriteria();
        Object.assign(this, defaultValues);
    }
}