import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'generic-details-card',
  template: ``,
  styles: ['']
})
export abstract class GenericDetailsCardComponent {

  constructor(protected authService: AuthService) {}

}
