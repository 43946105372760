import { DateRangeCriteria } from "./date-range-criteria";
import { DateCriteria } from "./date-criteria";
import { SearchString } from "./search-string";

export abstract class GenericCriteria {

    // Permet de transformer l'objet courant pour l'envoyer ensuite au back sérialisé en JSON
    // On le débarrasse au passage des propriétés non renseignées que l'on ne souhaite pas transmettre
    // On pourrait joeur avec les attributs des propriétés pour arriver au même résultat mais cela pose des problèmes
    // lorsque l'on souhaite utiliser la mapping standard d'Angular (via ngModel) 
    public toJSON(): string {
        // Helper pour distinguer les types références des types valeurs (ou primitifs)
        function isPrimitive(value: unknown): value is bigint | boolean | null | number | string | symbol | undefined {
            return (typeof value !== 'object' && typeof value !== 'function');
        }

        const json: any = {};
        // On parse les propriétés de l'objet pour n'envoyer que les critères définis
        for (const key of Object.keys(this)) {
            const value = (this as any)[key];
            // Filtrer les valeurs null ou non définies
            if (value === null || value === undefined) {
                continue;
            }
            switch(isPrimitive(value))
            {
                case true: 
                    // On examine d'abord les types valeurs ..
                    if((typeof value === 'string' && (value as string) !== "")                     
                    || (typeof value === 'boolean') 
                    || (typeof value === 'number' && (value as number) != 0)) {
                        json[key] = value;
                    }
                    break;
                case false:
                    // ... Puis les types références
                    if ((value instanceof SearchString && (value as SearchString).Txt !== "") ||
                    (value instanceof DateRangeCriteria && ((value as DateRangeCriteria).start !== null || (value as DateRangeCriteria).end !== null)) ||
                    (value instanceof DateCriteria && (value as DateCriteria).StateDate !== null ) || 
                    (Array.isArray(value) && value.length > 0)) {
                        json[key] = value;
                    }
                    break;
            }
        }
        return json;
    }

    public abstract resetToDefault(): void;
}
