
import { BEROffTitleEntity } from "../back-end-raw-entities/ber-off-title-entity";
import { FEDBaseEntity } from "./fed-base-entity";

export class FEDOffTitleEntity extends FEDBaseEntity implements BEROffTitleEntity {
    constructor(source : BEROffTitleEntity) {
        super(source);
        Object.assign(this, source);
    }
    // Données de BEROffTitleEntity
    Title!: { EN: string; FR: string; };
    HTType!: string;
    Country!: string;

    // Données connues uniquement côté Front End
    CurrentTitleLabel!: string;
}
