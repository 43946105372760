import { Component } from '@angular/core';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'home-tab',
  templateUrl: './home-tab.component.html',
  styleUrls: ['./home-tab.component.scss']
})
export class HomeTabComponent {
  constructor(public translateService: EpisoftTranslateService){ }
}
