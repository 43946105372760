<div class="watching-details-card-component">
    <div class="watching-details-card-component__header">
        <input class="epw-checkbox" id="checkboxSelectItem{{ details.RefGM }}" type="checkbox" value="on"
            [checked]="details.IsSelected" (click)="selectItem()" >
        <label class="epw-title bold" for="checkboxSelectItem{{ details.RefGM }}">{{ details.Denomination}}</label>
        <button class="watching-details-card-component__button-open-documents" (click)="openDocuments()" [title]="this.translateService.getLabel('common.documents')">{{ translateService.getLabel('common.documents')
            }}<span class="glyphicon glyphicon-duplicate gm-margin-left-5px"></span></button>
    </div>
    <div class="watching-details-card-component__grid">
        <!-- 1ère colonne -->
        <div class="watching-details-card-component__grid-column">
            <div class="watching-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.status') }}</div>
            <div class="watching-details-card-component__grid-cell-value">{{ details.SituationLabel }}&nbsp;</div>
            <div class="watching-details-card-component__grid-cell-title">{{ translateService.getLabel('watching.props.options') }}</div>
            <div class="watching-details-card-component__grid-cell-value">{{ details.CurrentOptionLabel }}</div>
            <div class="watching-details-card-component__grid-cell-title">{{ translateService.getLabel('watching.props.subscriptions') }}</div>
            <div class="watching-details-card-component__grid-cell-value">{{ this.getCurrentSubscriptionInformations(details)}}</div>
        </div>
        <!-- 2ème colonne -->
        <div class="watching-details-card-component__grid-column">
            <div class="watching-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.type') }}</div>
            <div class="watching-details-card-component__grid-cell-value">{{ details.TypeLabel }}&nbsp;</div>
            <div class="watching-details-card-component__grid-cell-title">{{ translateService.getLabel('watching.props.classes') }}</div>
            <div class="watching-details-card-component__grid-cell-value">{{ details.CurrentClassesLabel}}&nbsp;</div>
            <div class="watching-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.refGM') }}</div>
            <div class="watching-details-card-component__grid-cell-value">{{ details.RefGM }}&nbsp;</div>
        </div>
        <!-- Image (colonne dédiée) -->
        <div class="watching-details-card-component__grid-column-image">
            <div class="image" *ngIf="details.WatchingTypeCode == 'FG'"><image-with-popover [imageUrl]="this.getImageUrl(details)" /></div>
        </div>
    </div>
</div>