import { DateHelper } from "@core/helpers/date-helper";

export class DateRangeCriteria {
    
    public start: Date | null = null;
    public end: Date | null = null;

    // On surcharge le formatteur JSON afin de ne garder que la partie date, sans heure
    public toJSON() {
        const json: { start: string | null, end: string | null } = {
            start: this.start ? DateHelper.formatDate(this.start) : null,
            end: this.end ? DateHelper.formatDate(this.end) : null
        };
        return json;
    }
}