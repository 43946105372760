export enum SearchStringComparison {
    Contains = 0,
    Equal = 1,
    StartsWith = 2,
    EndsWith = 3
}

export class SearchString {

    public constructor(txt: string = "", alphanum: boolean = false, exactmatch: boolean = false) {
        this.Txt = txt;
        this.Alphanum = alphanum;
        this.ExactMatch = exactmatch;
    }

    public Txt: string = "";

    public Alphanum: boolean = false;
    
    public ExactMatch: boolean = false;

    public Comp: SearchStringComparison = SearchStringComparison.Contains;

    public static ComparisonOptions = [SearchStringComparison.Contains, SearchStringComparison.Equal, SearchStringComparison.StartsWith, SearchStringComparison.EndsWith];

    public static GetGlyphiconClass(comp: SearchStringComparison): string {
      switch (comp) {
        case SearchStringComparison.Contains: return 'glyphicon-align-center';
        case SearchStringComparison.Equal: return 'glyphicon-align-justify';
        case SearchStringComparison.StartsWith: return 'glyphicon-align-left';
        case SearchStringComparison.EndsWith: return 'glyphicon-align-right';
      }
    }
}

