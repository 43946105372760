import { DateRangeCriteria } from "./date-range-criteria";
import { GenericCriteria } from "./generic-criteria";
import { SearchString } from "./search-string";

export class DomainNameCriteria extends GenericCriteria {
    public IncludeInactive: boolean = false;
    public Dns: SearchString = new SearchString();
    public Extension: SearchString = new SearchString();
    public BookingDate: DateRangeCriteria = new DateRangeCriteria();
    public Owner: number = 0;
    public RefGM: SearchString = new SearchString();
    public Client: SearchString = new SearchString();
    public DueDates: DateRangeCriteria = new DateRangeCriteria();

    public override resetToDefault(): void {
        const defaultValues = new DomainNameCriteria();
        Object.assign(this, defaultValues);
    }
}
