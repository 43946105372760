import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AuthService } from '@core/services/auth.service';

import { GenericDetailsCardComponent } from '../generic-details-card/generic-details-card.component';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { TabContentPanelComponent } from '@shared/tab-content-panel/tab-content-panel.component';
import { FEDSoleauEntity } from '@core/models/entities/front-end-displayable-entities/fed-soleau-entity';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'soleau-details-card',
  templateUrl: './soleau-details-card.component.html',
  styleUrls: ['./soleau-details-card.component.scss']
})
export class SoleauDetailsCardComponent extends GenericDetailsCardComponent {
  @Input({ required: true }) details!: FEDSoleauEntity;
  @Input({ required: true }) parent!: TabContentPanelComponent;

  constructor(public translateService: EpisoftTranslateService, authService: AuthService) {
    super(authService);
  }

  public selectItem() {
    // Elément (dé)sélectionné : on transmet l'info au parent pour mettre à jour la check box de sélection globale
    this.details.IsSelected = !this.details.IsSelected;
    // On passe par un "input" plutôt que par un évènement classique car le composant est généré dynamiquement et output n'est pas encore géré par ngComponentOutlet
    this.parent.itemSelectionStateChangedCallback(this.details.IsSelected);
  }

  // Connexion ZenWeb
  public openDocuments() {   
    let url: string = `${environment.zenWebUrl}/content/B/${this.details.CodeZen}?token=${this.authService.getToken()}`;
    window.open(url);
  };

}
