import { Component, Input } from '@angular/core';
import { SoleauCriteria } from '@core/models/criterias/soleau-criteria';
import { Owner } from '@core/models/owner';
import { TabContext } from '@core/models/tab-contexts/tab-context';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'soleau-search-panel',
  templateUrl: './soleau-search-panel.component.html',
  styleUrls: ['./soleau-search-panel.component.scss']
})
export class SoleauSearchPanelComponent {
  @Input() tabContext! : TabContext;

  searchCriterias: SoleauCriteria = new SoleauCriteria();

  public getSearchCriterias(): SoleauCriteria {
    return this.searchCriterias;
  }

  constructor(public translateService: EpisoftTranslateService) {}

  ngOnInit(){
    if (this.tabContext != undefined){
      this.tabContext.searchCriterias = this.searchCriterias;
      this.searchCriterias.DepNum.Alphanum = true;
    }
  }

  ownerLabelSelector : (owner : Owner) => string = owner => owner.Name;
  
  ownerIsActiveSelector : (owner : Owner) => boolean = owner => owner.HasActiveItems;
  
  ownerValueSelector : (owner : Owner) => Number = owner => owner.Code;

  updateOwner(newValue: string) {
    let newValueAsNumber: number = Number(newValue);
    newValueAsNumber = isNaN(newValueAsNumber) ? 0 : newValueAsNumber;
    this.searchCriterias.Owner = newValueAsNumber;
  }
  
  updateIncludeInactive(newValue: boolean){
    this.searchCriterias.IncludeInactive = newValue;
  }
}
