import { TabContext } from "./tab-context";
import { StringHelper } from "@core/helpers/string-helper";
import { WatchingsService } from "@core/services/watchings.service";
import { EpisoftTranslateService } from '@core/services/translate.service';
import { ParametersService } from "@core/services/parameters.service";
import { Observable } from "rxjs";
import { FEDWatchingEntity } from "../entities/front-end-displayable-entities/fed-watching-entity";
import { GenericCriteria } from "../criterias/generic-criteria";
import { WatchingCriteria } from "../criterias/watching-criteria";
import { ExportActionType } from "../export-action";

export class WatchingsTabContext extends TabContext {

    constructor(override translateService: EpisoftTranslateService, override parametersService : ParametersService, private watchingsService: WatchingsService) {
        super(translateService, parametersService);
    }

    static readonly moduleId = "watchings";
    public disableOptions : boolean = false; 
    public disableCountries : boolean = false;
    public disableClasses : boolean = false;

    override getModuleId(): string {
        return WatchingsTabContext.moduleId;
    }

    protected override customLoadData(searchCriterias : GenericCriteria | undefined): Observable<any> {
        let customSearchCriterias = searchCriterias as WatchingCriteria;
        return this.watchingsService.GetWatchings(customSearchCriterias);
    }

    override loadParameters() : void {
        this.watchingsService.GetParams().subscribe(r => {
            this.parameters.types = r.TypeTitles;
            this.parameters.typeTitlesFilter = r.TypeTitlesFilter;
            this.parameters.options = r.WatchingOption;
            this.parameters.filteredOptions = r.WatchingOption;
            this.parameters.optionsFilter = r.OptionsFilter;
            this.parameters.countriesFilter = r.CountriesFilter;
            this.loadCurrentLanguageEntityParameters();
            this.sortEntityParameters();  
        }
        );
        this.parametersService.Get().subscribe(r => {
            this.parameters.countries = r.Countries;
            this.parameters.filteredCountries = r.Countries;
            this.loadCurrentLanguageGlobalParameters();
            this.sortGlobalParameters();
          }
        );  
    }

    public loadTypeAhead(){
        this.watchingsService.getTypeAhead().subscribe(response => {
            this.typeAhead = response;
            this.typeAhead.sort()
        });
    }

    override sortData() {
        let rawData: FEDWatchingEntity[] = this.data;
        rawData.sort((a, b) => {
            // Fonction de comparaison en fonction du sortCode
            switch (this.sort.selectedOptionCode) {
                case 'Name': //Dénomination
                    return StringHelper.compareStrings(a.Denomination, b.Denomination);
                case 'Options': // Option de surveillance
                    return StringHelper.compareStrings(a.CurrentOptionLabel, b.CurrentOptionLabel);
                case 'Type': // Type de surveillance
                    return StringHelper.compareStrings(a.TypeLabel, b.TypeLabel);
                default:
                    return 0; // Aucun tri par défaut
            }
        });

        if (!this.sort.useSortOrderASC) {
            rawData.reverse();
        }

        this.data = rawData;
    } 
    
    public override resetSearchCriterias(): void {
        if (this.searchCriterias === null) this.searchCriterias = new WatchingCriteria();
        this.searchCriterias?.resetToDefault();
    }

    public override export(exportType: ExportActionType): void {
        this.watchingsService.Export(exportType, this.data.filter(d => d.IsSelected));
    }

    public override getAvailableExportTypes(): ExportActionType[] {
        return [ExportActionType.List];
      }
}