<div class="trademark-details-card-component">
    <div class="trademark-details-card-component__header">
        <input class="epw-checkbox" id="checkboxSelectItem{{ details.RefGM }}" type="checkbox" value="on"
            [checked]="details.IsSelected" (click)="selectItem()" *ngIf="details.Fractions.length == 0">
        <label class="epw-label bigger bold" for="checkboxSelectItem{{ details.RefGM }}">{{ details.Root.Name}}</label>
        <button class="trademark-details-card-component__button-open-documents" [disabled]="details.Root.CodeZen === ''" (click)="openDocuments(details.Root.CodeZen)" [title]="this.translateService.getLabel('common.documents')">
            {{ translateService.getLabel('common.documents') }}<span class="glyphicon glyphicon-duplicate"></span>
        </button>
        <button class="trademark-details-card-component__button-open-details" (click)="openDetails(details.Root.RefGM, details.GUID)" [title]="this.translateService.getLabel('common.details')">
            {{ translateService.getLabel('common.details') }}<span class="glyphicon glyphicon-new-window"></span>
        </button>
    </div>
    <div class="trademark-details-card-component__grid">
        <!-- 1ère colonne -->
        <div class="trademark-details-card-component__grid-column">
            <div class="trademark-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.countryDep') }}</div>
            <div class="trademark-details-card-component__grid-cell-value">{{ details.Root.DepositCountryLabel }}&nbsp;
                <button *ngIf="details.Root.DesignatedCountries.length > 0" class="trademark-details-card-component__button-designated-countries" 
                    (mouseover)="this.showDesignatedCountryToolTip = true;" (mouseout)="this.showDesignatedCountryToolTip = false;">
                <span class="glyphicon glyphicon-flag"></span>
                <custom-tooltip *ngIf="this.showDesignatedCountryToolTip" [title]="this.translateService.getLabel('common.shared.countryDes')" [content]="details.Root.DesignatedCountriesLabel"></custom-tooltip>
                </button> 
            </div>
            <div class="trademark-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.depDate') }}</div>
            <div class="trademark-details-card-component__grid-cell-value">{{ details.Root.OriDepDateLabel }}&nbsp;</div>    
            <div class="trademark-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.regDate') }}</div>
            <div class="trademark-details-card-component__grid-cell-value">{{ details.Root.CurrRegDateLabel}}&nbsp;</div>
            <div class="trademark-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.regNum') }}</div>
            <div class="trademark-details-card-component__grid-cell-value">{{ details.Root.CurrRegNum }}&nbsp;</div>
            <div class="trademark-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.status') }}</div>
            <div class="trademark-details-card-component__grid-cell-value">{{ details.SituationLabel }}&nbsp;</div>
        </div>
        <!-- 2ème colonne -->
        <div class="trademark-details-card-component__grid-column">
            <div class="trademark-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.type') }}</div>
            <div class="trademark-details-card-component__grid-cell-value">{{ details.Root.TypeLabel }}&nbsp;</div>
            <div class="trademark-details-card-component__grid-cell-title">{{ translateService.getLabel('trademark.props.classes') }}</div>
            <div class="trademark-details-card-component__grid-cell-value">{{ details.Root.ClassesLabel}}&nbsp;</div> 
            <div class="trademark-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.owner') }}</div>
            <div class="trademark-details-card-component__grid-cell-value">{{ details.Root.OwnersLabel }}&nbsp;</div>
            <div class="trademark-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.renewDates') }}</div>
            <div class="trademark-details-card-component__grid-cell-value">{{ details.Root.RenewDueDatesLabels }}&nbsp;</div>
            <div class="trademark-details-card-component__grid-cell-title">{{ translateService.getLabel('common.shared.refGM') }}</div>
            <div class="trademark-details-card-component__grid-cell-value">{{ details.Root.RefGM }}&nbsp;</div>
        </div>
        <!-- Image (colonne dédiée) -->
        <div class="trademark-details-card-component__grid-column-image">
            <image-with-popover [imageUrl]="this.getImageUrl(details)" />
        </div>
    </div>
    <!-- Fractions -->
    <div *ngIf="details.Fractions.length > 0">
        <div class="trademark-details-card-component__grid-fractions trademark-details-card-component__grid-fractions-header">
            <!-- Entête-->
            <div class="trademark-details-card-component__grid-fractions-column-checkbox"><span class="trademark-details-card-component__badge gm-margin-bottom-5px">{{details.Fractions.length}}</span></div>
            <div class="trademark-details-card-component__grid-fractions-column-buttons"></div>
            <div class="trademark-details-card-component__grid-fractions-column-country trademark-details-card-component__grid-fractions-cell-title">{{ translateService.getLabel('common.shared.countryDep') }}&nbsp;</div>
            <div class="trademark-details-card-component__grid-fractions-column-label trademark-details-card-component__grid-fractions-cell-title">{{ translateService.getLabel('common.shared.designation') }}&nbsp;</div>
            <div class="trademark-details-card-component__grid-fractions-column-situation trademark-details-card-component__grid-fractions-cell-title">{{ translateService.getLabel('common.shared.status') }}&nbsp;</div>
            <div class="trademark-details-card-component__grid-fractions-column-classes trademark-details-card-component__grid-fractions-cell-title">{{ translateService.getLabel('trademark.props.classes') }}&nbsp;</div>
        </div>
        <!-- Lignes des fractions-->
        <div *ngFor="let fraction of this.details.Fractions">
            <div class="trademark-details-card-component__grid-fractions trademark-details-card-component__grid-fractions-data">
                <div class="trademark-details-card-component__grid-fractions-column-checkbox">
                    <input class="checkbox-select" id="checkboxSelectItem{{ fraction.RefGM }}" type="checkbox" value="on"
                            [checked]="fraction.IsSelected" (click)="selectFraction(fraction.RefGM)">&nbsp;
                </div>
                <div class="trademark-details-card-component__grid-fractions-column-buttons">
                    <button class="trademark-details-card-component__grid-fractions-button-open-documents" [disabled]="fraction.CodeZen === ''" (click)="openDocuments(fraction.CodeZen)" [title]="this.translateService.getLabel('common.documents')">
                        <span class="glyphicon glyphicon-duplicate"></span>
                    </button>&nbsp;
                    <button class="trademark-details-card-component__grid-fractions-button-open-details" (click)="openDetails(fraction.RefGM, details.GUID)" [title]="this.translateService.getLabel('common.details')">
                        <span class="glyphicon glyphicon-new-window"></span>
                    </button>&nbsp;
                </div>
                <div class="trademark-details-card-component__grid-fractions-column-country trademark-details-card-component__grid-fractions-cell-value">{{ fraction.DepositCountryLabel }}&nbsp;
                    <button *ngIf="fraction.DesignatedCountries.length > 0" class="trademark-details-card-component__button-designated-countries" 
                        (mouseover)="this.showDesignatedCountryToolTip = true;" (mouseout)="this.showDesignatedCountryToolTip = false;">
                    <span class="glyphicon glyphicon-flag"></span>
                    <custom-tooltip *ngIf="this.showDesignatedCountryToolTip" [title]="this.translateService.getLabel('common.shared.countryDes')" [content]="fraction.DesignatedCountriesLabel"></custom-tooltip>
                    </button> 
                </div> 
                <div class="trademark-details-card-component__grid-fractions-column-label trademark-details-card-component__grid-fractions-cell-value">
                    {{ fraction.DesignationLabel }}
                    <span>{{ translateService.getLabel('common.shared.designation_since') }}</span>&nbsp;<span>{{ fraction.DesignationDateLabel }}</span></div>
                <div class="trademark-details-card-component__grid-fractions-column-situation trademark-details-card-component__grid-fractions-cell-value">{{ fraction.SituationLabel }}</div>
                <div class="trademark-details-card-component__grid-fractions-column-classes trademark-details-card-component__grid-fractions-cell-value">{{ fraction.Classes }}</div>
            </div>
        </div>
    </div>
</div>