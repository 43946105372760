import { Component, Input } from '@angular/core';
import { TrademarkCriteria } from "@core/models/criterias/trademark-criteria";
import { Owner } from '@core/models/owner';
import { TabContext } from '@core/models/tab-contexts/tab-context';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'trademarks-search-panel',
  templateUrl: './trademarks-search-panel.component.html',
  styleUrls: ['./trademarks-search-panel.component.scss']
})
export class TrademarksSearchPanelComponent {
  @Input() tabContext! : TabContext;

  searchCriterias : TrademarkCriteria = new TrademarkCriteria();

  constructor(public translateService: EpisoftTranslateService) {}

  ngOnInit(){
    if (this.tabContext != undefined){
      this.tabContext.searchCriterias = this.searchCriterias;
      this.searchCriterias.RegNum.Alphanum = true;
      this.searchCriterias.DepNum.Alphanum = true;
      this.searchCriterias.PrioNum.Alphanum = true;
    }
  }

  updateExactMatch(newValue: boolean){
    this.searchCriterias.Name.ExactMatch = newValue;
  }

  // Méthodes gérant la liste déroulante "Titulaire"
  ownerLabelSelector : (owner : Owner) => string = owner => owner.Name;
  
  ownerIsActiveSelector : (owner : Owner) => boolean = owner => owner.HasActiveItems;
  
  ownerValueSelector : (owner : Owner) => Number = owner => owner.Code;

  updateOwner(newValue: string) {
    let newValueAsNumber: number = Number(newValue);
    newValueAsNumber = isNaN(newValueAsNumber) ? 0 : newValueAsNumber;
    this.searchCriterias.Owner = newValueAsNumber;
  }

  // Méthodes gérant la liste déroulante "Pays" 
  countryLabelSelector : (country: any) => string = country => country.CurrentCountryLabel;

  countryIsActiveSelector: Function = (a: any): boolean => true; //Tous les pays sont toujours proposés par défaut

  countryValueSelector: (country: any) => string = country => String(country.Code);

  updateCountry(newValue: string){
    this.searchCriterias.Country = newValue;
  }

  // Méthodes gérant la liste déroulante "Type marque"
  typeLabelSelector : (TypeTitle: any) => string = TypeTitle => TypeTitle.CurrentTypeLabel;

  typeIsActiveSelector: Function = (a: any): boolean => true; //Tous les types de dossiers sont toujours proposés par défaut

  typeValueSelector: (TypeTitle: any) => string = TypeTitle => String(TypeTitle.Code);

  updateType(newValue: any) {
    this.searchCriterias.TypeTitle = newValue;
  }

  updateClasses(newValue: any){
    // Ajout du numéro de classe coché en liste de critères de recherche si inexistant
    // Retrait du numéro de classe décoché si existant dans liste de critères de recherche
    if (!(this.searchCriterias.Classes.find(c => c === newValue.Num)) && newValue.isChecked){
      this.searchCriterias.Classes.push(newValue.Num);
      this.searchCriterias.Classes.sort();
    } else if (this.searchCriterias.Classes.find(c => c === newValue.Num) && !newValue.isChecked){
      this.searchCriterias.Classes.splice(this.searchCriterias.Classes.findIndex(c => { return c ===newValue.Num }), 1)     
    }    
  }

  updateIncludeInactive(newValue: boolean){
    this.searchCriterias.IncludeInactive = newValue;
  }
}
