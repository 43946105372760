<div *ngIf="this.patentDetails !== undefined && patentDetails.DepositCountry !== undefined" class="detail">
    <button class="button-open-documents" (click)="openDocuments()" [title]="this.translateService.getLabel('common.documents')">
        <span> {{ translateService.getLabel('common.documents') }}&nbsp;&nbsp; </span>       
        <span class="glyphicon glyphicon-duplicate"></span>
    </button>
    <div class="main-header">
        <!-- 1ère ligne -->
        <div class="cell-title">{{ translateService.getLabel('patent.props.name') }}</div>
        <div class="text-success">{{ patentDetails.TitleLabel }}</div> 
        <div class="image"><image-with-popover [imageUrl]="this.getImageUrl(patentDetails)"/></div>
        <!-- 2ème ligne -->
        <div class="cell-title">{{ translateService.getLabel('common.shared.countryDep') }}</div>   
        <div class="cell-value">{{ patentDetails.DepositCountry.Label }}</div>
        <div class="cell-large-title">{{ translateService.getLabel('common.shared.depKind') }}</div>
        <div class="cell-large-value">{{ patentDetails.DepositKindLabel }}</div>               
        <!-- 3ème ligne -->
        <div class="cell-title">{{ translateService.getLabel('common.shared.type') }}</div>
        <div class="cell-value">{{ patentDetails.TypeTitle.Label }}</div>               
        <!-- 4ème ligne -->
        <div class="cell-title">{{ translateService.getLabel('common.shared.refGM') }}</div>
        <div class="cell-value">{{ patentDetails.RefGM }}</div>
        <div class="cell-title">{{ translateService.getLabel('common.shared.status') }}</div>
        <div class="cell-value">{{ patentDetails.Situation.Label }}</div> 
        
        <div rowspan="1" colspan="5" *ngIf="patentDetails.Summary.length > 0" class="abstract">
            <div class="cell-title">{{ translateService.getLabel('patent.props.description') }}</div>
            <div class="description">{{ patentDetails.Summary }}</div>
        </div>
    </div>
    <mat-expansion-panel class="details-panel mat-elevation-z0" expanded="true" hideToggle>
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-info-sign icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.general') }}</div>
        </mat-expansion-panel-header>
        <div class="head">
            <!-- Entête-->
            <div class="head-border">
                <div class="head-column"></div>
                <div class="date-column"><strong>{{ translateService.getLabel('common.shared.date') }}</strong></div>
                <div class="number-column"><strong>{{ translateService.getLabel('common.shared.number') }}</strong></div>
            </div>
            <!-- 1ère ligne -->
            <div class="head-thin-border">
                <div class="head-column">{{ translateService.getLabel('common.shared.depOri') }}</div>
                <div class="date-column">{{ patentDetails.OriDepDateLabel }}</div>
                <div class="number-column">{{ patentDetails.OriDepNum }}</div>
            </div>
            <!-- 2ème ligne -->
            <div class="head-thin-border">
                <div class="head-column">{{ translateService.getLabel('common.shared.pub') }}</div>
                <div class="date-column">{{ patentDetails.PubDateLabel }}</div>
                <div class="number-column">{{ patentDetails.PubNum }}</div>
            </div>
            <!-- 3ème ligne -->
            <div class="head-thin-border">
                <div class="head-column">{{ translateService.getLabel('patent.props.patent') }}</div>
                <div class="date-column">{{ patentDetails.PatentDateLabel }}</div>
                <div class="number-column">{{ patentDetails.PatentNum }}</div>
            </div>
            <!-- 4ème ligne -->
            <div class="head-thin-border">
                <div class="head-column">{{ translateService.getLabel('common.shared.prio') }}</div>
                <div class="date-column">{{ patentDetails.PrioDateLabel }}</div>
                <div class="number-column">{{ patentDetails.PrioNum }}</div>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="details-panel mat-elevation-z0" expanded="true" hideToggle>
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-link icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.owners') }}</div>
        </mat-expansion-panel-header>
        <div class="persons">
            <div class="person" *ngFor="let owner of this.patentDetails.Owners">
                <address>
                    <strong>{{owner.Name}}</strong>
                    <br />
                    <span class="small">
                        {{owner.Addr1}}{{owner.Addr2}}{{owner.Addr3}}<br />
                        {{owner.City}} {{owner.Postcode}}, {{owner.Country.Label}}
                    </span>
                </address>
            </div>
        </div>          
    </mat-expansion-panel>
    <mat-expansion-panel class="details-panel mat-elevation-z0" expanded="true" hideToggle>
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-education icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.inventors')}}</div>
        </mat-expansion-panel-header>   
        <div class="persons">     
            <div class="person" *ngFor="let inventor of this.patentDetails.Inventors" >
                <address>
                    <strong>{{inventor.Name}}</strong>
                    <br />
                    <span class="small">
                        {{inventor.Addr1}}{{inventor.Addr2}}{{inventor.Addr3}}<br />
                        {{inventor.City}} {{inventor.Postcode}}, {{inventor.Country.Label}}
                    </span>
                </address>
            </div>
        </div>     
    </mat-expansion-panel>
    <mat-expansion-panel class="details-panel mat-elevation-z0" expanded="true" hideToggle *ngIf="this.patentDetails.Annuities.length > 0">
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-calendar icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.annuities') }}</div>
        </mat-expansion-panel-header>
        <div class="annuities">       
            <div class="annuity" *ngFor="let annuity of this.patentDetails.Annuities">           
                <strong>{{ annuity.Qte }}&nbsp;&nbsp;&nbsp;</strong>
                <div>{{ annuity.DateLabel }}</div>              
            </div>
        </div>    
    </mat-expansion-panel>

    <mat-expansion-panel class="details-panel mat-elevation-z0" hideToggle *ngIf="this.patentDetails.Records.length > 0">
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-th-list icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.registry') }}</div>
        </mat-expansion-panel-header>
          <!-- Entête-->
          <div class="content-header records">
            <strong class="table-item">{{ translateService.getLabel('common.shared.kind') }}</strong>
            <strong class="table-item">{{ translateService.getLabel('common.shared.date') }}</strong>
            <strong class="table-item">{{ translateService.getLabel('common.shared.number') }}</strong>
            <strong class="table-item">{{ translateService.getLabel('common.shared.comment') }}</strong>
        </div>
        <div>                                  
            <div *ngFor="let record of this.patentDetails.Records">
                <div class="records">
                    <div class="table-item">{{ record.Nature.Label }}</div>
                    <div class="table-item">{{ record.DateLabel }}</div>
                    <div class="table-item">{{ record.Num }}</div>
                    <div class="table-item">{{ record.TxtLabel }}</div>
                </div>
            </div>
        </div> 
    </mat-expansion-panel>

    <mat-expansion-panel class="details-panel mat-elevation-z0" hideToggle *ngIf="this.patentDetails.DesignatedCountries.length > 0">
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-globe icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.countryDes') }}</div>
        </mat-expansion-panel-header>
         <!-- Entête-->
         <div class="content-header">
            <strong class="cell-title-44">{{ translateService.getLabel('common.shared.country') }}</strong>
            <strong class="cell-title-22">{{ translateService.getLabel('common.shared.status') }}</strong>
            <strong class="cell-title-22">{{ translateService.getLabel('common.shared.designation') }}</strong>
            <strong class="cell-title-22">{{ translateService.getLabel('common.shared.dateEffect') }}</strong>
        </div>
        <div>                                  
            <div *ngFor="let designatedCountry of this.patentDetails.DesignatedCountries">
                <div class="countryDes">
                    <div class="table-item-44">{{ designatedCountry.Label.Label }}</div>
                </div>
            </div>
        </div>      
    </mat-expansion-panel>

    <mat-expansion-panel class="details-panel mat-elevation-z0" expanded="true" hideToggle *ngIf="this.patentDetails.Billings.length > 0">
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-euro icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.invoice') }}</div>
        </mat-expansion-panel-header>
        <div *ngIf="this.canAccessFinancialData()">
            <!-- Entête-->
            <div class="billings-header">
                <strong class="billings-header-cell-title">{{ translateService.getLabel('common.shared.date') }}</strong>
                <strong class="billings-header-cell-title">{{ translateService.getLabel('common.shared.type') }}</strong>
                <strong class="billings-header-cell-title">{{ translateService.getLabel('common.shared.number') }}</strong>
                <strong class="billings-header-amount">{{ translateService.getLabel('common.shared.invoice.ht') }}</strong>
                <strong class="billings-header-amount">{{ translateService.getLabel('common.shared.invoice.tva') }}</strong>
                <strong class="billings-header-amount">{{ translateService.getLabel('common.shared.invoice.ttc') }}</strong>
                <strong class="billings-header-cell-title">{{ translateService.getLabel('common.shared.invoice.to') }}</strong>
            </div>
            <div>                                  
                <div *ngFor="let billing of this.patentDetails.Billings">
                    <div class="billings">
                        <div class="table-item">{{ billing.DateLabel }}</div>
                        <div class="table-item">{{ billing.BillingType.Label }}</div>
                        <div class="table-item">{{ billing.Num }}</div>
                        <div class="billings-item-amount">{{ billing.HTLabel }}</div>
                        <div class="billings-item-amount">{{ billing.TVALabel }}</div>
                        <div class="billings-item-amount">{{ billing.AmountLabel }}</div>
                        <div class="table-item">{{ billing.InvoicedTo }}</div>
                    </div>
                </div>     
                <div class="billings">
                    <div class="billings-spacer"></div>
                    <strong class="billings-item-amount">{{ patentDetails.TotalHT }}</strong>
                    <strong class="billings-item-amount">{{ patentDetails.TotalTVA }}</strong>
                    <strong class="billings-item-amount">{{ patentDetails.TotalAmount }}</strong>
                </div>                    
            </div>
        </div>
    </mat-expansion-panel>
</div>