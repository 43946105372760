import { Component, Input } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateRangeCriteria } from '@core/models/criterias/date-range-criteria';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'search-panel-input-date-range',
  templateUrl: './search-panel-input-date-range.component.html',
  styleUrls: ['./search-panel-input-date-range.component.scss'], 
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr'},
  ],
})
export class SearchPanelInputDateRangeComponent {
    @Input({ required: true }) headerText : string = '';
    @Input() criteria: DateRangeCriteria = new DateRangeCriteria();

  constructor(public translateService: EpisoftTranslateService,   private dateAdapter: DateAdapter<Date>,) {
}

  ngOnInit() {
    this.dateAdapter.setLocale('fr');
  }

}
