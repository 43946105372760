import { BERDomainNameEntity } from "../back-end-raw-entities/ber-domain-name-entity";
import { FEDBaseEntity } from "./fed-base-entity";

export class FEDDomainNameEntity extends FEDBaseEntity implements BERDomainNameEntity {
    constructor(source : BERDomainNameEntity) {
        super(source);
        Object.assign(this, source);
    }
    // Données de BERDomainNameEntity
    DNS!: string;
    Owner!: { Name: string; };
    BookingDate!: Date;
    RenewDueDates!: { Type: string; Date: Date; }[];
    // Données connues uniquement côté Front End
    RenewDueDatesLabels! : string;
    BookDateLabel! : string;
}
