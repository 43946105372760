import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() { }

  public log(...messages: any[]): void {
    if (!environment.production) {
      console.log(...messages);
    }
  }

  public error(...messages: any[]): void {
    if (!environment.production) {
      console.error(...messages);
    }
  }
}
