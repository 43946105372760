<div class="admin-tab-component">
    <div class="admin-tab-component__header">
        <button class="admin-tab-component__navigation-button" [class.selected]="this.currentSubPage == this.SubPage.Users" (click)="updateCurrentSubPage(this.SubPage.Users)">{{ translateService.getLabel('admin.users.name') }}
            <span class="glyphicon glyphicon-user"></span></button>
        <button class="admin-tab-component__navigation-button" [class.selected]="this.currentSubPage == this.SubPage.Profiles" (click)="updateCurrentSubPage(this.SubPage.Profiles)">{{ translateService.getLabel('admin.profiles.name') }}
            <span class="glyphicon glyphicon-tags"></span></button>
        <button *ngIf="this.currentUser?.IsSuperAdmin" class="admin-tab-component__navigation-button" [class.selected]="this.currentSubPage == this.SubPage.Messages" (click)="updateCurrentSubPage(this.SubPage.Messages)">{{ translateService.getLabel('admin.messager.name') }}
            <span class="glyphicon glyphicon-edit"></span></button>
    </div>
    <div class="admin-tab-component__header">
        <button class="epw-blue-button" *ngIf="this.currentSubPage == this.SubPage.Users && !this.currentUser?.IsSuperAdmin" (click)="showUserDialog(undefined)">{{ translateService.getLabel('admin.newUser') }}
            <span class="glyphicon glyphicon-plus"></span></button>
        <button class="epw-blue-button" *ngIf="this.currentSubPage == this.SubPage.Users && this.currentUser?.IsSuperAdmin" (click)="showUserDialog(undefined)">{{ translateService.getLabel('admin.newAdmin') }}
            <span class="glyphicon glyphicon-plus"></span></button>
        <button class="epw-blue-button" *ngIf="this.currentSubPage == this.SubPage.Profiles" (click)="showProfileDialog(undefined)">{{ translateService.getLabel('admin.newProfile') }}
            <span class="glyphicon glyphicon-plus"></span></button>
    </div>
    <div *ngIf="this.currentSubPage == this.SubPage.Users">
        <div class = "admin-tab-component__table">
            <div class="admin-tab-component__table-row-users-header">
                <div class="admin-tab-component__cell-title">{{ translateService.getLabel('admin.users.table.name') }}</div>
                <div class="admin-tab-component__cell-title">{{ translateService.getLabel('admin.users.table.login') }}</div>
                <div class="admin-tab-component__cell-title">{{ translateService.getLabel('admin.users.table.profile') }}</div>
                <div class="admin-tab-component__cell-title-centered">{{ translateService.getLabel('admin.users.table.admin') }}</div>
            </div>
            <div *ngFor="let user of this.users">
                <div class="admin-tab-component__table-row-users-data" (click)="showUserDialog(user)" >
                    <div class="admin-tab-component__cell-value">{{ user.Fullname }}</div>
                    <div class="admin-tab-component__cell-value">{{ user.Login }}</div>
                    <div class="admin-tab-component__cell-value">{{ user.Profile.Name }}</div>
                    <div class="admin-tab-component__cell-value-centered">
                        <span *ngIf="user.UserLevel >= 1" class="glyphicon glyphicon-ok"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="this.currentSubPage == this.SubPage.Profiles">
        <div class = "admin-tab-component__table">
            <div class="admin-tab-component__table-row-profiles-header">
                <div class="admin-tab-component__cell-title">{{ translateService.getLabel('admin.profiles.table.name') }}</div>
                <div class="admin-tab-component__cell-title">{{ translateService.getLabel('admin.profiles.table.authorizationLevel') }}</div>
                <div class="admin-tab-component__cell-title">{{ translateService.getLabel('admin.profiles.table.wallet') }}</div>
                <div class="admin-tab-component__cell-title-centered">{{ translateService.getLabel('admin.profiles.table.financialAccess') }}</div>
            </div>
            <div *ngFor="let profile of this.profiles">
                <div class="admin-tab-component__table-row-profiles-data" (click)="showProfileDialog(profile)">
                    <div class="admin-tab-component__cell-value">{{ profile.Name }}</div>
                    <div class="admin-tab-component__cell-value">{{ translateService.getLabel('admin.profiles.authLevels.' + profile.GetAuthorizationLevelLabel()) }}</div>
                    <div class="admin-tab-component__cell-value">{{ profile.getFilteringOwnersLabels(this.wallet) }}</div>
                    <div class="admin-tab-component__cell-value-centered">
                        <span *ngIf="profile.AccessFinancialData" class="glyphicon glyphicon-ok"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>