import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { TabContext } from '@core/models/tab-contexts/tab-context';
import { TrademarkDetailsCardComponent } from '@shared/details-cards/trademark-details-card/trademark-details-card.component';
import { DrawingModelDetailsCardComponent } from '@shared/details-cards/drawing-model-details-card/drawing-model-details-card.component';
import { PatentDetailsCardComponent } from '@shared/details-cards/patent-details-card/patent-details-card.component';
import { DomainNameDetailsCardComponent } from '@shared/details-cards/domain-name-details-card/domain-name-details-card.component';
import { OffTitleDetailsCardComponent } from '@shared/details-cards/off-title-details-card/off-title-details-card.component';
import { SoleauDetailsCardComponent } from '@shared/details-cards/soleau-details-card/soleau-details-card.component';
import { WatchingDetailsCardComponent } from '@shared/details-cards/watching-details-card/watching-details-card.component';
import { FEDBaseEntity } from '@core/models/entities/front-end-displayable-entities/fed-base-entity';

// Dictionnaire pour mapper les modules aux composants correspondants
const moduleComponentMap: { [moduleId: string]: any } = {
  'trademarks': TrademarkDetailsCardComponent,
  'drawingModels': DrawingModelDetailsCardComponent,
  'patents':PatentDetailsCardComponent,
  'domainNames': DomainNameDetailsCardComponent,
  'offTitles': OffTitleDetailsCardComponent,
  'soleau': SoleauDetailsCardComponent,
  'watchings' : WatchingDetailsCardComponent,
};

@Component({
  selector: 'tab-content-panel',
  templateUrl: './tab-content-panel.component.html',
  styleUrls: ['./tab-content-panel.component.scss']
})
export class TabContentPanelComponent implements OnInit {
  @Output() toggleSearchPanel = new EventEmitter<void>();
  @Input() tabContext! : TabContext;

  selectedComponent : any;

  constructor(private changeDetectorRef: ChangeDetectorRef) {

  }
  ngOnInit(): void {
    if(this.tabContext != undefined) {
      this.selectedComponent = moduleComponentMap[this.tabContext.getModuleId()];
    }
  }

  itemSelectionStateChangedCallback(b : boolean) {
    this.selectionStateChanged();
  }

  selectAllValueChanged() {
    // (dé)Sélection de tous les éléments
    this.tabContext.data.forEach(e => {
      let a: FEDBaseEntity = e;
      if (a != undefined) {
        // On passe par le selectItem du contexte plutôt que par une affectation directe de a.selected
        // Cela permet de gérer le cas des sous-éléments qui doivent être automatiquement (dé)sélectionnés
        // C'est par exemple le cas des fractions de marque inter
        this.tabContext.selectItem(a, this.tabContext.selectAllIsChecked);
      }
    }
    );
    this.changeDetectorRef.detectChanges();
  }

  selectionStateChanged() {
   // Un élément a été (dé)sélectionné : si tous les éléments sont sélectionnés : on coche la case de sélection globale
   this.tabContext.selectAllIsChecked = (this.tabContext.data.find(e => !e.IsSelected) === undefined);
  }

}
