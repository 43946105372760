import { TabContext } from "./tab-context";
import { StringHelper } from "@core/helpers/string-helper";
import { DateHelper } from "@core/helpers/date-helper";
import { DrawingModelsService } from "@core/services/drawing-models.service";
import { EpisoftTranslateService } from '@core/services/translate.service';
import { ParametersService } from "@core/services/parameters.service";
import { Observable } from "rxjs";
import { FEDDrawingModelEntity } from "../entities/front-end-displayable-entities/fed-drawing-model-entity";
import { FEDBaseEntity } from "../entities/front-end-displayable-entities/fed-base-entity";
import { GenericCriteria } from "../criterias/generic-criteria";
import { DrawingModelCriteria } from "../criterias/drawing-model-criteria";
import { ExportActionType } from "../export-action";

export class DrawingModelsTabContext extends TabContext {

    constructor(override translateService: EpisoftTranslateService, override parametersService: ParametersService, private drawingModelsService: DrawingModelsService) {
        super(translateService, parametersService);
    }

    static readonly moduleId = "drawingModels";

    override getModuleId(): string {
        return DrawingModelsTabContext.moduleId;
    }

    protected override customLoadData(searchCriterias : GenericCriteria | undefined): Observable<any> {
        let customSearchCriterias = searchCriterias as DrawingModelCriteria;
        return this.drawingModelsService.GetDrawingModels(customSearchCriterias);
    }

    override loadParameters(): void {
        this.drawingModelsService.GetParams().subscribe(r => {
            this.parameters.owners = r.Owners;
            this.parameters.types = r.TypeTitles;
            this.loadCurrentLanguageEntityParameters();
            this.sortEntityParameters();
        });
        this.parametersService.Get().subscribe(r => {
            this.parameters.countries = r.Countries;
            this.loadCurrentLanguageGlobalParameters();
            this.sortGlobalParameters();
        });
    }
    
    public loadTypeAhead(){
        this.drawingModelsService.getTypeAhead().subscribe(response => {
            this.typeAhead = response;
            this.typeAhead.sort()
        });
    }

    override sortData() {
        let rawData: FEDDrawingModelEntity[] = this.data;
        rawData.sort((a, b) => {
            // Fonction de comparaison en fonction du sortCode
            switch (this.sort.selectedOptionCode) {
                case 'CurrRegDate':
                    return DateHelper.compareDates(a.Root.CurrRegDate, b.Root.CurrRegDate)
                case 'DepDate':
                    return DateHelper.compareDates(a.Root.OriDepDate, b.Root.OriDepDate)
                case 'RenewDueDates':
                    return DateHelper.compareDates((a.Root.RenewDueDates.length > 0 ? new Date(a.Root.RenewDueDates[0].Date) : new Date(1970, 1, 1)),
                        (b.Root.RenewDueDates.length > 0 ? new Date(b.Root.RenewDueDates[0].Date) : new Date(1970, 1, 1)));
                case 'DepositCountryLabel':
                    return StringHelper.compareStrings(a.Root.DepositCountry, b.Root.DepositCountry);
                case 'SituationLabel':
                    return StringHelper.compareStrings(a.SituationLabel, b.SituationLabel);
                case 'TitleLabel':
                    return StringHelper.compareStrings(a.Root.CurrentTitleLabel, b.Root.CurrentTitleLabel);
                case 'Owner':
                    return StringHelper.compareStrings(a.Root.Owners[0].Name, b.Root.Owners[0].Name);
                case 'TypeLabel':
                    return StringHelper.compareStrings(a.Root.Type, b.Root.Type);
                default:
                    return 0; // Aucun tri par défaut
            }
        });

        if (!this.sort.useSortOrderASC) {
            rawData.reverse();
        }

        this.data = rawData;
    }

    // Rédefinition de la méthode de sélection d'un élément afin de gérer les fractions
    override selectItem(item: FEDBaseEntity, select: boolean): void {
        let t : FEDDrawingModelEntity = item as FEDDrawingModelEntity;
        t.IsSelected = select;
        // La (dé)sélection d'une marque inter entraîne celle de ses fractions
        t.Fractions.forEach(element => {
            element.IsSelected = select;
        });
    }

    public override resetSearchCriterias(): void {
        if (this.searchCriterias === null) this.searchCriterias = new DrawingModelCriteria();
        this.searchCriterias?.resetToDefault();  
    }

    public override getAvailableExportTypes(): ExportActionType[] {
        return [ExportActionType.ListByFiling, ExportActionType.ListByDesign, ExportActionType.Map];
      }

      public override export(exportType: ExportActionType): void {
        let selectedItems : FEDBaseEntity[] = [];
        this.data.forEach((drawing: FEDDrawingModelEntity) => {
            let selectedFractions : FEDBaseEntity[] = drawing.Fractions.filter(d => d.IsSelected);
            // Ajout de la marque courante - on ajoute aussi les modèles inter si au moins  une de leur fraction est sélectionnée
            if(drawing.IsSelected || drawing.Root.IsSelected || selectedFractions.length > 0) 
                selectedItems.push(drawing.Root);
            selectedFractions.forEach((fraction: FEDBaseEntity) => {
                selectedItems.push(fraction);
            })
        });
        this.drawingModelsService.Export(exportType, selectedItems);
    }

}