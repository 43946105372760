<div class="login-component__container">
  <div class="login-component__left-side">
    <img src="./assets/images/visuel_fond_GM.png" />
  </div>
  <div class="login-component__right-side">
    <form (ngSubmit)="onSubmit()">

      <div class="login-component__logo">
        <img src="./assets/images/logo2020.png" />
      </div>

      <input type="email" placeholder="Identifiant" class="login-component__login-input" name="login" [(ngModel)]="login" required>

      <input type="password" placeholder="Mot de passe" class="login-component__login-input" name="password" [(ngModel)]="password" required>

      <button class="login-component__red-button" type="submit"  [disabled]="this.login === '' || this.password === ''">{{ translateService.getLabel('auth.signin') }}</button>

      <a class="login-component__account-creation-link"
        href="mailto:gm@germainmaureau.com?subject=Créer un compte / Create an account EpisoftWeb">
        {{ translateService.getLabel('auth.requestAccount') }}</a>

      <div *ngIf="showErrorMessage">
        <h1 class="login-component__error-message">{{ errorMessage }}</h1>
      </div>

      <mat-card *ngIf="messageText !== ''" appearance="outlined" class="login-component__info-message">
        <mat-card-content>{{ messageText }}</mat-card-content>
      </mat-card>

    </form>
  </div>
</div>