import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Imports de modules externes
import { CoreModule } from '@core/core.module';
import { AppRoutingModule } from '../../app-routing.module';


// Imports des composants internes
import { DashboardComponent } from './dashboard/dashboard.component';



@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    CoreModule,
  ]
})
export class DashboardModule { }
