import { Injectable } from '@angular/core';
import { default as apiPathsRawData } from './api-paths.json';

export interface ApiConfigItem {
  id: string;
  url: string;
  requiresAuth: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class ApiPathsService {

  constructor() { 
    this.loadApiConfig();
  }

  private apiPaths: Map<string, ApiConfigItem> = new Map<string, ApiConfigItem>();

  private loadApiConfig(): void {
    // transformation du JSON brut en tableau associatif indexé sur l'id de chaque API path
    apiPathsRawData.apis.forEach((item) => {
      this.apiPaths.set(item.id, {
        id: item.id,
        url: item.url,
        requiresAuth: item.requiresAuth ?? false
      });
    });
  }

  // Récupération de l'URL d'une API à partir de son identifiant et utilisation d'un dictionnaire de paramètres à remplacer dans l'URL obtenu
  public GetUrl(apiId: string, keysDictionary: Map<string, string> = new Map<string, string>()) : string {
    const apiPath: ApiConfigItem = this.apiPaths.get(apiId)!;
    let result: string = apiPath.url;
    // Alimentation des paramètres dynamiques de l'URL
    keysDictionary.forEach((value, key) => {
      result = result.replaceAll("{" + key + "}", value);
    });

    return result;
  }

  // Indique si l'API nécessite de s'être authentifié au préalable
  public RequiresAuth(url: string) : boolean {
    let apiPath = this.getApiConfigItemFromUrl(url);
    return apiPath !== null && apiPath.requiresAuth;
  }

  private getApiConfigItemFromUrl(url: string) : ApiConfigItem | null {
    for(let apiPath of this.apiPaths.values()) {
      if(this.areCompatibleUrls(apiPath.url, url)) {
        //console.log("areCompatibleUrls",apiPath.url,  url);
        return apiPath;
      }
    }
    return null;
  }

  private areCompatibleUrls(template: string, url: string): boolean {
    // Principe : on a une adresse "template", du type /api/zen/epi/patents/{refgm}/image?token={token}
    // Et une adresse "réelle", "url", du type  /api/zen/epi/patents/FOO/image?token=BAR
    // On veut savoir si "url" est compatible avec "template" (ce qui est le cas dans l'exemple ci-dessus)
    // Pour cela, on va construire un regex qui permettra de gérer les parties "dynamiques" (paramétrables) de "template"
    // Dans l'exemple ci-dessus, la regex va etre : \/api\/zen\/epi\/patents\/(.*)\/image\?token=(.*)
    // Il suffira ensuite de l'appliquer à url pour vérifier la compatibilité des urls. 

    // On échappe les caractères qui seraient interprétés par le moteur regex
    let regexPattern = template.replaceAll("/", "\\/");
    regexPattern = regexPattern.replaceAll("?", "\\?");
    // On remplace les parties dynamiques de la forme {FOO} en les remplaçant par une chaine regex (.*)
    regexPattern = regexPattern.replace(/\{[^{}]+\}/g, "(.*)");
    // On créé l'expression régulière qui va bien
    const regex = new RegExp(`^${regexPattern}$`);
    // On teste si l'URL correspond à l'expression régulière
    return regex.test(url);
  }

}
