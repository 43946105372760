import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { EntityRef } from '@core/models/entity-ref';
import { HydratationHelper } from '@core/helpers/hydratation-helper';
import { Owner } from '@core/models/owner';
import { EpisoftTranslateService } from './translate.service';
import { FEDSoleauEntity } from '@core/models/entities/front-end-displayable-entities/fed-soleau-entity';
import { BERSoleauEntity } from '@core/models/entities/back-end-raw-entities/ber-soleau-entity';
import { SoleauCriteria } from '@core/models/criterias/soleau-criteria';
import { ExportActionType } from '@core/models/export-action';
import { ExportService } from './export.service';

export interface SoleauData {
  Refs: EntityRef[];
  Entities: BERSoleauEntity[];
}

export interface SoleauParamsData {
  Owners: Owner[];
  typeDueDates: EntityRef[];
}

@Injectable({
  providedIn: 'root'
})
export class SoleauService {

  constructor(private httpApiService: HttpApiService, private translateService: EpisoftTranslateService, private exportService: ExportService) { }

  public GetSoleau(searchCriterias: SoleauCriteria | null = null): Observable<FEDSoleauEntity[]> {
    return this.httpApiService.post<SoleauData>("API-Soleau-POST", searchCriterias).pipe(
      map((data: SoleauData) => { 
        let entities: FEDSoleauEntity[] = (data.Entities ?? []).map(e => new FEDSoleauEntity(e));
        // Hydratation des données
        HydratationHelper.HydrateSoleaus(entities, data.Refs, this.translateService.getCurrentLanguage());     
        return entities;
    }));
  }

  public GetParams(): Observable<SoleauParamsData> {
    let data: Observable<SoleauParamsData> = this.httpApiService.get<SoleauParamsData>("API-SoleauParams-GET");
    return data.pipe(map((data: SoleauParamsData) => { 
      return data;
    }));
  }

  public Export(exportType: ExportActionType, items: FEDSoleauEntity[]) : void {
    // Seul l'export liste est autorisé pour les ES
    if (exportType !== ExportActionType.List)
        throw new Error("Invalid export type");

    // Appel du service générique d'export
    this.exportService.Export(
      // API
      "API-SoleauExport-POST",
      // Références des dossiers à exporter
      items.map(i => i.RefGM),
      // Colonnes
      [ 
        { Code: "RefGM", Text: "common.shared.refGM" },
        { Code: "RefClient", Text: "common.shared.refClient" },
        { Code: "Title", Text: "soleau.props.name" },
        { Code: "Owner", Text: "common.shared.owner" },
        { Code :"DepositDate", Text: "common.shared.depDate"},
        { Code :"DepositNum", Text: "common.shared.depNum"},
        { Code: "RenewDueDates", Text: "common.shared.renewDates" },
        { Code: "OtherDueDates", Text: "common.shared.otherDueDates" },
        { Code: "Situation", Text: "common.shared.status" }
      ],
      // Textes
      [
        { Code: "Title", Text: "soleau.name" }
      ],
      // Onglets
      []
    );
  }
}