<form (ngSubmit)="onSubmit()">
<mat-dialog-content class="change-password-dialog-flex-container">

      <div class="topHeader epw-label bigger">{{ translateService.getLabel("auth.changePassword") }}</div>

      <mat-card appearance="outlined" class="info-message">
        <mat-card-content>{{ translateService.getLabel("auth.passwordConstraints.part1") }}</mat-card-content>
        <mat-card-content>{{ translateService.getLabel("auth.passwordConstraints.part2") }}</mat-card-content>
      </mat-card>

        <input type="password" class="password-input" name="password" [(ngModel)]="password1" (change)="validatePassword()" required>

        <input type="password" class="password-input" [class.invalid-password]="!validatePassword()" name="password" [(ngModel)]="password2" (change)="validatePassword()" required>

        <button class="epw-blue-button password-save-button"GMweb170 [mat-dialog-close]="true" [disabled]="!validatePassword()" type="submit">{{ translateService.getLabel('auth.resetPassword') }}
          <span class="glyphicon glyphicon-lock"></span></button>
</mat-dialog-content>
</form>