import { Component } from '@angular/core';
import { WatchingsTabContext } from '@core/models/tab-contexts/watchings-tab-context';
import { ParametersService } from '@core/services/parameters.service';
import { WatchingsService } from '@core/services/watchings.service';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'watchings-tab',
  templateUrl: './watchings-tab.component.html',
  styleUrls: ['./watchings-tab.component.scss']
})
export class WatchingsTabComponent {
  tabContext: WatchingsTabContext = new WatchingsTabContext(this.translateService, this.parametersService, this.watchingsService);

  constructor(public translateService: EpisoftTranslateService, private parametersService: ParametersService, private watchingsService: WatchingsService) { 
    this.translateService.onChangeCallback = () => { this.initOptionAddedSubscription(); };
    this.tabContext.sort.selectedOptionCode = "Name";
  };

  ngOnInit() {
    this.initOptionAddedSubscription();
    this.tabContext.loadTypeAhead();
  }

  public async initOptionAddedSubscription() {  
    this.tabContext.sort.options = [
      { Code: "Name", Label: await this.translateService.getAsyncTrad('watching.props.name').toPromise() || '' },     
      { Code: "Options", Label: await this.translateService.getAsyncTrad('watching.props.options').toPromise() || '' },    
      { Code: "Type", Label: await this.translateService.getAsyncTrad('watching.props.type').toPromise() || '' },    
    ];
  };
}
