import { TabContext } from "./tab-context";
import { StringHelper } from "@core/helpers/string-helper";
import { DateHelper } from "@core/helpers/date-helper";
import { TrademarksService } from "@core/services/trademarks.service";
import { EpisoftTranslateService } from '@core/services/translate.service';
import { ParametersService } from "@core/services/parameters.service";
import { Observable } from "rxjs";
import { FEDTrademarkEntity } from "../entities/front-end-displayable-entities/fed-trademark-entity";
import { FEDBaseEntity } from "../entities/front-end-displayable-entities/fed-base-entity";
import { GenericCriteria } from "../criterias/generic-criteria";
import { TrademarkCriteria } from "../criterias/trademark-criteria";
import { ExportActionType } from "../export-action";

export class TrademarksTabContext extends TabContext {

    constructor(override translateService: EpisoftTranslateService, override parametersService : ParametersService, private trademarksService: TrademarksService) {
        super(translateService, parametersService);
    }

    static readonly moduleId = "trademarks";

    override getModuleId(): string {
        return TrademarksTabContext.moduleId;
    }

    protected override customLoadData(searchCriterias : GenericCriteria | undefined): Observable<any> {
        let customSearchCriterias = searchCriterias as TrademarkCriteria;
        return this.trademarksService.getLabelemarks(customSearchCriterias);
    }

    override loadParameters() : void {
        this.trademarksService.GetParams().subscribe(r => {
            this.parameters.owners = r.Owners;
            this.parameters.types = r.TypeTitles;
            this.loadCurrentLanguageEntityParameters();
            this.sortEntityParameters(); 
        }); 
        this.parametersService.Get().subscribe(r => {
            this.parameters.countries = r.Countries;
            this.loadCurrentLanguageGlobalParameters();
            this.sortGlobalParameters();
        });
    }

    public loadTypeAhead(){
        this.trademarksService.getTypeAhead().subscribe(response => {
            this.typeAhead = response;
            this.typeAhead.sort()
        });
    }

    override sortData() {
        let rawData: FEDTrademarkEntity[] = this.data;
        rawData.sort((a, b) => {
            // Fonction de comparaison en fonction du sortCode
            switch (this.sort.selectedOptionCode) {
                case 'CurrRegDate':
                    return DateHelper.compareDates(a.Root.CurrRegDate, b.Root.CurrRegDate)
                case 'DepDate':
                    return DateHelper.compareDates(a.Root.OriDepDate, b.Root.OriDepDate)
                case 'Name':
                    return StringHelper.compareStrings(a.Root.Name, b.Root.Name);
                case 'RenewDueDates':
                    return DateHelper.compareDates( (a.Root.RenewDueDates.length > 0 ? new Date(a.Root.RenewDueDates[0].Date) : new Date(1970, 1, 1)), 
                    (b.Root.RenewDueDates.length > 0 ? new Date(b.Root.RenewDueDates[0].Date) : new Date(1970, 1, 1)));
                case 'DepositCountryLabel':
                    return StringHelper.compareStrings(a.Root.DepositCountry, b.Root.DepositCountry);
                case 'SituationLabel':
                    return StringHelper.compareStrings(a.SituationLabel, b.SituationLabel);
                case 'Owner':
                    return StringHelper.compareStrings(a.Root.Owners[0].Name, b.Root.Owners[0].Name);
                case 'TypeLabel':
                    return StringHelper.compareStrings(a.Root.Type, b.Root.Type);
                default:
                    return 0; // Aucun tri par défaut
            }
        });

        if (!this.sort.useSortOrderASC) {
            rawData.reverse();
        }

        this.data = rawData;
    }
    
    // Rédefinition de la méthode de sélection d'un élément afin de gérer les fractions
    override selectItem(item: FEDBaseEntity, select: boolean): void {
        let t : FEDTrademarkEntity = item as FEDTrademarkEntity;
        // Redondance informations de sélection pour les marques (information dans Root et plus haut niveau) 
        t.IsSelected = select;
        t.Root.IsSelected = select;
        // La (dé)sélection d'une marque inter entraîne celle de ses fractions
        t.Fractions.forEach(element => {
            element.IsSelected = select;
        });
    }

    public override resetSearchCriterias(): void {
        if (this.searchCriterias === null) this.searchCriterias = new TrademarkCriteria();
        this.searchCriterias?.resetToDefault();
    }

    public override export(exportType: ExportActionType): void {
        let selectedItems : FEDBaseEntity[] = [];
        this.data.forEach((trademark: FEDTrademarkEntity) => {
            let selectedFractions : FEDBaseEntity[] = trademark.Fractions.filter(d => d.IsSelected);
            // Ajout de la marque courante - on ajoute aussi les marques inter si au moins  une de leur fraction est sélectionnée
            if(trademark.IsSelected || trademark.Root.IsSelected || selectedFractions.length > 0) 
                selectedItems.push(trademark.Root);
            selectedFractions.forEach((fraction: FEDBaseEntity) => {
                selectedItems.push(fraction);
            })
        });
        this.trademarksService.Export(exportType, selectedItems);
    }

    public override getAvailableExportTypes(): ExportActionType[] {
        return [ExportActionType.List, ExportActionType.Map];
      }
}