import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'image-with-popover',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './image-with-popover.component.html',
  styleUrls: ['./image-with-popover.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageWithPopoverComponent {
  @Input({ required: true }) imageUrl!: string;

  isPopoverVisible = false;

  showPopover() {
    this.isPopoverVisible = true;
  }

  hidePopover() {
    this.isPopoverVisible = false;
  }

  constructor() {
  }

}
