import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { EntityRef } from '@core/models/entity-ref';
import { Owner } from '@core/models/owner';
import { HydratationHelper } from '@core/helpers/hydratation-helper';
import { EpisoftTranslateService } from './translate.service';
import { DomainNameCriteria } from '@core/models/criterias/domain-name-criteria';
import { BERDomainNameEntity } from '@core/models/entities/back-end-raw-entities/ber-domain-name-entity';
import { FEDDomainNameEntity } from '@core/models/entities/front-end-displayable-entities/fed-domain-name-entity';
import { AuthService } from './auth.service';
import { ExportService } from './export.service';
import { ExportActionType } from '@core/models/export-action';


export interface DomainNameData {
  Refs: EntityRef[];
  Entities: BERDomainNameEntity[];
}

export interface DomainNameParamsData {
  TypeDueDate: EntityRef[];
  Owners: Owner[];
}

@Injectable({
  providedIn: 'root'
})
export class DomainNamesService {

  constructor(private httpApiService: HttpApiService, private translateService: EpisoftTranslateService, private authService: AuthService, private exportService : ExportService) { }

  public GetDomainNames(searchCriterias: DomainNameCriteria | null = null): Observable<FEDDomainNameEntity[]> {
    return this.httpApiService.post<DomainNameData>("API-DomainNames-POST", searchCriterias).pipe(
      map((data: DomainNameData) => { 
      // Hydratation des données
      let entities : FEDDomainNameEntity[] = (data.Entities ?? []).map(e => new FEDDomainNameEntity(e));
      HydratationHelper.HydrateDomainNames(entities, data.Refs, this.translateService.getCurrentLanguage());
      return entities;
    }));
  }

  public GetParams(): Observable<DomainNameParamsData> {
    let data: Observable<DomainNameParamsData> = this.httpApiService.get<DomainNameParamsData>("API-DomainNamesParams-GET");
    return data.pipe(map((data: DomainNameParamsData) => { 
      return data;
    }));
  }

  public Export(exportType: ExportActionType, items: FEDDomainNameEntity[]) : void {
    // Seul l'export liste est autorisé pour les DN
    if (exportType !== ExportActionType.List)
        throw new Error("Invalid export type");

    // Appel du service générique d'export
    this.exportService.Export(
      // API
      "API-DomainNamesExport-POST",
      // Références des dossiers à exporter
      items.map(i => i.RefGM),
      // Colonnes
      [ 
        { Code: "RefGM", Text: "common.shared.refGM" },
        { Code: "DNS", Text: "domain.props.name" },
        { Code: "Owner", Text: "common.shared.owner" },
        { Code: "BookingDate", Text: "domain.props.bookingDate" },
        { Code: "RenewDueDates", Text: "common.shared.renewDates" },
        { Code: "OtherDueDates", Text: "common.shared.otherDueDates" },
        { Code: "Situation", Text: "common.shared.status" }
      ],
      // Textes
      [
        { Code: "Title", Text: "domain.name" }
      ],
      // Onglets
      []
    );
  }
}
