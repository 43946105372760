<mat-expansion-panel class="search-panel mat-elevation-z0" expanded="true">
    <mat-expansion-panel-header>
        <div class="panel-header">
            <label class="epw-label bold title">{{ translateService.getLabel('common.groups.general') }}</label>
        </div>
    </mat-expansion-panel-header>
    <div>
        <search-panel-input-combo-box  
            headerText="{{ translateService.getLabel('other.props.type') }}" 
            [items]="this.tabContext.parameters.types"
            [labelSelector]="typeLabelSelector"
            [valueSelector]="typeValueSelector"
            [activeSelector]="typeIsActiveSelector"
            (criteriaValueChanged)="this.updateType($event)" 
            />
        <search-panel-input-text 
            headerText="{{ translateService.getLabel('other.props.name') }}" 
            [showTextFilter]="true" 
            [criteria]="this.searchCriterias.Title"
            />
        <search-panel-input-combo-box   
            headerText="{{ translateService.getLabel('common.shared.country') }}" 
            [items]="this.tabContext.parameters.countries"
            [labelSelector]="countryLabelSelector"
            [valueSelector]="countryValueSelector"
            [activeSelector]="countryIsActiveSelector"
            (criteriaValueChanged)="this.updateCountry($event)"  
            />
        <search-panel-input-combo-box   
            headerText="{{ translateService.getLabel('common.shared.status') }}" 
            [items]="this.tabContext.parameters.status"
            [labelSelector]="statusLabelSelector"
            [valueSelector]="statusValueSelector"
            [activeSelector]="statusIsActiveSelector"
            (criteriaValueChanged)="this.updateStatus($event)"  
            />
        <search-panel-input-text 
            headerText="{{ translateService.getLabel('common.shared.refGM') }}" 
            [showTextFilter]="false" 
            [criteria]="this.searchCriterias.RefGM"
            />    
        <search-panel-input-check-box
            headerText="{{ translateService.getLabel('common.shared.closed') }}"
            checkBoxLabel="{{ translateService.getLabel('common.shared.closedHelp') }}"
            (criteriaValueChanged)="this.updateIncludeInactive($event)"
        />
    </div>
</mat-expansion-panel>
