import { TabContext } from "./tab-context";
import { PatentsService } from "@core/services/patents.service";
import { EpisoftTranslateService } from '@core/services/translate.service';
import { ParametersService } from "@core/services/parameters.service";
import { Observable } from "rxjs";
import { DateHelper } from "@core/helpers/date-helper";
import { FEDPatentFamilyEntity } from "../entities/front-end-displayable-entities/fed-patent-family-entity";
import { GenericCriteria } from "../criterias/generic-criteria";
import { PatentCriteria } from "../criterias/patent-criteria";
import { ExportActionType } from "../export-action";

export class PatentsTabContext extends TabContext {

    constructor(override translateService: EpisoftTranslateService, override parametersService : ParametersService, private patentsService: PatentsService) {
        super(translateService, parametersService);
    }

    static readonly moduleId = "patents";

    override getModuleId(): string {
        return PatentsTabContext.moduleId;
    }

    protected override customLoadData(searchCriterias : GenericCriteria | undefined): Observable<any> {
        let customSearchCriterias = searchCriterias as PatentCriteria;
        return this.patentsService.GetPatents(customSearchCriterias);
    }

    override loadParameters() : void {
        this.patentsService.GetParams().subscribe(r => {
            this.parameters.owners = r.Owners;
            this.parameters.inventors = r.Inventors;
            this.loadCurrentLanguageEntityParameters();
            this.sortEntityParameters();  
        });
        this.parametersService.Get().subscribe(r => {
            this.parameters.countries = r.Countries;
            this.loadCurrentLanguageGlobalParameters();
            this.sortGlobalParameters();
        }); 
    }

    override sortData() {
        let rawData: FEDPatentFamilyEntity[] = this.data;
        rawData.sort((a, b) => {
            const MAX_DATE : Date = new Date(2100, 1, 1);
            // Fonction de comparaison en fonction du sortCode
            switch (this.sort.selectedOptionCode) {
                case 'DatePri':
                    return DateHelper.compareDates(a.DatePri ?? MAX_DATE, b.DatePri ?? MAX_DATE)
                case 'DepDate':
                    // Le tri se fait sur la base de la date de dépôt la plus ancienne parmi les membres de la famille
                    function getOldestDepDate(element: FEDPatentFamilyEntity): Date {
                        const validDates = element.Patents.map(e => e.DepDate).filter(date => date !== null) as Date[];  
                        if (validDates.length === 0) return MAX_DATE;
                        return validDates.reduce((oldest, current) => current < oldest ? current : oldest);
                    }
                    return DateHelper.compareDates(getOldestDepDate(a), getOldestDepDate(b));
                case 'PubDate':
                    // Le tri se fait sur la base de la date de publication la plus ancienne parmi les membres de la famille
                    function getOldestPubDate(element: FEDPatentFamilyEntity): Date {
                        const validDates = element.Patents.map(e => e.PubDate).filter(date => date !== null) as Date[];  
                        if (validDates.length === 0) return MAX_DATE;
                        return validDates.reduce((oldest, current) => current < oldest ? current : oldest);
                    }
                    return DateHelper.compareDates(getOldestPubDate(a), getOldestPubDate(b));
                default:
                    return 0; // Aucun tri par défaut
            }
        });

        if (!this.sort.useSortOrderASC) {
            rawData.reverse();
        }

        this.data = rawData;
    }

    public override resetSearchCriterias(): void {
        if (this.searchCriterias === null) this.searchCriterias = new PatentCriteria();
        this.searchCriterias?.resetToDefault();
    }
    
    public override export(exportType: ExportActionType): void {
        this.patentsService.Export(exportType, this.data.filter(d => d.IsSelected));
    }

    public override getAvailableExportTypes(): ExportActionType[] {
        return [ExportActionType.List, ExportActionType.Family];
      }
}