<div *ngIf="this.drawingModelDetails !== undefined" class="detail">
    <button class="button-open-documents" (click)="openDocuments()" [title]="this.translateService.getLabel('common.documents')">
        <span> {{ translateService.getLabel('common.documents') }}&nbsp;&nbsp; </span>       
        <span class="glyphicon glyphicon-duplicate"></span>
    </button>
    <!-- Panneau principal -->
    <div class="main-header">
        <!-- 1ère ligne -->
        <div class="cell-title">{{ translateService.getLabel('model.props.name') }}</div>
        <div class="text-success">{{ drawingModelDetails.Root.TitleLabel }}</div>
        <div class="image">
            <image-with-popover [imageUrl]="this.getImageUrl(drawingModelDetails)"/>
            <span>{{this.getModelLabel(drawingModelDetails)}}</span>
        </div>
        <!-- 2ème ligne -->
        <div class="cell-title">{{ translateService.getLabel('common.shared.countryDep') }}</div>
        <div class="main-header-cell-value">{{ drawingModelDetails.Root.DepositCountry.Label }}</div>
        <div class="cell-title">{{ translateService.getLabel('common.shared.depKind') }}</div>
        <div class="main-header-cell-value">{{ drawingModelDetails.Root.DepositKindLabel }}</div>
        <!-- 3ème ligne -->
        <div class="cell-title">{{ translateService.getLabel('common.shared.type') }}</div>
        <div class="main-header-cell-value">{{ drawingModelDetails.Root.TypeLabel }}</div>
        <div class="cell-title">{{ translateService.getLabel('common.shared.status') }}</div>   
        <div class="main-header-cell-value">{{ drawingModelDetails.Root.Situation.Label }}</div>

        <!-- 4ème ligne -->        
        <div class="cell-title">{{ translateService.getLabel('common.shared.refGM') }}</div>  
        <div class="main-header-cell-value">{{ drawingModelDetails.Root.RefGM }}</div>
        <div></div>
        <div></div>  
        <!-- 5ème ligne -->
        <div class="cell-title">{{ translateService.getLabel('model.props.nbModels') }}</div>  
        <div class="model-selector-container">
            <carousel-selector [entity]="drawingModelDetails" [imageUrl]="this.getImageUrl(drawingModelDetails)"/> 
        </div>
        <div class="images-table">
            <div *ngFor="let image of this.drawingModelDetails.Root.Images">
                <div class="head-thin-border">
                    <div class="number-column">{{image.Idx}}</div>
                    <div class="text-column">{{image.Desig}}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- Panneau Général -->
    <mat-expansion-panel class="details-panel mat-elevation-z0" expanded="true" hideToggle>
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-info-sign icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.general') }}</div>
        </mat-expansion-panel-header>
        <div class="head">
            <!-- Entête-->
            <div class="head-border">
                <div class="head-column"></div>
                <div class="date-column"><strong>{{ translateService.getLabel('common.shared.date') }}</strong></div>
                <div class="number-column"><strong>{{ translateService.getLabel('common.shared.numKind') }}</strong></div>
            </div>
            <!-- 1ère ligne -->
            <div class="head-thin-border">
                <div class="head-column" *ngIf="!this.isFraction">{{ translateService.getLabel('common.shared.depOri') }}</div>
                <div class="head-column" *ngIf="this.isFraction">{{ translateService.getLabel('common.shared.designation') }}</div>
                <div class="date-column" *ngIf="!this.isFraction">{{ drawingModelDetails.Root.OriDepDateLabel  }}</div>
                <div class="date-column" *ngIf="this.isFraction">{{ drawingModelDetails.Root.DesignationDateLabel  }}</div>
                <div class="number-column" *ngIf="!this.isFraction">{{ drawingModelDetails.Root.OriDepNum }}</div>
                <div class="number-column" *ngIf="this.isFraction">{{ drawingModelDetails.Root.Designation.Label  }}</div>
            </div>
            <!-- 2ème ligne -->
            <div class="head-thin-border">
                <div class="head-column" *ngIf="!this.isFraction">{{ translateService.getLabel('model.props.extend') }}</div>
                <div class="head-column" *ngIf="this.isFraction">{{ translateService.getLabel('trademark.props.currRegInter') }}</div>                
                <div class="date-column" *ngIf="!this.isFraction">{{ drawingModelDetails.Root.ExtendDateLabel }}</div>
                <div class="date-column" *ngIf="this.isFraction">{{ drawingModelDetails.currRegInterDateLabel }}</div>
                <div class="number-column" *ngIf="!this.isFraction">{{ drawingModelDetails.Root.ExtendNum }}</div>
                <div class="number-column" *ngIf="this.isFraction">{{ drawingModelDetails.currRegInterNum }}</div>
            </div>
            <!-- 3ème ligne -->
            <div class="head-thin-border">
                <div class="head-column">{{ translateService.getLabel('trademark.props.currReg') }}</div>
                <div class="date-column">{{ drawingModelDetails.Root.CurrRegDateLabel }}</div>
                <div class="number-column">{{ drawingModelDetails.Root.CurrRegNum }}</div>
            </div>
            <!-- 4ème ligne -->
            <div class="head-thin-border">
                <div class="head-column">{{ translateService.getLabel('common.shared.prio') }}</div>
                <div class="date-column">{{ drawingModelDetails.Root.PrioDateLabel }}</div>
                <div class="number-column">{{ drawingModelDetails.Root.PrioNum }}</div>
            </div>
            <!-- 5ème ligne -->
            <div class="head-thin-border" *ngIf="this.isFraction">
                <div class="head-column">{{ translateService.getLabel('trademark.props.protection') }}</div>
                <div class="date-column">{{ drawingModelDetails.Root.ProtectionDateLabel }}</div>
                <div class="number-column"></div>
            </div>            
        </div>
    </mat-expansion-panel>
    <!-- Panneau Titulaires -->
    <mat-expansion-panel class="details-panel mat-elevation-z0" expanded="true" hideToggle>
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-link icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.owners') }}</div>
        </mat-expansion-panel-header>
        <div class="persons">
            <div class="person" *ngFor="let owner of this.drawingModelDetails.Root.Owners">
                <address class="address">
                    <strong>{{owner.Name}}</strong>
                    <br />
                    <span class="small">
                        {{owner.Addr1}}{{owner.Addr2}}{{owner.Addr3}}<br />
                        {{owner.City}} {{owner.Postcode}}, {{owner.Country.Label}}
                    </span>
                </address>
            </div>
        </div>          
    </mat-expansion-panel>
    <!-- Panneau Echéances -->
    <mat-expansion-panel class="details-panel mat-elevation-z0" expanded="true" hideToggle *ngIf="this.drawingModelDetails.Root.DueDates.length > 0">
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-calendar icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.dueDates') }}</div>
        </mat-expansion-panel-header>
        <div class="dueDates-head-border">
            <div class="cell-title-dueDates">{{ translateService.getLabel('common.shared.pastOps') }}</div>
            <div class="cell-title-dueDates">{{ translateService.getLabel('common.shared.nextOps') }}</div>
            <div class="dueDates-head-border">
                <div class="cell-subtitle-dueDates">{{ translateService.getLabel('common.shared.type') }}</div>
                <div class="cell-subtitle-dueDates">{{ translateService.getLabel('common.shared.date') }}</div>
            </div>
            <div class="dueDates-head-border">
                <div class="cell-subtitle-dueDates">{{ translateService.getLabel('common.shared.type') }}</div>
                <div class="cell-subtitle-dueDates">{{ translateService.getLabel('common.shared.date') }}</div>
            </div>
        </div>
        <div class="dueDates">
            <div>
                <div *ngFor="let dueDate of this.pastOps">  
                    <div class="dueDatesValues">           
                        <div>{{ dueDate.Type.Label }}</div>
                        <div>{{ dueDate.DateLabel }}</div>              
                    </div>
                </div>
            </div>
            <div>
                <div *ngFor="let dueDate of this.nextOps">  
                    <div class="dueDatesValues">           
                        <div>{{ dueDate.Type.Label }}</div>
                        <div>{{ dueDate.DateLabel }}</div>
                    </div>
                </div>

            </div>  
        </div> 
    </mat-expansion-panel>
    <!-- Panneau Inscriptions -->
    <mat-expansion-panel class="details-panel mat-elevation-z0" hideToggle *ngIf="this.drawingModelDetails.Root.Records.length > 0">
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-th-list icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.registry') }}</div>
        </mat-expansion-panel-header>
          <div class="content-header">
            <strong class="cell-title-29">{{ translateService.getLabel('common.shared.kind') }}</strong>
            <strong class="cell-title-14">{{ translateService.getLabel('common.shared.date') }}</strong>
            <strong class="cell-title-14">{{ translateService.getLabel('common.shared.number') }}</strong>
            <strong class="cell-title-43">{{ translateService.getLabel('common.shared.comment') }}</strong>
        </div>
        <div>                                  
            <div *ngFor="let record of this.drawingModelDetails.Root.Records">
                <div class="records">
                    <div class="table-item">{{ record.Nature.Label }}</div>
                    <div class="table-item">{{ record.DateLabel }}</div>
                    <div class="table-item">{{ record.Num }}</div>
                    <div class="table-item">{{ record.TxtLabel }}</div>
                </div>
            </div>
        </div> 
    </mat-expansion-panel>
    <!-- Panneau Pays Désignés -->
    <mat-expansion-panel class="details-panel mat-elevation-z0" hideToggle *ngIf="this.drawingModelDetails.Root.DesignatedCountries.length > 0">
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-globe icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.countryDes') }}</div>
        </mat-expansion-panel-header>
         <!-- Entête-->
         <div class="designated-countries-content-header">
            <strong>{{ translateService.getLabel('common.shared.country') }}</strong>
            <strong>{{ translateService.getLabel('common.shared.status') }}</strong>
            <strong>{{ translateService.getLabel('common.shared.designation') }}</strong>
            <strong>{{ translateService.getLabel('common.shared.dateEffect') }}</strong>
        </div>
        <div>                                  
            <div *ngFor="let designatedCountry of this.drawingModelDetails.Root.DesignatedCountries">
                <div class="designated-countries-content-row">
                    <div> {{ getDesignatedCountryLabel(designatedCountry.Code) }}</div>  
                    <div>{{ designatedCountry.Situation !== null ? designatedCountry.Situation.Label : "" }}</div>
                    <div>{{ designatedCountry.Designation !== null ? (designatedCountry.Designation.Label + " " + translateService.getLabel('common.shared.designation_since') + " " + designatedCountry.DesignationDateLabel) : "" }}</div>
                    <div>{{ designatedCountry.EffectDateLabel}}</div>
                </div>
            </div>
        </div>      
    </mat-expansion-panel>    
    <!-- Panneau Facturation -->
    <mat-expansion-panel class="details-panel mat-elevation-z0" expanded="true" hideToggle *ngIf="this.drawingModelDetails.Root.Billings.length > 0">
        <mat-expansion-panel-header class="panel-header">
            <span class="glyphicon glyphicon-euro icone"></span>
            <div class="epw-label bold title">{{ translateService.getLabel('common.groups.invoice') }}</div>
        </mat-expansion-panel-header>
        <div *ngIf="this.canAccessFinancialData()">
            <div class="billings-header">
                <strong class="billings-header-cell-title">{{ translateService.getLabel('common.shared.date') }}</strong>
                <strong class="billings-header-cell-title">{{ translateService.getLabel('common.shared.type') }}</strong>
                <strong class="billings-header-cell-title">{{ translateService.getLabel('common.shared.number') }}</strong>
                <strong class="billings-header-amount">{{ translateService.getLabel('common.shared.invoice.ht') }}</strong>
                <strong class="billings-header-amount">{{ translateService.getLabel('common.shared.invoice.tva') }}</strong>
                <strong class="billings-header-amount">{{ translateService.getLabel('common.shared.invoice.ttc') }}</strong>
                <strong class="billings-header-cell-title">{{ translateService.getLabel('common.shared.invoice.to') }}</strong>
            </div>
            <div>                                  
                <div *ngFor="let billing of this.drawingModelDetails.Root.Billings">
                    <div class="billings">
                        <div class="table-item">{{ billing.DateLabel }}</div>
                        <div class="table-item">{{ billing.BillingType.Label }}</div>
                        <div class="table-item">{{ billing.Num }}</div>
                        <div class="billings-item-amount">{{ billing.HTLabel }}</div>
                        <div class="billings-item-amount">{{ billing.TVALabel }}</div>
                        <div class="billings-item-amount">{{ billing.AmountLabel }}</div>
                        <div class="table-item">{{ billing.InvoicedTo }}</div>
                    </div>
                </div>     
                <div class="billings">
                    <div class="billings-spacer"></div>
                    <strong class="billings-item-amount">{{ drawingModelDetails.Root.TotalHT }}</strong>
                    <strong class="billings-item-amount">{{ drawingModelDetails.Root.TotalTVA }}</strong>
                    <strong class="billings-item-amount">{{ drawingModelDetails.Root.TotalAmount }}</strong>
                </div>                    
            </div>
        </div>
    </mat-expansion-panel>  
</div>    