import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { EntityRef } from '@core/models/entity-ref';
import { Owner } from '@core/models/owner';
import { HydratationHelper } from '@core/helpers/hydratation-helper';
import { EpisoftTranslateService } from './translate.service';
import { ParametersService } from './parameters.service';
import { OffTitleCriteria } from '@core/models/criterias/off-title-criteria';
import { FEDOffTitleEntity } from '@core/models/entities/front-end-displayable-entities/fed-off-title-entity';
import { BEROffTitleEntity } from '@core/models/entities/back-end-raw-entities/ber-off-title-entity';
import { ExportActionType } from '@core/models/export-action';
import { ExportService } from './export.service';

export interface OffTitleData {
  Refs: EntityRef[];
  Entities: BEROffTitleEntity[];
}

export interface OffTitleParamsData {
  HTTypes: EntityRef[];
  Owners: Owner[];
  typeDueDates: EntityRef[];
  Situations: EntityRef[];
}

@Injectable({
  providedIn: 'root'
})
export class OffTitlesService {

  constructor(private httpApiService: HttpApiService, private translateService: EpisoftTranslateService, private parametersService : ParametersService, private exportService: ExportService) { }

  public GetOffTitles(searchCriterias: OffTitleCriteria | null = null): Observable<FEDOffTitleEntity[]> {
    let specificData : OffTitleParamsData;
      // Chargement des paramètres globaux
    return this.parametersService.Get().pipe(
      // Chargement des paramètres spécifiques
      switchMap( _ => this.GetParams()),
      // Chargement des données
      switchMap( data => {
        specificData = data;
        return this.httpApiService.post<OffTitleData>("API-OffTitles-POST", searchCriterias)
      }),
      // Hydratation des données
      map((data: OffTitleData) => { 
          let entities : FEDOffTitleEntity[] = (data.Entities ?? []).map(e => new FEDOffTitleEntity(e));
          HydratationHelper.HydrateOffTitles(entities, data.Refs, specificData.HTTypes, this.parametersService, this.translateService.getCurrentLanguage());
          return entities;
      })
    );
  }

  public GetParams(): Observable<OffTitleParamsData> {
    let data: Observable<OffTitleParamsData> = this.httpApiService.get<OffTitleParamsData>("API-OffTitlesParams-GET");
    return data.pipe(map((data: OffTitleParamsData) => { 
      return data;
    }));
  }

  public Export(exportType: ExportActionType, items: FEDOffTitleEntity[]) : void {
    // Seul l'export liste est autorisé pour les DO
    if (exportType !== ExportActionType.List)
        throw new Error("Invalid export type");

    // Appel du service générique d'export
    this.exportService.Export(
      // API
      "API-OffTitlesExport-POST",
      // Références des dossiers à exporter
      items.map(i => i.RefGM),
      // Colonnes
      [ 
        { Code: "RefGM", Text: "common.shared.refGM" },
        { Code: "HTType", Text: "other.props.type" },
        { Code: "Title", Text: "other.props.name" },
        { Code: "Country", Text: "common.shared.country" },
        { Code: "Situation", Text: "common.shared.status" },
        { Code: "OpenDate", Text: "other.props.openDate" },
        { Code: "CloseDate", Text: "other.props.closeDate" }
      ],
      // Textes
      [
        { Code: "Title", Text: "other.name" }
      ],
      // Onglets
      []
    );
  }

}