import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { LogService } from './log.service';

export interface CountryInformation {
  Code: string;
  FR: string;
  EN: string;
}
export interface ParametersData {
  Countries: CountryInformation[];
}

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  private data?: ParametersData = undefined;

  constructor(private httpApiService: HttpApiService, private logService: LogService) { }

  public Get() : Observable<ParametersData> {
    return this.httpApiService.get<ParametersData>("API-Params-GET").pipe(map(
      (data: ParametersData) => { 
        this.data = data;
        return data;
      }
    ));
  }

  public GetCountryLabelFromCode(code: string, languageCode: string) : string {
    // Recherche du libellé pays à partir de son code
    //this.logService.log(`Chargement du libellé du pays [${code}]`)
    const countryData =  this.data?.Countries.find(x => x.Code === code);
    if(countryData != undefined) {
      switch(languageCode) {
        case "fr" : return countryData.FR;
        case "en" : return countryData.EN;
        default : 
          this.logService.log("Code langue inconnu : ", languageCode);
      }
    }
    // Si on ne trouve rien, on renvoie le code
    this.logService.log("Pays inconnu : ", code);
    //this.logService.log(this.data?.Countries);
    return code;
  }
}
