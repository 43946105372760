// Imports système
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// Imports Material
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule} from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
// Imports EpisoftWeb internes
import { HomeTabComponent } from './home-tab/home-tab.component';
import { AdminTabComponent } from  './admin-tab/admin-tab.component';
import { PatentsTabComponent } from './patents-tab/patents-tab.component';
import { TrademarksTabComponent } from './trademarks-tab/trademarks-tab.component';
import { DrawingsModelsTabComponent } from './drawings-models-tab/drawings-models-tab.component';
import { DomainNamesTabComponent } from './domain-names-tab/domain-names-tab.component';
import { SoleauTabComponent } from './soleau-tab/soleau-tab.component';
import { OffTitlesTabComponent } from './off-titles-tab/off-title-tab.component';
import { WatchingsTabComponent } from './watchings-tab/watchings-tab.component';
// Imports EpisoftWeb externes
import { SharedModule } from 'src/app/shared/shared.module';

import { EpisoftTranslateService } from '@core/services/translate.service';

@NgModule({
  declarations: [
    AdminTabComponent,
    HomeTabComponent,
    PatentsTabComponent,
    TrademarksTabComponent,
    DrawingsModelsTabComponent,
    DomainNamesTabComponent,
    SoleauTabComponent,
    OffTitlesTabComponent,
    WatchingsTabComponent,
  ],
  exports : [
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    MatInputModule,
    MatTooltipModule,
    MatButtonModule,
    FormsModule,
    SharedModule,
  ],
  providers: [
    EpisoftTranslateService
  ]
})
export class TabsModule { }
