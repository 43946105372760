import { Component, Input } from '@angular/core';
import { SearchString, SearchStringComparison } from '@core/models/criterias/search-string';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'search-panel-input-text',
  templateUrl: './search-panel-input-text.component.html',
  styleUrls: ['./search-panel-input-text.component.scss']
})
export class SearchPanelInputTextComponent {
    @Input({ required: true }) headerText : string = '';
    @Input() showTextFilter  : boolean = false;
    @Input() criteria: SearchString = new SearchString();

    constructor(public translateService: EpisoftTranslateService) {
    }

    getComparisonOptions : Function = () => SearchString.ComparisonOptions;

    getGlyphiconClass : Function = (comp: SearchStringComparison) => SearchString.GetGlyphiconClass(comp);  

    updateCriteria(comp: SearchStringComparison): void {
      this.criteria.Comp = comp;
    }
}
