import { Component, Input } from '@angular/core';
import { SearchString, SearchStringComparison } from '@core/models/criterias/search-string';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'search-panel-input-dual-refs',
  templateUrl: './search-panel-input-dual-refs.component.html',
  styleUrls: ['./search-panel-input-dual-refs.component.scss']
})
export class SearchPanelInputDualRefsComponent {
  @Input({ required: true }) headerTextRefGM : string = '';
  @Input({ required: true }) headerTextRefClient : string = '';
  @Input() criteriaRefGM: SearchString = new SearchString();
  @Input() criteriaRefClient: SearchString = new SearchString();

  constructor(public translateService: EpisoftTranslateService) {
  }
  
  getComparisonOptions : Function = () => SearchString.ComparisonOptions;

  getGlyphiconClass : Function = (comp: SearchStringComparison) => SearchString.GetGlyphiconClass(comp);  

  updateCriteria(comp: SearchStringComparison): void {
    this.criteriaRefClient.Comp = comp;
  }
}
