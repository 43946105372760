import { BERTrademarkRootEntity } from "../back-end-raw-entities/ber-trademark-root-entity";
import { FEDBaseEntity } from "./fed-base-entity";
import { FEDTrademarkFractionEntity } from "./fed-trademark-fraction-entity"; // Nécessaire, à conserver

export class FEDTrademarkRootEntity extends FEDBaseEntity implements BERTrademarkRootEntity {
    constructor(source : BERTrademarkRootEntity) {
        super(source);
        Object.assign(this, source);
    }
    // Données de BERTrademarkRootEntity
    RenewDueDates!: { Type: string; Date: Date; }[];
    DepositCountry!: string;
    DesignatedCountries!: { Code: string; SubItems: []; Active: boolean; }[];
    Name!: string;
    Classes!: { Num: string; }[];
    Owners!: { Name: string; }[];
    OriDepDate!: Date;
    CurrRegNum!: string;
    CurrRegDate!: Date;
    DesignationDate!: Date | null;
    Designation!: { $ref: string; Code: string; FR: string; EN: string; } | null;
    IsCollectiveMark!: string;

    // Données connues uniquement côté Front End
    RenewDueDatesLabels!: string;
    DepositCountryLabel!: string;
    DesignatedCountriesLabel!: string;
    ClassesLabel!: string;
    DesignationDateLabel!: string;
    DesignationLabel!: string;
    OwnersLabel!: string;
    OriDepDateLabel!: string;
    CurrRegDateLabel!: string;
}
