import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FEDDrawingModelEntity } from '@core/models/entities/front-end-displayable-entities/fed-drawing-model-entity';
import { DrawingModelsService } from '@core/services/drawing-models.service';

@Component({
  selector: 'image-carousel',
  template: `
    <div class="modal-title"><h3>{{viewLabel}} </h3></div>
    <div class="modal-value">
      <div class="modal-direction"><span class="glyphicon glyphicon-chevron-left" (click)="this.getPreviousDesignation(data.entity.Root.RefGM, data.entity.Root.Images)"></span></div>
      <img [src]="image" alt="Image" />
      <div class="modal-direction"><span class="glyphicon glyphicon-chevron-right" (click)="this.getNextDesignation(data.entity.Root.RefGM, data.entity.Root.Images)"></span></div>
    </div>
  `,
  styleUrls: ['./image-carousel.component.css'],
})

export class ImageCarouselComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { imageUrl: string, entity: FEDDrawingModelEntity }, private drawingModelsService: DrawingModelsService) {}
  currentIdx: number = 1
  viewLabel: string = this.currentIdx + " - " + this.data.entity.Root.Images[this.currentIdx - 1].Desig
  image: any = this.data.imageUrl;
  
  public getCurrentDesignation():string {
     return this.viewLabel = this.currentIdx + " - " + this.data.entity.Root.Images[this.currentIdx - 1].Desig
  }

  public getPreviousDesignation(refGm: string, images: Object[]) {
    this.currentIdx = this.currentIdx - 1
    
    if(this.currentIdx < 1)
    {
      this.currentIdx = images.length
    }
    this.viewLabel = this.getCurrentDesignation();
    this.image = this.drawingModelsService.GetImageUrl(refGm, this.currentIdx)
  }

  public getNextDesignation(refGm: string, images: Object[]) {
    this.currentIdx = this.currentIdx + 1

    if(this.currentIdx > images.length)
    {
      this.currentIdx = 1
    }
    this.viewLabel = this.getCurrentDesignation();
    this.image = this.drawingModelsService.GetImageUrl(refGm, this.currentIdx)
  }
}
