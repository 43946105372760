import { BERPatentMemberEntity } from "../back-end-raw-entities/ber-patent-member-entity";
import { FEDBaseEntity } from "./fed-base-entity";

export class FEDPatentMemberEntity extends FEDBaseEntity implements BERPatentMemberEntity {
    constructor(source : BERPatentMemberEntity) {
        super(source);
        Object.assign(this, source);
    }
    // Données de BERPatentFamilyEntity
    DepositCountry!: string;
    DepNum!: string;
    DepDate!: Date;
    PubNum!: string;
    PubDate!: Date;

    // Données connues uniquement côté Front End
    DepDateLabel!: string;
    PubDateLabel!: string;
}
