
import { BERDrawingModelFractionEntity } from "../back-end-raw-entities/ber-drawing-model-fraction-entity";
import { FEDBaseEntity } from "./fed-base-entity";

export class FEDDrawingModelFractionEntity extends FEDBaseEntity implements BERDrawingModelFractionEntity {
    constructor(source : BERDrawingModelFractionEntity) {
        super(source);
        Object.assign(this, source);
    }
    // Données de BERDrawingModelFractionEntity
    DepositCountry!: string;
    DesignatedCountries!: { Code: string; SubItems: []; Active: boolean; }[];
    DesignationDate!: Date | null;
    Designation!: { Code: string; FR: string; EN: string; } | null;
    // Données connues uniquement côté Front End
    DesignatedCountriesLabel!: string;
    DepositCountryLabel!: string;
    DesignationLabel: string ='';
    DesignationDateLabel!: string;
}
