import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({ 
  selector: 'custom-tooltip',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTooltipComponent { 
  @Input({ required: true }) title!: string;
  @Input({ required: true }) content!: string;
  visible: boolean = false;

  ngOnInit(): void {}
}