import { Component, Input } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { GenericDetailsCardComponent } from '../generic-details-card/generic-details-card.component';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { ParametersService } from '@core/services/parameters.service';
import { TabContentPanelComponent } from '@shared/tab-content-panel/tab-content-panel.component';
import { WatchingsService } from '@core/services/watchings.service';
import { FEDWatchingEntity } from '@core/models/entities/front-end-displayable-entities/fed-watching-entity';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'watching-details-card',
  templateUrl: './watching-details-card.component.html',
  styleUrls: ['./watching-details-card.component.scss']
})
export class WatchingDetailsCardComponent extends GenericDetailsCardComponent {
  @Input({ required: true }) details!: FEDWatchingEntity;
  @Input({ required: true }) parent!: TabContentPanelComponent;

  constructor(public translateService: EpisoftTranslateService, authService: AuthService, private parametersService: ParametersService, public watchingsService: WatchingsService) {
    super(authService);
  }

  public selectItem() {
    // On transmet l'info au parent pour mettre à jour la check box de sélection globale
    // On passe par un "input" plutôt que par un évènement classique car le composant est généré dynamiquement et output n'est pas encore géré par ngComponentOutlet
      this.parent.tabContext.selectItem(this.details, !this.details.IsSelected)
      this.parent.itemSelectionStateChangedCallback(this.details.IsSelected);
  }

  public getCurrentSubscriptionInformations(e: FEDWatchingEntity): string {
    let label: string = "";
    let label2: string = "";

    this.translateService.acquire('common.dateFrom').subscribe((res: string) => {label += res;});  
    this.translateService.acquire('common.dateFromTo').subscribe((res: string) => {label2 = res;})
    label += " " + e.CurrentSubscriptionLabel.replace(" ", " " + label2 + " ")

    return label
  }

  public getImageUrl(e: FEDWatchingEntity): string {
    if(e.Type === "VB") return "";
    return this.watchingsService.GetImageUrl(e.RefGM);
  }

  // Connexion ZenWeb
  public openDocuments() {
    let url: string = `${environment.zenWebUrl}/content/B/${this.details.CodeZen}?token=${this.authService.getToken()}`;
    window.open(url);
  };

}