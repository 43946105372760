<mat-expansion-panel class="search-panel mat-elevation-z0" expanded="true">
    <mat-expansion-panel-header>
        <div class="panel-header">
            <label class="epw-label bold title">{{ translateService.getLabel('common.groups.general') }}</label>
        </div>
    </mat-expansion-panel-header>
    <div class="panel-content">
        <search-panel-input-text-autocomplete
            headerText="{{ translateService.getLabel('trademark.props.name') }}" 
            [showTextFilter]="true"
            [criteria]="this.searchCriterias.Name"
            [typeAhead]="this.tabContext.typeAhead" 
        />
        <search-panel-input-check-box
            checkBoxLabel="{{ translateService.getLabel('common.exactMatch') }}"
            (criteriaValueChanged)="this.updateExactMatch($event)"
        />
        <search-panel-input-combo-box   
            headerText="{{ translateService.getLabel('trademark.search.type') }}" 
            [items]="this.tabContext.parameters.types"
            [labelSelector]="typeLabelSelector"
            [valueSelector]="typeValueSelector"
            [activeSelector]="typeIsActiveSelector"
            (criteriaValueChanged)="this.updateType($event)" 
        />
        <search-panel-input-combo-box 
            headerText="{{ translateService.getLabel('common.shared.owner') }}"
            [items]="this.tabContext.parameters.owners"
            [labelSelector]="ownerLabelSelector"
            [valueSelector]="ownerValueSelector"
            [activeSelector]="ownerIsActiveSelector"
            (criteriaValueChanged)="this.updateOwner($event)"
        />
        <search-panel-input-combo-box   
            headerText="{{ translateService.getLabel('common.shared.country') }}" 
            [items]="this.tabContext.parameters.countries"
            [labelSelector]="countryLabelSelector"
            [valueSelector]="countryValueSelector"
            [activeSelector]="countryIsActiveSelector"
            (criteriaValueChanged)="this.updateCountry($event)" 
        /> 
        <search-panel-input-classes-selector
            headerText="{{ translateService.getLabel('trademark.props.classes') }}"
            (criteriaValueChanged)="this.updateClasses($event)"
        />        
        <search-panel-input-dual-refs 
            headerTextRefGM="{{ translateService.getLabel('common.shared.refGM') }}"
            headerTextRefClient="{{ translateService.getLabel('common.shared.refClient') }}"
            [criteriaRefGM]="this.searchCriterias.RefGM"
            [criteriaRefClient]="this.searchCriterias.Client"
        />
    </div>
</mat-expansion-panel>
<mat-expansion-panel class="search-panel mat-elevation-z0">
    <mat-expansion-panel-header>            
    <div class="panel-header">
        <label class="epw-label bold title">{{ translateService.getLabel('common.groups.advanced')  }}</label>
    </div>
    </mat-expansion-panel-header>
    <div class="panel-content">
        <div class="panel-row-container">
            <div class="split-50">
                <search-panel-input-date-range 
                    headerText="{{ translateService.getLabel('common.shared.regDate') }}" 
                    [criteria]="this.searchCriterias.RegDate"
                />
            </div>
            <div class="gm-margin-right-15px"></div>
            <div class="split-50">
                <search-panel-input-text 
                    headerText="{{ translateService.getLabel('common.shared.regNum') }}" 
                    [showTextFilter]="true" 
                    [criteria]="this.searchCriterias.RegNum"
                /> 
            </div>
        </div>
        <div class="panel-row-container">
            <div class="split-50">
                <search-panel-input-date-range 
                    headerText="{{ translateService.getLabel('common.shared.depDate') }}" 
                    [criteria]="this.searchCriterias.DepDate"
                />
            </div>
            <div class="gm-margin-right-15px"></div>
            <div class="split-50">
                <search-panel-input-text 
                    headerText="{{ translateService.getLabel('common.shared.depNum') }}" 
                    [showTextFilter]="true" 
                    [criteria]="this.searchCriterias.DepNum"
                />
            </div> 
        </div>
        <div class="panel-row-container">
            <div class="split-50">
                <search-panel-input-date-range 
                    headerText="{{ translateService.getLabel('common.shared.prioDate') }}" 
                    [criteria]="this.searchCriterias.PrioDate"
                />
            </div>
            <div class="gm-margin-right-15px"></div>
            <div class="split-50">
                <search-panel-input-text 
                    headerText="{{ translateService.getLabel('common.shared.prioNum') }}" 
                    [showTextFilter]="true" 
                    [criteria]="this.searchCriterias.PrioNum"
                />
            </div>
        </div>
        <search-panel-input-check-box
            headerText="{{ translateService.getLabel('common.shared.closed') }}"
            checkBoxLabel="{{ translateService.getLabel('common.shared.closedHelp') }}"
            (criteriaValueChanged)="this.updateIncludeInactive($event)"
        />
    </div>
</mat-expansion-panel> 
<mat-expansion-panel class="search-panel mat-elevation-z0">
    <mat-expansion-panel-header>            
    <div class="panel-header">
        <label class="epw-label bold title">{{ translateService.getLabel('common.groups.dueDates')  }}</label>
    </div>
    </mat-expansion-panel-header>
    <search-panel-input-date-range 
        headerText="{{ translateService.getLabel('common.shared.dueDates') }}" 
        [criteria]="this.searchCriterias.DueDates" 
    />
</mat-expansion-panel> 