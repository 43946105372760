import { Injectable, OnInit } from '@angular/core';
import {TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class EpisoftTranslateService {

  constructor(
        private ngxTranslate: TranslateService,
        private logService: LogService) { }

    public onChangeCallback : Function = () => {};

    private currentLanguageKey : string = 'currentLanguage';

    // Méthode d'ajout de langue
    public addLanguages(languages: string[]): void {
        this.ngxTranslate.addLangs(languages);
    };

    // Récupration de langue du navigateur
    private getBrowserLanguage(): string {
        const browserLang = this.ngxTranslate.getBrowserLang(); 
        if (browserLang){
            return browserLang;
        }
        else{
            return "";
        }
    };

    // Utilisation contrainte de la langue passée en paramètre
    public setCurrentLanguage(languageCode : string): void {
        localStorage.setItem(this.currentLanguageKey, languageCode);
        this.ngxTranslate.use(languageCode.match(/en|fr/) ? languageCode : 'fr');
        this.onChangeCallback();
    };

    // Méthode de plus bas niveau pour récupération de libellé
    public acquire(keyValue: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
        return this.ngxTranslate.get(keyValue, interpolateParams);
    }

    // Récupération de libellé de manière asynchrone
    public getAsyncTrad(keyValue: string ): Observable<string> {
        if(keyValue === "") return new Observable<string>();
        return this.acquire(keyValue);
    };

    // Récupération de libellé de manière synchrone
    public getLabel(keyValue: string):string {
        this.ngxTranslate.instant(keyValue)
        return this.ngxTranslate.instant(keyValue)
    }

    // Récupération du code langue courant
    public getCurrentLanguage(): string {
        return this.ngxTranslate.currentLang
    }

    public initializeCurrentLanguage() {
        // Recherche de la langue courante par ordre de priorité : 
        let languageCode : string = "";
        // 1 - En cache dans la session
        languageCode = localStorage.getItem(this.currentLanguageKey) ?? "";
        if(languageCode === "") {
            // 2 - A partir du navigateur
            languageCode = this.getBrowserLanguage();
        }
        if(languageCode === "") {
            // 3 -  Français par défaut
            languageCode = 'fr';
        }
        this.setCurrentLanguage(languageCode);
    }
}