import { Component } from '@angular/core';
import { PasswordHelper } from '@core/helpers/password-helper';
import { LogService } from '@core/services/log.service';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { UsersService } from '@core/services/users.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent {

  constructor(
    public usersService : UsersService,
    public translateService : EpisoftTranslateService,
    private logService : LogService
  ) {}

  public password1: string = '';

  public password2: string = '';

  public onSubmit() : void {
    this.logService.log("MAJ du mode passe de ", this.usersService.currentUser?.ZenUserID);
    this.usersService.UpdatePassword(this.usersService.currentUser?.ZenUserID ?? "", this.password1);
  }

  public validatePassword() : boolean {
    if(this.password1 != this.password2)
      return false;

    return PasswordHelper.validatePassword(this.password1);
  }

}
