<form>
<div class="user-dialog-component">
  <mat-dialog-content>

    <div class="user-dialog-component__header">
      <span class="user-dialog-component__user-icon glyphicon glyphicon-user"></span>
      <span>{{ this.user.Fullname }} </span>
      <div class="user-dialog-component__close-button" [mat-dialog-close]="true">X</div>
    </div>
    <!-- Message d'aide -->
    <mat-card appearance="outlined">
      <mat-card-content>{{ translateService.getLabel("admin.users.help") }}</mat-card-content>
    </mat-card>
    <!-- Login -->
    <div class="user-dialog-component__input-row">
      <label>{{ translateService.getLabel("admin.users.table.loginDesc") }}</label>
      <input class="user-dialog-component__input" 
        [disabled]="this.userDialogMode != userDialogModeEnum.CreateAdmin && this.userDialogMode != userDialogModeEnum.CreateUser"
        [class.invalid-input]="!validateLogin()" [(ngModel)]="this.user.Login" name="Login"/>
    </div>
    <!-- Nom complet -->
    <div class="user-dialog-component__input-row">
      <label>{{ translateService.getLabel("admin.users.table.name") }}</label>
      <input class="user-dialog-component__input" [class.invalid-input]="!validateFullName()" [(ngModel)]="this.user.Fullname" name="FullName"/>
    </div>
    <!-- Profil -->
    <div class="user-dialog-component__input-row">
      <label>{{ translateService.getLabel("admin.users.table.profile") }}</label>
          <select class="epw-combobox" [(ngModel)]="this.user.Profile" name="ProfileId">
            <option *ngFor="let profile of this.profiles"
            value="{{ profile }}">{{ profile.Name }}
        </option>
      </select>
    </div>
    <!-- Langue -->
    <div class="user-dialog-component__input-row">
      <label>{{ translateService.getLabel("admin.users.table.language") }}</label>
          <select class="epw-combobox" [(ngModel)]="this.user.IsoLanguageCode" name="IsoLanguageCode">
            <option *ngFor="let language of this.languagesCodes"
            value="{{ language }}">{{ translateService.getLabel("common.languages." + language) }}
        </option>
      </select>
    </div>
    <!-- Consignes pour le mot de passe-->
    <mat-card appearance="outlined">
      <mat-card-content>{{ translateService.getLabel("auth.passwordConstraints.part1") }}</mat-card-content>
      <mat-card-content>{{ translateService.getLabel("auth.passwordConstraints.part2") }}</mat-card-content>
    </mat-card>
    <!-- Mot de passe -->
    <div class="user-dialog-component__input-row">
      <label>{{ translateService.getLabel("admin.password.name") }}</label>
      <span>
        <input class="user-dialog-component__input" [class.invalid-input]="!validatePassword()" [(ngModel)]="this.password" name="Password"/>
        <button class="epw-white-button" (click)="generatePassword()">
          <span class="glyphicon glyphicon-repeat"></span>
        </button>
      </span>
    </div>
    <!-- Boutons du bas -->
    <div class="user-dialog-component__bottom">
      <button class="user-dialog-component__bottom--delete-button" [mat-dialog-close]="true" (click)="delete()"
        *ngIf="this.canDelete()">
        <span class="glyphicon glyphicon-trash"></span>{{ translateService.getLabel('common.delete') }}</button>
      <button class="epw-blue-button" [mat-dialog-close]="true" [disabled]="!validateFullName() || !validateLogin() || !validatePassword()" (click)="save()">
        <span class="glyphicon glyphicon-floppy-disk"></span>{{ translateService.getLabel('common.save') }}</button>
       <button class="epw-white-button" [mat-dialog-close]="true">{{ translateService.getLabel('common.close') }}</button>
    </div>

  </mat-dialog-content>
</div>
</form>