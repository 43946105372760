import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { PatentsService } from '@core/services/patents.service';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { FEDPatentDetails } from '@core/models/details/front-end-displayable-details/fed-patent-details';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'patent-details-page',
  templateUrl: './patent-details-page.component.html',
  styleUrls: ['./patent-details-page.component.scss']
})
export class PatentDetailsPageComponent  {
  refgm: string = '' ;
  patentDetails = {} as  FEDPatentDetails;

  constructor(private route: ActivatedRoute, private authService: AuthService, public translateService: EpisoftTranslateService, public patentsService: PatentsService) {

  }

  ngOnInit() {
    this.route.paramMap.subscribe(p => {
      this.refgm = p.get('id') ?? "";
      this.patentsService.getDetails(this.refgm).subscribe(d => 
        { 
          this.patentDetails = d;
        });
    });
  }

  public getImageUrl(e: FEDPatentDetails): string {
    return this.patentsService.GetImageUrl(e.RefGM);
  }

  // Connexion ZenWeb
  public openDocuments() : void {   
    let url: string = `${environment.zenWebUrl}/content/B/${this.patentDetails.CodeZen}?token=${this.authService.getToken()}`;
    window.open(url);
  };

  public canAccessFinancialData() : boolean{
    return this.authService.isAuthorizedToAccessFinancialData()
  }
}
