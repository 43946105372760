import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'search-panel-input-combo-box',
  templateUrl: './search-panel-input-combo-box.component.html',
  styleUrls: ['./search-panel-input-combo-box.component.scss']
})
export class SearchPanelInputComboBoxComponent  {
  @Input({ required: true }) headerText : string = '';
  @Input({ required: true }) items : any[] = [];
  @Output() criteriaValueChanged = new EventEmitter<string> ();
  @Input({ required: true }) labelSelector : Function = (a : any) => "";
  @Input({ required: true }) valueSelector : Function = (a : any) => "";
  @Input({ required: true }) activeSelector : Function = (a : any) : boolean => false;
  @Input({ required: false }) disableSelector : boolean = false;
  selectedValue: string = "";

  constructor(public translateService: EpisoftTranslateService) {}

  public resetSelectedValue() { this.selectedValue = ""; }

  onCriteriaValueChanged() {
      this.criteriaValueChanged.emit(this.selectedValue);
  }
  
}
