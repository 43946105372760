import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FEDUserEntity } from '@core/models/entities/front-end-displayable-entities/fed-user-entity';
import { BERUserEntity } from "@core/models/entities/back-end-raw-entities/ber-user-entity";
import { LogService } from '@core/services/log.service';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { UsersService } from '@core/services/users.service';
import { FEDUserProfileEntity } from '@core/models/entities/front-end-displayable-entities/fed-user-profile-entity';
import { FEDWalletEntity } from '@core/models/entities/front-end-displayable-entities/fed-wallet-entity';
import { PasswordHelper } from '@core/helpers/password-helper';


export enum userDialogModeEnum {
  Undefined,
  CreateUser,
  UpdateUser,
  CreateAdmin,
  UpdateAdmin,
  UpdateAdminSelf
}

@Component({
  selector: 'user-password-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent {

  constructor(
    public usersService : UsersService,
    public translateService : EpisoftTranslateService,
    private logService : LogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public currentUser: FEDUserEntity | undefined;

  public user!: FEDUserEntity;

  public episoftId : string = '';

  public password : string = '';

  public profiles : FEDUserProfileEntity[] = [];

  public wallet: FEDWalletEntity[] = [];

  public languagesCodes : string[] = ["fr", "en"];

  public userDialogModeEnum = userDialogModeEnum;

  public userDialogMode : userDialogModeEnum = userDialogModeEnum.Undefined;

  DEFAULT_PASSWORD : string = "XXXXXXX";

  ngOnInit() {
    this.currentUser = this.data.currentUser;
    if(this.data.user !== undefined)
      this.user = new FEDUserEntity(this.data.user); // On fait une copie de la source pour gérer l'annulation
    this.profiles = this.data.profiles;
    this.wallet = this.data.wallet;
    this.episoftId = this.data.episoftId;
    // Détermination du mode d'affichage de la fenêtre
    if(this.user !== undefined) {
      // Mode mise à jour
      if (this.user.UserLevel ===0) {
        // MAJ user
        this.userDialogMode = userDialogModeEnum.UpdateUser;
      }
      else {
        // MAJ admin - on distingue le cas d'une auto-maj
        if(this.user.ZenUserID === this.currentUser?.ZenUserID)
          this.userDialogMode = userDialogModeEnum.UpdateAdminSelf;
        else
          this.userDialogMode = userDialogModeEnum.UpdateAdmin;
      }
    } else {
      // Mode création - selon le type de l'utilisateur courant, on en déduit si on souhaite créer un user ou un admin
        if(this.currentUser?.IsSuperAdmin)
          this.userDialogMode = userDialogModeEnum.CreateAdmin; // Seuls les super-admins peuvent créer des admins (et il ne peuvent pas créer de simple user)
        else
          this.userDialogMode = userDialogModeEnum.CreateUser;
    }

    // Si on est création, il faut allouer un objet user vide
    if(this.userDialogMode == userDialogModeEnum.CreateUser || this.userDialogMode == userDialogModeEnum.CreateAdmin) {
      this.user = FEDUserEntity.fromBERUserEntity({ 
        Id : 0,
        ZenUserID : "",
        UserLevel : this.userDialogMode == userDialogModeEnum.CreateUser ? 0 : 1, // 0 pour les users, 1 pour les admins
        IsSuperAdmin : false,
        EpisoftID : this.episoftId,
        ProfileID : 1,
        Login : "",
        Profile : this.profiles[0],
        Fullname : "",
        IsoLanguageCode : "fr"
      });
    } else {
      // On utilise un mot de passe par défaut car on ne veut pas afficher le vrai mot de passe en clair
      this.password = this.DEFAULT_PASSWORD;
    }
  }

  public validatePassword() : boolean {
    // Si on est en mode MAJ et que le mot de passe n'a pas été modifié, pas besoin de validation (cas 1)
    // Si on est en mode création OU en mode MAJ et que le mot de passe a été modifié, on doit valider (cas 2)
    return (
      ((this.userDialogMode == userDialogModeEnum.UpdateAdmin || this.userDialogMode == userDialogModeEnum.UpdateUser || this.userDialogMode == userDialogModeEnum.UpdateAdminSelf)
        && (this.password == this.DEFAULT_PASSWORD))
      || PasswordHelper.validatePassword(this.password));
  }

  public validateLogin() : boolean {
    return this.user.Login.indexOf("@") >= 0 && this.user.Login.length > 3;
  }

  public validateFullName() : boolean {
    return this.user.Fullname.length > 2;
  }

  public generatePassword() {
    this.password = PasswordHelper.generatePassword();
  }

  save() {
    if(this.userDialogMode == userDialogModeEnum.CreateUser || this.userDialogMode == userDialogModeEnum.CreateAdmin) {    
      // Mode création
      this.usersService.CreateUser(this.user, this.password);
    } else {
      // Mode MAJ
      // On transmet le mot de passe uniquement s'il a changé
      this.usersService.UpdateUser(this.user, this.password !== this.DEFAULT_PASSWORD ? this.password : undefined);
    }
  }

  delete() {
      this.usersService.DeleteUser(this.user);
  }

  canDelete() : boolean {
    // On peut supprimer uniquement si on est en modification ...
    if(this.userDialogMode == userDialogModeEnum.UpdateAdmin || this.userDialogMode == userDialogModeEnum.UpdateUser) {
      // ... et uniquement les utilisateurs simples NON admin ... sauf si on est soi-même Super Admin
      if(this.user.UserLevel == 0 || this.currentUser?.IsSuperAdmin)
        return true;
    }
    return false;
  }

}