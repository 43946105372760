import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { ApiPathsService } from '@core/services/api-paths.service';
import { EntityRef } from '@core/models/entity-ref';
import { Filter } from '@core/models/filter';
import { Owner } from '@core/models/owner';
import { HydratationHelper } from '@core/helpers/hydratation-helper';
import { EpisoftTranslateService } from './translate.service';
import { AuthService } from './auth.service';
import { ParametersService } from './parameters.service';
import { FEDWatchingEntity } from '@core/models/entities/front-end-displayable-entities/fed-watching-entity';
import { BERWatchingEntity } from '@core/models/entities/back-end-raw-entities/ber-watching-entity';
import { WatchingCriteria } from "@core/models/criterias/watching-criteria";
import { ExportActionType } from '@core/models/export-action';
import { ExportService } from './export.service';
import { environment } from 'src/environments/environment';

export interface WatchingData {
    Refs: EntityRef[];
    Entities: BERWatchingEntity[];
  }
  
  export interface WatchingParamsData {
    AllTypeTitles: EntityRef[];
    TypeTitles: EntityRef[];
    TypeTitlesFilter: Filter[];
    OptionsFilter: Filter[];
    CountriesFilter: Filter[];
    TypeDueDates: EntityRef[];
    AllWatchingOptions:EntityRef[];
    WatchingOption: EntityRef[];
    Owners: Owner[];
  }
  
  @Injectable({
    providedIn: 'root'
  })
  export class WatchingsService {
  
    constructor(private httpApiService: HttpApiService, private translateService: EpisoftTranslateService, private parametersService : ParametersService, private apiPathsService: ApiPathsService, private authService: AuthService, private exportService: ExportService) { }
    
    public GetWatchings(searchCriterias: WatchingCriteria | null = null): Observable<FEDWatchingEntity[]> {
      let specificData: WatchingParamsData;
        // Chargement des paramètres globaux
        return this.parametersService.Get().pipe(
          // Chargement des paramètres spécifiques
          switchMap( _ => this.GetParams()),
          // Chargement des données
          switchMap( data => {
            specificData = data;
            return this.httpApiService.post<WatchingData>("API-Watchings-POST", searchCriterias);
          }),
          // Hydratation des données
          map((data: WatchingData) => { 
            let entities : FEDWatchingEntity[] = (data.Entities ?? []).map(e => new FEDWatchingEntity(e));
            HydratationHelper.HydrateWatchings(entities, data.Refs, specificData.TypeTitles, specificData.WatchingOption, this.parametersService, this.translateService.getCurrentLanguage());
            return entities;
          })
        );       
    }
  
    public GetParams(): Observable<WatchingParamsData> {
      let data: Observable<WatchingParamsData> = this.httpApiService.get<WatchingParamsData>("API-WatchingsParams-GET");
      return data.pipe(map((data: WatchingParamsData) => { 
        return data;
      }));
    }
    
    public getTypeAhead(): Observable<any>{
      let data: Observable<any> = this.httpApiService.get<any>("API-WatchingsTypeAhead-GET");
      return data.pipe(map((data: any) => { 
        return data;
      }));
    }
  
    public GetImageUrl(refgm: string) : string {
      // L'URL d'une image est du type : https://episoft-test.germainmaureau.com/api/zen/epi/watchings/SU045692/image?token=FOO
      return environment.backendUrl + 
            this.apiPathsService.GetUrl("API-WatchingsImage-GET", new Map<string, string>([["refgm", refgm], ["token", this.authService.getToken()]]));
    }

    public Export(exportType: ExportActionType, items: FEDWatchingEntity[]) : void {
      // Seul l'export liste est autorisé pour les SU
      if (exportType !== ExportActionType.List)
          throw new Error("Invalid export type");
  
      // Appel du service générique d'export
      this.exportService.Export(
        // API
        "API-WatchingsExport-POST",
        // Références des dossiers à exporter
        items.map(i => i.RefGM),
        // Colonnes
        [ 
          { Code: "RefGM", Text: "common.shared.refGM" },
          { Code: "Denomination", Text: "watching.props.name" },
          { Code: "Logo", Text: "" },
          { Code: "TypeLabel",Text: "watching.props.type"},
          { Code: "SubscriptionLabel", Text: "watching.props.subscriptions"},
          { Code: "Options", Text: "watching.props.options"},
          { Code: "Classes", Text: "watching.props.classes"},
          { Code: "Situation", Text: "common.shared.status" }
        ],
        // Textes
        [
          { Code: "Title", Text: "watching.name" }
        ],
        // Onglets
        []
      );
    }
  }