<div class="content-panel-content">
    <tab-content-panel-header 
        (toggleSearchPanel)="this.toggleSearchPanel.emit()" 
        (selectAllValueChanged)="this.selectAllValueChanged()"
        [tabContext]="this.tabContext"
        />
        <div class="content-panel-content__cards">
            <ng-container *ngIf="selectedComponent">
            <div *ngFor="let details of this.tabContext.visibleData"  [ngClass]="{ 'gray-text': !details.Active }">
                <ng-container *ngComponentOutlet="selectedComponent; inputs: { details: details, parent: this }" />
            </div>
        </ng-container>
          </div>     
</div>