
import { BERTrademarkFractionEntity } from "../back-end-raw-entities/ber-trademark-fraction-entity";
import { FEDBaseEntity } from "./fed-base-entity";

export class FEDTrademarkFractionEntity extends FEDBaseEntity implements BERTrademarkFractionEntity {
    constructor(source : BERTrademarkFractionEntity) {
        super(source);
        Object.assign(this, source);
    }
    // Données de BERTrademarkFractionEntity
    Classes!: string;
    DepositCountry!: string;
    DesignatedCountries!: { Code: string; SubItems: []; Active: boolean; }[];
    DesignationDate!: Date | null;
    Designation!: { Code: string; FR: string; EN: string; } | null;
    // Données connues uniquement côté Front End
    DesignatedCountriesLabel!: string;
    DepositCountryLabel!: string;
    DesignationLabel!: string;
    DesignationDateLabel!: string;
}
