<div><label class="epw-label blue bold">{{ this.headerText }}</label></div>
<div class="row-container">
    <input type="text" class="epw-search-panel-input" [(ngModel)]="this.criteria.Txt">
    <span *ngIf="this.showTextFilter" >
        <button [matMenuTriggerFor]="menu" class="epw-white-button sort-button">
            <span class="glyphicon"
            [ngClass]="getGlyphiconClass(this.criteria.Comp)"></span>
        </button>
        <mat-menu #menu="matMenu">
            <button *ngFor="let comp of this.getComparisonOptions()" (click)="updateCriteria(comp)" mat-menu-item>
                <span class="filter-menu-item glyphicon" [ngClass]="this.getGlyphiconClass(comp)"></span>
                {{ translateService.getLabel('common.searchComparison.' + comp) }}
            </button>
        </mat-menu>
    </span>
</div>

