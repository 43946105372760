import { Component } from '@angular/core';
import { combineLatest, forkJoin, map, switchMap } from 'rxjs';
import { FEDUserEntity } from '@core/models/entities/front-end-displayable-entities/fed-user-entity';
import { FEDUserProfileEntity } from '@core/models/entities/front-end-displayable-entities/fed-user-profile-entity';
import { EpisoftTranslateService } from '@core/services/translate.service';
import { UserProfilesService } from '@core/services/user-profiles.service';
import { UsersService } from '@core/services/users.service';
import { FEDWalletEntity } from '@core/models/entities/front-end-displayable-entities/fed-wallet-entity';
import { WalletService } from '@core/services/wallet.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserDialogComponent } from '@shared/dialogs/user-dialog/user-dialog.component';
import { ProfileDialogComponent } from '@shared/dialogs/profile-dialog/profile-dialog.component';

export enum AdminTabComponent__SubPage {
  Users,
  Profiles,
  Messages,
}

@Component({
  selector: 'admin-tab',
  templateUrl: './admin-tab.component.html',
  styleUrls: ['./admin-tab.component.scss']
})
export class AdminTabComponent {
  constructor(
    private route: ActivatedRoute,
    public translateService: EpisoftTranslateService,
    public usersService: UsersService,
    public userProfilesService: UserProfilesService,
    public walletService: WalletService,
    public dialog: MatDialog
    ){ }

    // Export de l'enum pour qu'il soit visible dans le composant HTML
    public SubPage = AdminTabComponent__SubPage;

    public currentSubPage : AdminTabComponent__SubPage = AdminTabComponent__SubPage.Users;

    public updateCurrentSubPage(newsubPage: AdminTabComponent__SubPage) {
        if(newsubPage == this.currentSubPage)
          return;
      this.currentSubPage = newsubPage;
    }

    public users: FEDUserEntity[] =  [];

    public currentUser!: FEDUserEntity;

    public profiles: FEDUserProfileEntity[] =  [];

    public wallet: FEDWalletEntity[] = []

    public currentEpisoftId: string  = '';

    ngOnInit() {
      this.refreshData();
    }
  
    refreshData() {
      // Chargement des users et des profils (et titulaires pour le filtrage du portefeuille)
      combineLatest( {
        currentUser: this.usersService.GetCurrentUserData(),
        routeParams: this.route.queryParamMap,
      }).subscribe(({ currentUser, routeParams }) => {
        // Par défaut, l'inscription courante est celle du user connecté
        this.currentEpisoftId = currentUser.EpisoftID;
        this.currentUser = currentUser;
        // Les SuperAdmins peuvent gérer une inscription via le paramètre en querystring
        if(currentUser.IsSuperAdmin && routeParams.has("eid")) {
          this.currentEpisoftId = routeParams.get("eid") ?? '';
        }
        // Maintenant que l'on a l'inscription courante, on peut récupérer les users, profils et titulaires
        // forkJoin pour faire les appels de service en parallèle
        forkJoin({
          users: this.usersService.GetUsers(this.currentEpisoftId),
          profiles: this.userProfilesService.GetProfiles(this.currentEpisoftId),
          wallet: this.walletService.GetWallet(this.currentEpisoftId)
        }).subscribe(({ users, profiles, wallet }) => {
          this.users = users;
          this.users.sort((u1, u2) => u1.Id - u2.Id); // Tri par ID
          this.profiles = profiles;
          this.wallet = wallet;
          // Rattachement des profils aux users
          this.users.forEach(user => {
            let profile = this.profiles.find(p => p.Id === user.ProfileID);
            if (profile !== undefined) {
              user.Profile = profile;
            }
          });
          // Rattachement des titulaires aux profils
          // this.profiles.forEach(profile => {
          //   // Les titulaires sont stockés sous forme d'une liste de NUMREL dans la propriété WALLET
          //   // Pour chacun, on va récupérer l'objet Owner correspondant dans la collection Wallet renvoyée par l'API
          //   profile.Wallet.forEach(ownerId => {
          //     let owner = this.wallet.find(o => o.Id === ownerId);
          //     if (owner !== undefined) {
          //         profile.FilteringOwners.push(owner);
          //     }
          //   });
          // });
      })
    });
    }

  showUserDialog(user: FEDUserEntity | undefined) {
    let profiles = this.profiles;
    let wallet = this.wallet;
    let currentUser = this.currentUser;
    let episoftId = this.currentEpisoftId;
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '650px',
      data: { currentUser, user, profiles, wallet, episoftId } // données à passer à la boite de dialogue
    });

    // Rafraichissement de la page après sortie
    dialogRef.afterClosed().subscribe(result => {
      this.refreshData();
    });
  }

  showProfileDialog(profile: FEDUserProfileEntity | undefined) {
    let wallet = this.wallet;
    let currentUser = this.currentUser;
    const dialogRef = this.dialog.open(ProfileDialogComponent, {
      width: '650px',
      data: { currentUser, profile, wallet } // données à passer à la boite de dialogue
    });

    // Rafraichissement de la page après sortie
    dialogRef.afterClosed().subscribe(result => {
      this.refreshData();
    });
  }
}



