// Contient des fonctions utilitaires permettant d'hydrater des objets.
// C'est à dire : réinjecter dans ces objets, en générale renvoyés par les APIs, 
// des valeurs qui sont référencées via leur code
// Et dont les libellés sont retournés séparément via un dictionnaire code => libellé

import { FEDTrademarkEntity } from "@core/models/entities/front-end-displayable-entities/fed-trademark-entity";
import { EntityRef } from "@core/models/entity-ref";
import { EntityRef as EntityRefDetails } from "@core/models/details/entity-ref";
import { ParametersService } from "@core/services/parameters.service";
import { FEDOffTitleEntity } from "@core/models/entities/front-end-displayable-entities/fed-off-title-entity";
import { FEDDrawingModelEntity } from "@core/models/entities/front-end-displayable-entities/fed-drawing-model-entity";
import { FEDPatentFamilyEntity } from "@core/models/entities/front-end-displayable-entities/fed-patent-family-entity";
import { FEDWatchingEntity } from "@core/models/entities/front-end-displayable-entities/fed-watching-entity";
import { FEDBaseEntity } from "@core/models/entities/front-end-displayable-entities/fed-base-entity";
import { FEDTrademarkFractionEntity } from "@core/models/entities/front-end-displayable-entities/fed-trademark-fraction-entity";
import { FEDDrawingModelFractionEntity } from "@core/models/entities/front-end-displayable-entities/fed-drawing-model-fraction-entity";
import { FEDSoleauEntity } from "@core/models/entities/front-end-displayable-entities/fed-soleau-entity";
import { FEDPatentMemberEntity } from "@core/models/entities/front-end-displayable-entities/fed-patent-member-entity ";
import { FEDDomainNameEntity } from "@core/models/entities/front-end-displayable-entities/fed-domain-name-entity";
import { FEDPatentDetails } from "@core/models/details/front-end-displayable-details/fed-patent-details";
import { FEDTrademarkDetails } from "@core/models/details/front-end-displayable-details/fed-trademark-details";
import { FEDDrawingModelDetails } from "@core/models/details/front-end-displayable-details/fed-drawing-model-details";

export abstract class HydratationHelper {

  public static HydrateProperty<T extends FEDBaseEntity>(entities: T[], refs: EntityRef[], propertyRefGetter: (entity: T) => EntityRef | undefined, propertySetter: (e: T, s: string) => void, languageCode: string): void {
    entities.forEach(entity => {
        // Modifie la propriété avec la nouvelle valeur
        const ref = propertyRefGetter(entity);
        if (ref !== undefined) 
        {
          propertySetter(entity, (languageCode == "en") ? ref.EN : ref.FR);
        }
    });
  }

  public static HydrateTrademarks(entities : FEDTrademarkEntity[], situationRefs: EntityRef[], typeRefs: EntityRef[], parametersService: ParametersService, languageCode: string) {
    // Libellé de la situation
    HydratationHelper.HydrateProperty<FEDTrademarkEntity>(entities, situationRefs, 
      (e) => situationRefs.find(r => r.$id === e.Root.Situation.$ref), 
      (e, s) => { e.SituationLabel = s; },
      languageCode);
    // Libellé de la situation - fractions
    HydratationHelper.HydrateProperty<FEDTrademarkFractionEntity>(entities.flatMap(e => e.Fractions), situationRefs, 
      (e) => situationRefs.find(r => r.$id === e.Situation.$ref), 
      (e, s) => { e.SituationLabel = s; },
      languageCode);
     // Libellé du type de marque
     HydratationHelper.HydrateProperty<FEDTrademarkEntity>(entities, typeRefs, 
      (e) => typeRefs.find(r => r.Code === e.Root.Type), 
      (e, s) => { e.Root.TypeLabel = s; },
      languageCode);

      var locale = languageCode === "fr" ? 'fr-FR': 'en-US';  
      entities.forEach(element => {
        // Libellé du pays de dépôt pour les marques
        element.Root.DepositCountryLabel = parametersService.GetCountryLabelFromCode(element.Root.DepositCountry, languageCode);
        // Libellé classes pour les marques
        element.Root.ClassesLabel = element.Root.Classes.map(c => c.Num).join(', ');
        element.Fractions.forEach(fraction => {
          // Libellé du pays de dépôt pour les fractions 
          fraction.DepositCountryLabel = parametersService.GetCountryLabelFromCode(fraction.DepositCountry, languageCode);
          // Libellé date de dépôt pour les fractions
          fraction.DesignationDateLabel = fraction.DesignationDate !== null ? new Date(fraction.DesignationDate).toLocaleDateString(locale) : "";
          // Libellé désignation pour les fractions
          fraction.DesignationLabel = this.getEnglishLabelIfExistAndNeeded(fraction.Designation, languageCode);
        });
        // Libellé titulaires
        element.Root.OwnersLabel = element.Root.Owners.map(owner => owner.Name).join(', ');
        // Libellé pays désignés
        // Construction de la liste des libellés correspondant à chaque code de pays désigné
        // Fonction utilitaire pour construire le label des pays
        function buildCountryLabel(designatedCountries: { Code: string }[], languageCode: string): string {
          return designatedCountries
            .map(c => parametersService.GetCountryLabelFromCode(c.Code, languageCode))
            .join(", ");
        }

        // Construction de la liste des libellés correspondant à chaque code de pays désigné
        element.Root.DesignatedCountriesLabel = buildCountryLabel(element.Root.DesignatedCountries, languageCode);

        // Idem pour les fractions
        element.Fractions.forEach(fraction => {
          fraction.DesignatedCountriesLabel = buildCountryLabel(fraction.DesignatedCountries, languageCode);
        });
  
        // Dates de renouvellement
        element.Root.RenewDueDatesLabels = element.Root.RenewDueDates.map(d => new Date(d.Date).toLocaleDateString(locale)).join(' ');
        // Date de dépôt
        element.Root.OriDepDateLabel = element.Root.OriDepDate !== null ? new Date(element.Root.OriDepDate).toLocaleDateString(locale) : "";
        // Date d'enregistrement
        element.Root.CurrRegDateLabel = element.Root.CurrRegDate !== null ? new Date(element.Root.CurrRegDate).toLocaleDateString(locale) : "";
    });
  }

  public static HydrateDrawingModels(entities : FEDDrawingModelEntity[], situationRefs: EntityRef[], typeRefs: EntityRef[], parametersService: ParametersService, languageCode: string) {
    // Libellé de la situation
    HydratationHelper.HydrateProperty<FEDDrawingModelEntity>(entities, situationRefs, 
      (e) => situationRefs.find(r => r.$id === e.Root.Situation.$ref), 
      (e, s) => { e.SituationLabel = s; },
      languageCode);
    // Libellé de la situation - fractions
    HydratationHelper.HydrateProperty<FEDDrawingModelFractionEntity>(entities.flatMap(e => e.Fractions), situationRefs, 
      (e) => situationRefs.find(r => r.$id === e.Situation.$ref), 
      (e, s) => { e.SituationLabel = s; },
      languageCode);
    // Libellé du type de DM
    HydratationHelper.HydrateProperty<FEDDrawingModelEntity>(entities, typeRefs, 
      (e) => typeRefs.find(r => r.Code === e.Root.Type), 
      (e, s) => { e.Root.TypeLabel = s; },
      languageCode);
    
    var locale = languageCode === "fr" ? 'fr-FR': 'en-US';  
    entities.forEach(element => {
      // Titre dans langue demandée si disponible
      element.Root.CurrentTitleLabel = this.getEnglishLabelIfExistAndNeeded(element.Root.Title, languageCode);
      // Libellé du pays de dépôt
      element.Root.DepositCountryLabel = parametersService.GetCountryLabelFromCode(element.Root.DepositCountry, languageCode);
      element.Fractions.forEach(fraction => {
        fraction.DepositCountryLabel = parametersService.GetCountryLabelFromCode(fraction.DepositCountry, languageCode);
        // Libellé date de dépôt pour les fractions
        fraction.DesignationDateLabel = fraction.DesignationDate !== null ? new Date(fraction.DesignationDate).toLocaleDateString(locale) : "";
        // Libellé désignation pour les fractions
        fraction.DesignationLabel = this.getEnglishLabelIfExistAndNeeded(fraction.Designation, languageCode);
      });
      // Libellé titulaires
      element.Root.OwnersLabel = element.Root.Owners.map(owner => owner.Name).join(', ');
      // Libellé pays désignés
      // Fonction utilitaire pour construire le label des pays
      function buildCountryLabel(designatedCountries: { Code: string }[], languageCode: string): string {
        return designatedCountries
          .map(c => parametersService.GetCountryLabelFromCode(c.Code, languageCode))
          .join(", ");
      }

      // Construction de la liste des libellés correspondant à chaque code de pays désigné
      element.Root.DesignatedCountriesLabel = buildCountryLabel(element.Root.DesignatedCountries, languageCode);

      // Idem pour les fractions
      element.Fractions.forEach(fraction => {
        fraction.DesignatedCountriesLabel = buildCountryLabel(fraction.DesignatedCountries, languageCode);
      });

      // Dates de renouvellement
      element.Root.RenewDueDatesLabels = element.Root.RenewDueDates.map(d => new Date(d.Date).toLocaleDateString(locale)).join(' ');
      // Date de dépôt
      element.Root.OriDepDateLabel = element.Root.OriDepDate !== null ? new Date(element.Root.OriDepDate).toLocaleDateString(locale) : "";
      // Date d'enregistrement
      element.Root.CurrRegDateLabel = element.Root.CurrRegDate !== null ? new Date(element.Root.CurrRegDate).toLocaleDateString(locale) : "";
    });
  }

  public static HydrateOffTitles(entities : FEDOffTitleEntity[], situationRefs: EntityRef[], typeRefs: EntityRef[], parametersService: ParametersService, languageCode: string) {
    // Libellé de la situation
    HydratationHelper.HydrateProperty<FEDOffTitleEntity>(entities, situationRefs, 
      (e) => situationRefs.find(r => r.$id === e.Situation.$ref), 
      (e, s) => { e.SituationLabel = s; },
      languageCode);
     // Libellé du type de DM
     HydratationHelper.HydrateProperty<FEDOffTitleEntity>(entities, typeRefs, 
      (e) => typeRefs.find(r => r.Code === e.HTType), 
      (e, s) => { e.TypeLabel = s; },
      languageCode);
    // Libellé du pays de dépôt et titre dans langue demandée si disponible
    entities.forEach(element => {
      element.CountryLabel = parametersService.GetCountryLabelFromCode(element.Country, languageCode);
      element.CurrentTitleLabel = languageCode == "en" && element.Title.EN !== '' ? element.Title.EN : element.Title.FR;
    });
  }
  
  public static HydrateSoleaus(entities : FEDSoleauEntity[], situationRefs: EntityRef[], languageCode: string) {
    // Libellé de la situation
    HydratationHelper.HydrateProperty<FEDSoleauEntity>(entities, situationRefs, 
      (e) => situationRefs.find(r => r.$id === e.Situation.$ref), 
      (e, s) => { e.SituationLabel = s; },
      languageCode); 
      var locale = languageCode === "fr" ? 'fr-FR': 'en-US';
      entities.forEach(element => {
        // Titre dans langue demandée si disponible
        element.CurrentTitleLabel = languageCode == "en" && element.Title.EN !== '' ? element.Title.EN : element.Title.FR;
        // Dates de renouvellement
        element.RenewDueDatesLabels = element.RenewDueDates.map(d => new Date(d.Date).toLocaleDateString(locale)).join(' ');
        // Date de dépôt
        element.DepDateLabel = element.DepDate !== null ? new Date(element.DepDate).toLocaleDateString(locale) : "";
      });  
  }

  public static HydrateDomainNames(entities : FEDDomainNameEntity[], situationRefs: EntityRef[], languageCode: string) {
    // Libellé de la situation
    HydratationHelper.HydrateProperty<FEDDomainNameEntity>(entities, situationRefs, 
      (e) => situationRefs.find(r => r.$id === e.Situation.$ref), 
      (e, s) => { e.SituationLabel = s; },
      languageCode);
      // Dates de renouvellement
      var locale = languageCode === "fr" ? 'fr-FR': 'en-US';
      entities.forEach(e => e.RenewDueDatesLabels = e.RenewDueDates.map(d => new Date(d.Date).toLocaleDateString(locale)).join(' '));
      // Date de réservation
      entities.forEach(e => e.BookDateLabel = e.BookingDate !== null ? new Date(e.BookingDate).toLocaleDateString(locale) : "");
  }

  public static HydratePatents(entities : FEDPatentFamilyEntity[], situationRefs: EntityRef[], typeRefs: EntityRef[], parametersService: ParametersService, languageCode: string) {
    // Libellé de la situation - membres
    HydratationHelper.HydrateProperty<FEDPatentMemberEntity>(entities.flatMap(e => e.Patents), situationRefs, 
      (e) => situationRefs.find(r => r.$id === e.Situation.$ref), 
      (e, s) => { e.SituationLabel = s; },
      languageCode);

    var locale = languageCode === "fr" ? 'fr-FR': 'en-US';    
    entities.forEach(element => {
      // Libellé titre famille
      element.FamilyTitleLabel = element.FamilyNum + " - " + (this.getEnglishLabelIfExistAndNeeded(element.TitleShort, languageCode));
      // Libellé titre complet
      element.TitleLabel = this.getEnglishLabelIfExistAndNeeded(element.Title, languageCode);
      // Libellé titulaires
      element.OwnersLabel = element.Owners.map(owner => owner.Name).join(', ');
      // Libellé inventeurs
      element.InventorsLabel = element.Inventors.map(inventor => inventor.Name).join(', ');      
    });

    entities.flatMap(e => e.Patents).forEach(element => {
      // Libellé du pays de dépôt
      element.CountryLabel = parametersService.GetCountryLabelFromCode(element.DepositCountry, languageCode);
      // Date de dépôt
      element.DepDateLabel = element.DepDate !== null ? new Date(element.DepDate).toLocaleDateString(locale) : "";
      // Date de publication
      element.PubDateLabel = element.PubDate !== null ? new Date(element.PubDate).toLocaleDateString(locale) : "";      
    });
  }

  public static HydrateWatchings(entities : FEDWatchingEntity[],  situationRefs: EntityRef[], typeRefs: EntityRef[], optionRefs: EntityRef[], parametersService: ParametersService, languageCode: string) {
    // Libellé de la situation
      HydratationHelper.HydrateProperty<FEDWatchingEntity>(entities, situationRefs, 
        (e) => situationRefs.find(r => r.$id === e.Situation.$ref), 
        (e, s) => { e.SituationLabel = s; },
        languageCode);   
      // Libellé du type de surveillance
     HydratationHelper.HydrateProperty<FEDWatchingEntity>(entities, typeRefs, 
        (e) => typeRefs.find(r => r.Code === e.WatchingTypeCode), 
        (e, s) => { e.TypeLabel = s; },
        languageCode);
      // Option de surveillance      
      entities.forEach(currentEntity => {      
        const currentSubscriptionIndex: number = +currentEntity.CurrentSubscriptionNumOrder - 1; // Index commence à 0 et non 1 comme NumOrder
        const ref = optionRefs.find(x => x.Code === currentEntity.Options[currentSubscriptionIndex].TypeOption);
        if (ref !== undefined) 
        {
          if(languageCode == "en"){
            currentEntity.CurrentOptionLabel = ref.EN;
          } 
          else {
            currentEntity.CurrentOptionLabel = ref.FR;
          }
          
          // Si le code option de pays est renseigné, son libellé est également alimenté.
          if(currentEntity.Options[currentSubscriptionIndex].CodePaysOption !== undefined){
            entities.filter(element => element.RefGM === currentEntity.RefGM && element.Options[currentSubscriptionIndex].CodePaysOption !== "").forEach(element => {
                element.CurrentOptionLabel +=  " : " 
                  + parametersService.GetCountryLabelFromCode(element.Options[currentSubscriptionIndex].CodePaysOption, languageCode);      
              }
            );
          }          
        }
        // Libellé abonnement surveillance en vigueur
        var locale = languageCode === "fr" ? 'fr-FR': 'en-US';
        currentEntity.CurrentSubscriptionLabel = currentEntity.Subscriptions[currentSubscriptionIndex].StartDate!== null ? 
          new Date(currentEntity.Subscriptions[currentSubscriptionIndex].StartDate).toLocaleDateString(locale) : "";
          currentEntity.CurrentSubscriptionLabel += currentEntity.Subscriptions[currentSubscriptionIndex].DueDate!== null ? 
          " " + new Date(currentEntity.Subscriptions[currentSubscriptionIndex].DueDate).toLocaleDateString(locale) : "";
        // Libellé classes surveillance en vigueur
        // On passe par un Set temporaire afin d'éliminer les doublons
        var classesLabels = [... new Set(currentEntity.Classes.map(c => c.Num))];
        classesLabels.sort();
        currentEntity.CurrentClassesLabel = classesLabels.join(', ');
      });
  }

  public static HydratePatentDetails(entity: FEDPatentDetails, languageCode: string){
    var locale = languageCode === "fr" ? 'fr-FR': 'en-US';
    var currentTotalHT = 0 ;
    var currentTotalTVA = 0 ;
    var currentTotalAmount = 0 ;
    // Alimentation dates selon formatage de langue souhaitée
    entity.OriDepDateLabel = entity.OriDepDate !== null ? new Date(entity.OriDepDate).toLocaleDateString(locale) : "";
    entity.PubDateLabel = entity.PubDate !== null ? new Date(entity.PubDate).toLocaleDateString(locale) : "";
    entity.PatentDateLabel = entity.PatentDate !== null ? new Date(entity.PatentDate).toLocaleDateString(locale) : "";
    entity.PrioDateLabel = entity.PrioDate !== null ? new Date(entity.PrioDate).toLocaleDateString(locale) : "";
    // Alimentation libellés selon langue souhaitée
    entity.DepositCountry.Label = this.getEnglishLabelIfExistAndNeeded(entity.DepositCountry, languageCode);
    entity.TypeTitle.Label = this.getEnglishLabelIfExistAndNeeded(entity.TypeTitle, languageCode);
    entity.DepositKindLabel = this.getEnglishLabelIfExistAndNeeded(entity.DepositKind, languageCode);
    entity.TitleLabel = this.getEnglishLabelIfExistAndNeeded(entity.Title, languageCode);
    entity.TitleShortLabel = this.getEnglishLabelIfExistAndNeeded(entity.TitleShort, languageCode);
    entity.Situation.Label = this.getEnglishLabelIfExistAndNeeded(entity.Situation, languageCode);
    // Alimentation libellés pays désignés le cas échéant selon langue souhaitée
    if (entity.DesignatedCountries.length > 0){
      entity.DesignatedCountries.forEach(designatedCountry => { 
        designatedCountry.Label.Label = this.getEnglishLabelIfExistAndNeeded(designatedCountry.Label, languageCode);
      })
    }
    // Alimentation libellés inscriptions le cas échéant selon langue souhaitée
    if (entity.Records.length > 0){
      entity.Records.forEach(record => { 
        record.Nature.Label = this.getEnglishLabelIfExistAndNeeded(record.Nature, languageCode);
        record.DateLabel = record.Date !== null ? new Date(record.Date).toLocaleDateString(locale) : "";
        record.TxtLabel = this.getEnglishLabelIfExistAndNeeded(record.Txt, languageCode);
      })
    }
    // Alimentation dates selon langue souhaitée
    entity.Annuities.forEach(annuity => { 
      annuity.DateLabel = annuity.Date !== null ? new Date(annuity.Date).toLocaleDateString(locale) : "";
    })
    // Alimentation date, libellé selon langue souhaitée et mise à jour totaux montants 
    entity.Billings.forEach(billing => { 
      billing.DateLabel = billing.Date !== null ? new Date(billing.Date).toLocaleDateString(locale) : "";
      billing.BillingType.Label = this.getEnglishLabelIfExistAndNeeded(billing.BillingType, languageCode);
      billing.HTLabel = this.getAmountLabel(billing.HT, locale);
      billing.TVALabel = this.getAmountLabel(billing.TVA, locale);
      billing.AmountLabel = this.getAmountLabel(billing.Amount, locale);
      currentTotalHT += billing.HT
      currentTotalTVA += billing.TVA
      currentTotalAmount += billing.Amount
    })
    // Alimentation libellés selon langue souhaitée
    entity.Owners.forEach(owner => { 
      owner.Country.Label = this.getEnglishLabelIfExistAndNeeded(owner.Country, languageCode);
    })
    // Alimentation libellés selon langue souhaitée
    entity.Inventors.forEach(inventor => { 
      inventor.Country.Label = this.getEnglishLabelIfExistAndNeeded(inventor.Country, languageCode);
    })

    // Arrondi avec deux chiffres après la virgule et séparateur de miliers
    entity.TotalHT =  this.getAmountLabel(currentTotalHT, locale);
    entity.TotalTVA = this.getAmountLabel(currentTotalTVA, locale);
    entity.TotalAmount = this.getAmountLabel(currentTotalAmount, locale);
  } 

  public static HydrateTrademarkDetails(entity: FEDTrademarkDetails, languageCode: string){
    var locale = languageCode === "fr" ? 'fr-FR': 'en-US';
    var currentTotalHT = 0 ;
    var currentTotalTVA = 0 ;
    var currentTotalAmount = 0 ;

    // Alimentation libellés selon langue souhaitée
    entity.Root.DepositCountry.Label = this.getEnglishLabelIfExistAndNeeded(entity.Root.DepositCountry, languageCode);
    entity.Root.TypeLabel = this.getEnglishLabelIfExistAndNeeded(entity.Root.TypeTitle, languageCode);
    entity.Root.DepositKindLabel = this.getEnglishLabelIfExistAndNeeded(entity.Root.DepositKind, languageCode);
    entity.Root.ClassesLabel = entity.Root.Classes.map(c => c.Num).join(', ');
    entity.Root.Situation.Label = this.getEnglishLabelIfExistAndNeeded(entity.Root.Situation, languageCode);
    if(entity.Root.Designation !== null){
      entity.Root.Designation.Label = this.getEnglishLabelIfExistAndNeeded(entity.Root.Designation, languageCode);
    }
    // Alimentation dates selon formatage de langue souhaitée
    entity.Root.OriDepDateLabel = entity.Root.OriDepDate !== null ? new Date(entity.Root.OriDepDate).toLocaleDateString(locale) : "";
    entity.Root.LastRenewDateLabel = entity.Root.LastRenewDate !== null ? new Date(entity.Root.LastRenewDate).toLocaleDateString(locale) : "";
    entity.Root.OriRegDateLabel = entity.Root.OriRegDate !== null ? new Date(entity.Root.OriRegDate).toLocaleDateString(locale) : "";
    entity.Root.CurrRegDateLabel = entity.Root.CurrRegDate !== null ? new Date(entity.Root.CurrRegDate).toLocaleDateString(locale) : "";
    entity.Root.PrioDateLabel = entity.Root.PrioDate !== null ? new Date(entity.Root.PrioDate).toLocaleDateString(locale) : "";
    entity.Root.DesignationDateLabel = entity.Root.DesignationDate !== null ? new Date(entity.Root.DesignationDate).toLocaleDateString(locale) : "";
    entity.Root.ReplacementDateLabel= entity.Root.ReplacementDate !== null ? new Date(entity.Root.ReplacementDate).toLocaleDateString(locale) : "";
    entity.Root.ProtectionDateLabel= entity.Root.ProtectionDate !== null ? new Date(entity.Root.ProtectionDate).toLocaleDateString(locale) : "";    
    // Alimentation libellés selon langue souhaitée
    entity.Root.Owners.forEach(owner => { 
      owner.Country.Label = this.getEnglishLabelIfExistAndNeeded(owner.Country, languageCode);
    })
    // Alimentation dates selon langue souhaitée
    entity.Root.DueDates.forEach(dueDate => { 
      dueDate.DateLabel = dueDate.Date !== null ? new Date(dueDate.Date).toLocaleDateString(locale) : "";
      dueDate.Type.Label = this.getEnglishLabelIfExistAndNeeded(dueDate.Type, languageCode);
    })
    // Alimentation libellés pays désignés le cas échéant selon langue souhaitée
    let hydrateDesignatedCountry : Function = (designatedCountry:  {
      Label: EntityRefDetails;
      Situation: EntityRefDetails;
      DesDate: Date;
      DesignationDateLabel: string;
      EffDate: Date| null;
      EffectDateLabel: string;
      Designation: EntityRefDetails;
  }) => {
      designatedCountry.Label.Label = this.getEnglishLabelIfExistAndNeeded(designatedCountry.Label, languageCode);
      designatedCountry.DesignationDateLabel = designatedCountry.DesDate !== null ? new Date(designatedCountry.DesDate).toLocaleDateString(locale) : "";
      if(designatedCountry.EffDate !== undefined){
        designatedCountry.EffectDateLabel = designatedCountry.EffDate !== null ? new Date(designatedCountry.EffDate).toLocaleDateString(locale) : "";    
      }if(designatedCountry.Designation !== null){
        designatedCountry.Designation.Label = this.getEnglishLabelIfExistAndNeeded(designatedCountry.Designation, languageCode);
      }
      if(designatedCountry.Situation !== null){
        designatedCountry.Situation.Label = this.getEnglishLabelIfExistAndNeeded(designatedCountry.Situation, languageCode);
      }
    };
      entity.Root.DesignatedCountries.forEach(designatedCountry =>        { 
        hydrateDesignatedCountry(designatedCountry);
    })

      entity.Fractions.forEach(fraction => {
        fraction.DesignatedCountries.forEach(designatedCountry => hydrateDesignatedCountry(designatedCountry));
      });
    
    // Alimentation libellés inscriptions le cas échéant selon langue souhaitée
    entity.Root.Records.forEach(record => { 
      record.Nature.Label = this.getEnglishLabelIfExistAndNeeded(record.Nature, languageCode);
      record.DateLabel = record.Date !== null ? new Date(record.Date).toLocaleDateString(locale) : "";
      record.TxtLabel = this.getEnglishLabelIfExistAndNeeded(record.Txt, languageCode);
    }) 
    // Alimentation date, libellé selon langue souhaitée et mise à jour totaux montants 
    entity.Root.Billings.forEach(billing => { 
      billing.DateLabel = billing.Date !== null ? new Date(billing.Date).toLocaleDateString(locale) : "";
      billing.BillingType.Label = this.getEnglishLabelIfExistAndNeeded(billing.BillingType, languageCode);
      billing.HTLabel = this.getAmountLabel(billing.HT, locale);
      billing.TVALabel = this.getAmountLabel(billing.TVA, locale);
      billing.AmountLabel = this.getAmountLabel(billing.Amount, locale);
      currentTotalHT += billing.HT
      currentTotalTVA += billing.TVA
      currentTotalAmount += billing.Amount
    })
    // Arrondi avec deux chiffres après la virgule et séparateur de miliers
    entity.Root.TotalHT =  this.getAmountLabel(currentTotalHT, locale);
    entity.Root.TotalTVA = this.getAmountLabel(currentTotalTVA, locale);
    entity.Root.TotalAmount = this.getAmountLabel(currentTotalAmount, locale);

    // Alimentation données marque inter si utilisation pour fractions
    if(entity.Fractions.length > 0 && entity.currRegInterDate === undefined && entity.currRegInterDateLabel === undefined && entity.currRegInterNum === undefined){
      entity.currRegInterDate = entity.Root.CurrRegDate;
      entity.currRegInterDateLabel = entity.Root.CurrRegDateLabel;
      entity.currRegInterNum = entity.Root.CurrRegNum;
    }
  }

  public static HydrateDrawingModelDetails(entity: FEDDrawingModelDetails, languageCode: string){
    var locale = languageCode === "fr" ? 'fr-FR': 'en-US';
    var currentTotalHT = 0 ;
    var currentTotalTVA = 0 ;
    var currentTotalAmount = 0 ;

    // Alimentation libellés selon langue souhaitée
    entity.Root.TitleLabel = this.getEnglishLabelIfExistAndNeeded(entity.Root.Title, languageCode);
    entity.Root.DepositCountry.Label = this.getEnglishLabelIfExistAndNeeded(entity.Root.DepositCountry, languageCode);
    entity.Root.TypeLabel = this.getEnglishLabelIfExistAndNeeded(entity.Root.TypeTitle, languageCode);
    entity.Root.DepositKindLabel = this.getEnglishLabelIfExistAndNeeded(entity.Root.DepositKind, languageCode);
    entity.Root.Situation.Label = this.getEnglishLabelIfExistAndNeeded(entity.Root.Situation, languageCode);
    if(entity.Root.Designation !== null){
      entity.Root.Designation.Label = this.getEnglishLabelIfExistAndNeeded(entity.Root.Designation, languageCode);
    }
    // Alimentation dates selon formatage de langue souhaitée
    entity.Root.OriDepDateLabel = entity.Root.OriDepDate !== null ? new Date(entity.Root.OriDepDate).toLocaleDateString(locale) : "";
    entity.Root.OriRegDateLabel = entity.Root.OriRegDate !== null ? new Date(entity.Root.OriRegDate).toLocaleDateString(locale) : "";
    entity.Root.CurrRegDateLabel = entity.Root.CurrRegDate !== null ? new Date(entity.Root.CurrRegDate).toLocaleDateString(locale) : "";
    entity.Root.PrioDateLabel = entity.Root.PrioDate !== null ? new Date(entity.Root.PrioDate).toLocaleDateString(locale) : "";
    entity.Root.DesignationDateLabel = entity.Root.DesignationDate !== null ? new Date(entity.Root.DesignationDate).toLocaleDateString(locale) : "";
    entity.Root.ExtendDateLabel= entity.Root.ExtendDate !== null ? new Date(entity.Root.ExtendDate).toLocaleDateString(locale) : "";
    entity.Root.ProtectionDateLabel= entity.Root.ProtectionDate !== null ? new Date(entity.Root.ProtectionDate).toLocaleDateString(locale) : "";    
    // Alimentation libellés selon langue souhaitée
    entity.Root.Owners.forEach(owner => { 
      owner.Country.Label = this.getEnglishLabelIfExistAndNeeded(owner.Country, languageCode);
    });
    // Alimentation dates selon langue souhaitée
    entity.Root.DueDates.forEach(dueDate => { 
      dueDate.DateLabel = dueDate.Date !== null ? new Date(dueDate.Date).toLocaleDateString(locale) : "";
      dueDate.Type.Label = this.getEnglishLabelIfExistAndNeeded(dueDate.Type, languageCode);
    });
    // Alimentation libellés pays désignés le cas échéant selon langue souhaitée
    let hydrateDesignatedCountry : Function = (designatedCountry:  {
      Label: EntityRefDetails;
      Situation: EntityRefDetails;
      DesDate: Date;
      DesignationDateLabel: string;
      EffDate: Date| null;
      EffectDateLabel: string;
      Designation: EntityRefDetails;
  }) => {
      designatedCountry.Label.Label = this.getEnglishLabelIfExistAndNeeded(designatedCountry.Label, languageCode);
      designatedCountry.DesignationDateLabel = designatedCountry.DesDate !== null ? new Date(designatedCountry.DesDate).toLocaleDateString(locale) : "";
      if(designatedCountry.EffDate !== undefined){
        designatedCountry.EffectDateLabel = designatedCountry.EffDate !== null ? new Date(designatedCountry.EffDate).toLocaleDateString(locale) : "";    
      }if(designatedCountry.Designation !== null){
        designatedCountry.Designation.Label = this.getEnglishLabelIfExistAndNeeded(designatedCountry.Designation, languageCode);
      }
      if(designatedCountry.Situation !== null){
        designatedCountry.Situation.Label = this.getEnglishLabelIfExistAndNeeded(designatedCountry.Situation, languageCode);
      }
    };

    entity.Root.DesignatedCountries.forEach(designatedCountry =>        { 
        hydrateDesignatedCountry(designatedCountry);
    })

    entity.Fractions.forEach(fraction => {
      fraction.DesignatedCountries.forEach(designatedCountry => hydrateDesignatedCountry(designatedCountry));
    });
    // Alimentation libellés inscriptions le cas échéant selon langue souhaitée
    entity.Root.Records.forEach(record => { 
      record.Nature.Label = this.getEnglishLabelIfExistAndNeeded(record.Nature, languageCode);
      record.DateLabel = record.Date !== null ? new Date(record.Date).toLocaleDateString(locale) : "";
      record.TxtLabel = this.getEnglishLabelIfExistAndNeeded(record.Txt, languageCode);
    }) 
    // Alimentation date, libellé selon langue souhaitée et mise à jour totaux montants 
    entity.Root.Billings.forEach(billing => { 
      billing.DateLabel = billing.Date !== null ? new Date(billing.Date).toLocaleDateString(locale) : "";
      billing.BillingType.Label = this.getEnglishLabelIfExistAndNeeded(billing.BillingType, languageCode);
      billing.HTLabel = this.getAmountLabel(billing.HT, locale);
      billing.TVALabel = this.getAmountLabel(billing.TVA, locale);
      billing.AmountLabel = this.getAmountLabel(billing.Amount, locale);
      currentTotalHT += billing.HT
      currentTotalTVA += billing.TVA
      currentTotalAmount += billing.Amount
    })
    // Arrondi avec deux chiffres après la virgule et séparateur de miliers
    entity.Root.TotalHT =  this.getAmountLabel(currentTotalHT, locale);
    entity.Root.TotalTVA = this.getAmountLabel(currentTotalTVA, locale);
    entity.Root.TotalAmount = this.getAmountLabel(currentTotalAmount, locale);

    // Alimentation données marque inter si utilisation pour fractions
    if(entity.Fractions.length > 0 && entity.currRegInterDate === undefined && entity.currRegInterDateLabel === undefined && entity.currRegInterNum === undefined){
      entity.currRegInterDate = entity.Root.CurrRegDate;
      entity.currRegInterDateLabel = entity.Root.CurrRegDateLabel;
      entity.currRegInterNum = entity.Root.CurrRegNum;
    }
  }

  static getEnglishLabelIfExistAndNeeded(property: any, languageCode: string): string{
    if (property === null){
      return ""
    }
    return languageCode === "en" && property.EN !== '' ? property.EN : property.FR;
  }
  static getAmountLabel(amount: number, locale: string): string {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(amount);
  }
}