<div><label class="epw-label blue bold">{{ this.headerText }}</label></div>
<div class="row-container">
    <input class="epw-search-panel-input" name="input-booking-date-start" matInput [matDatepicker]="pickerBookingDateStart"
        [(ngModel)]="this.criteria.start"
    >
    <button class="epw-white-button calendar-button" (click)="pickerBookingDateStart.open()">
        <span class="glyphicon glyphicon-calendar"></span>
    </button>
    <mat-datepicker #pickerBookingDateStart></mat-datepicker>
    
    <input type="text" [disabled]="true" class="fake-input" value="{{ translateService.getLabel('common.dateFromTo') }}">

    <input class="epw-search-panel-input" name="input-booking-date-end" matInput [matDatepicker]="pickerBookingDateEnd"
        [(ngModel)]="this.criteria.end">
    <button class="epw-white-button calendar-button" (click)="pickerBookingDateEnd.open()">
        <span class="glyphicon glyphicon-calendar"></span>
    </button>
    <mat-datepicker #pickerBookingDateEnd></mat-datepicker>
</div>