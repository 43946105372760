import { Component, Input } from '@angular/core';
import { OffTitleCriteria } from '@core/models/criterias/off-title-criteria';
import { TabContext } from '@core/models/tab-contexts/tab-context';
import { EpisoftTranslateService } from '@core/services/translate.service';

@Component({
  selector: 'app-off-titles-search-panel',
  templateUrl: './off-titles-search-panel.component.html',
  styleUrls: ['./off-titles-search-panel.component.scss']
})
export class OffTitlesSearchPanelComponent {
  @Input() tabContext!: TabContext;

  searchCriterias: OffTitleCriteria = new OffTitleCriteria();

  public getSearchCriterias(): OffTitleCriteria {
    return this.searchCriterias;
  }

  constructor(public translateService: EpisoftTranslateService) { }

  ngOnInit() {
    if (this.tabContext != undefined) {
      this.tabContext.searchCriterias = this.searchCriterias;
    }
  }

  // Méthodes gérant la liste déroulante "Type de dossier"
  typeLabelSelector : (HTType: any) => string = HTType => HTType.CurrentTypeLabel;

  typeIsActiveSelector: Function = (a: any): boolean => true; //Tous les types de dossiers sont toujours proposés par défaut

  typeValueSelector: (HTType: any) => string = HTType => String(HTType.Code);

  updateType(newValue: string) {
    this.searchCriterias.HTType = newValue;
  }

  // Méthodes gérant la liste déroulante "Pays"
  countryLabelSelector: (country: any) => string = country => country.CurrentCountryLabel;

  countryIsActiveSelector: Function = (a: any): boolean => true; //Tous les pays sont toujours proposés par défaut

  countryValueSelector: (country: any) => string = country => String(country.Code);

  updateCountry(newValue: string) {
    this.searchCriterias.Country = newValue;
  }

  // Méthodes gérant la liste déroulante "Situation"
  statusLabelSelector: (status: any) => string = status => status.CurrentStatusLabel;

  statusIsActiveSelector: Function = (a: any): boolean => true; //Toutes les situations sont toujours proposés par défaut

  statusValueSelector: (situation: any) => string = situation => String(situation.Code);

  updateStatus(newValue: string) {
    this.searchCriterias.Situation = newValue;
  }

  updateIncludeInactive(newValue: boolean) {
    this.searchCriterias.IncludeInactive = newValue;
  }
}
