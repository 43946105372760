<div class="home-tab-component">
    <div class="home-tab-component__left-panel">
        <div class="home-tab-component__header">{{ translateService.getLabel('dashboard.hello') }}</div>
        <div class="home-tab-component__title">Episoft Web</div>
        <div class="home-tab-component__content">{{ translateService.getLabel('dashboard.text.part1') }}
        <strong>{{ translateService.getLabel('dashboard.text.part2') }}</strong></div>
    </div>
    <div class="home-tab-component__right-panel">
        <img class="home-tab-component__right-panel-image" src="./assets/images/visuel_fond_GM.png" />
    </div>
</div>