import { BERUserProfileEntity } from "../back-end-raw-entities/ber-user-profile-entity";
import { FEDWalletEntity } from "./fed-wallet-entity";

export class FEDUserProfileEntity implements BERUserProfileEntity {
    constructor(source : BERUserProfileEntity) {
        Object.assign(this, source);
        this.hasFilteringOwners = this.Wallet.length > 0;
    }
    static fromBERUserProfileEntity(source: BERUserProfileEntity): FEDUserProfileEntity {
        return new FEDUserProfileEntity(source);
    }
    // Données de BERUserEntity
    Id!: number;
    Name!: string;
    EpisoftID!: string;
    AccessFinancialData!: boolean;
    AuthorizationLevel!: string;
    Created!: Date;
    Modified!: Date;
    IsDefault!: boolean;
    Wallet: number[] = [];    
    // Données connues uniquement côté Front End
    public GetAuthorizationLevelLabel() : string {      
        var level = FEDUserProfileEntity.authLevels.find(e => e.code === this.AuthorizationLevel);
        if(level !== undefined) return level.label;
        return "";
    }
    //FilteringOwners: FEDWalletEntity[] = [];
    public hasFilteringOwners: boolean;
     public getFilteringOwnersLabels(wallet : FEDWalletEntity[]): string {
         return this.Wallet.map(id => wallet.find(o => o.Id === id)?.Label).join("\n") || "";
     }
    public containsOwner(id: number) : boolean {
        return (this.Wallet.find(e => e === id) !== undefined);
    }

    public static authLevels = [
        { code: "AllDocuments", label :"all" },
        { code: "OfficialDocuments", label :"official" },
        { code: "NoDocument", label :"none" },   
      ];

}
