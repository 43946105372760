import { StringHelper } from "@core/helpers/string-helper";
import { Owner } from "../owner";
import { EpisoftTranslateService } from '@core/services/translate.service';
import { Observable } from "rxjs";
import { ParametersService } from "@core/services/parameters.service";
import { GenericCriteria } from "../criterias/generic-criteria";
import { FEDBaseEntity } from "../entities/front-end-displayable-entities/fed-base-entity";
import { ExportActionType } from "../export-action";

interface SortOption {
    Code: string;
    Label: string;
  }

  // Présent dans chaque onglet qui doit redéfinir dans son constructeur : 
  // sort.options               => les types de tri à proposer
  // sort.selectedOptionCode    => le tri à appliquer par défaut
  // loadData                   => La méthode de chargement permet d'alimenter le tableau data
export abstract class TabContext {
    constructor (public translateService: EpisoftTranslateService, public parametersService : ParametersService){ }

    isSearchPanelVisible : boolean = true;

    // Gestion des données et de leur chargement
    data : any[] = [];
    visibleData : any[] = [];
    // Permet de gérer les indicateurs de chargement    
    public isLoading : boolean = false;
    public isSearching : boolean = false;
    public get isIdle() : boolean { return !this.isLoading && !this.isSearching; }

    // Gestion des critères
    searchCriterias : GenericCriteria | null = null;
    public abstract resetSearchCriterias(): void;

    resetSearchPanelCallback : Function = () => {};

    // Le chargement des données se fait via la méthode public loadData()
    // Qui appelle en sous main : 
    // - customLoadData(), méthode abstraite ET ASYNCHRONE a redéfinir dans les contextes spécialisés dans chaque module
    // - sortData(), idem, mais pour trier
    // - un callback optionnel loadDataCallback, qui peut être défini par l'appelant 
    //      (utile pour forcer la navigation vers une page précise après le chargement, ou encore gérer la préséléction des éléments)
    // customLoadata() est asynchrone car elle est censée appeler le back-end ... donc via une API asynchrone. 
    protected abstract customLoadData(searchCriterias : GenericCriteria | undefined) : Observable<any>;
    abstract sortData() : void;
    public loadDataCallback : Function = () => { };

    public loadData(applyFilteringCriterias : boolean = false) : void {
        this.isLoading = !applyFilteringCriterias;
        this.isSearching = applyFilteringCriterias;
        // On réinitialise les critères de recherche si on fait un affichage total du portefeuille
        if(!applyFilteringCriterias) {
            this.searchCriterias?.resetToDefault();
            this.resetSearchPanelCallback();
        }
        // Chargement spécifiques des données
        this.customLoadData(applyFilteringCriterias ? (this.searchCriterias ?? undefined) : undefined).subscribe((data: any) => {
            this.data = data;
            this.sortData();
            this.loadDataCallback();
            this.isLoading = false;
            this.isSearching = false;
        });
    }

    
    abstract loadParameters() : void;
 
    abstract getModuleId() : string;

    // Gestion du nombre d'éléments à afficher par page
    pageSize = new class {
        selectedOption: number = 10;
        options: number[] = [
            10,
            25,
            50,
            100,
            250,
            1000
          ];
    }

    // Gestion du tri
    sort = new class {
        selectedOptionCode: string = '';
        useSortOrderASC: boolean = true;
        options: SortOption[] = [];
    }

    // Gestion de la pagination
    pagination = new class {
        currentPage: number = 1;
    }

    // Gestion de la sélection
    selectAllIsChecked : boolean = false;

    // Stockage des saisies anticipées pour marques modèles ou surveillances
    typeAhead: string[] = [];

    // Gestion des paramètres utilisés par les critères de recherche
    parameters = new class {
        owners: Owner[] = [];
        inventors: any[] = [];
        types: any[] = [];
        typeTitlesFilter: any[] = [];
        countries: any[] = [];
        filteredCountries: any[] = [];
        countriesFilter: any[] = [];
        status: any[] = [];
        options: any[] = [];
        optionsFilter: any[] = [];
        filteredOptions: any[] = [];
        classesFiltered: any[] = [];
    }

    protected loadCurrentLanguageEntityParameters() : void {
        switch (this.translateService.getCurrentLanguage()) {
            case "fr":
                this.parameters.types.forEach(type => {
                    type.CurrentTypeLabel = type.FR
                });
                this.parameters.status.forEach(status => {
                    status.CurrentStatusLabel = status.FR
                });
                this.parameters.options.forEach(option => {
                    option.CurrentOptionLabel = option.FR
                });
            break;    
            case "en":
                this.parameters.types.forEach(type => {
                    type.CurrentTypeLabel = type.EN
                });
                this.parameters.status.forEach(status => {
                    status.CurrentStatusLabel = status.EN
                });
                this.parameters.options.forEach(option => {
                    option.CurrentOptionLabel = option.EN
                });
            break;
        }
    }

    // Tri des paramètres de recherche propres aux services entités
    protected sortEntityParameters() : void {
        // Subtilité pour les titulaires : ceux qui sont inactifs apparaissent après les autres, d'où le sélecteur spécifique ci-dessous
        let ownerSortValueSelector : Function = (o: Owner) => o.HasActiveItems ? "_" : " " + o.Name;
        this.parameters.owners.sort((o1, o2) => StringHelper.compareStrings(ownerSortValueSelector(o1.Name), ownerSortValueSelector(o2.Name)));
        this.parameters.owners.splice(0, 0, { Code: 0, Name: "", HasActiveItems:false});
        this.parameters.inventors.sort((o1, o2) => StringHelper.compareStrings(o1.Name, o2.Name));
        this.parameters.inventors.splice(0, 0, { Code: 0, Name: "", HasActiveItems:false} );
        this.parameters.types.splice(0, 0, { Code: "", EN: "", FR: "", CurrentTypeLabel: ""});
        this.parameters.types.sort((t1, t2) => StringHelper.compareStrings(t1.CurrentTypeLabel, t2.CurrentTypeLabel)); 
        this.parameters.status.splice(0, 0, { Code: "", EN: "", FR: "", CurrentStatusLabel: ""});
        this.parameters.status.sort((s1, s2) => StringHelper.compareStrings(s1.CurrentStatusLabel, s2.CurrentStatusLabel));
        this.parameters.options.splice(0, 0, { Code: "", EN: "", FR: "", CurrentOptionLabel: ""});
        this.parameters.options.sort((op1, op2) => StringHelper.compareStrings(op1.CurrentOptionLabel, op2.CurrentOptionLabel));       
        this.parameters.filteredOptions.sort((op1, op2) => StringHelper.compareStrings(op1.CurrentOptionLabel, op2.CurrentOptionLabel));
    }

    protected loadCurrentLanguageGlobalParameters() : void {
        switch (this.translateService.getCurrentLanguage()) {
            case "fr":
                this.parameters.countries.forEach(country => {
                    country.CurrentCountryLabel = country.FR
                });
            break;    
            case "en":
                this.parameters.countries.forEach(country => {
                    country.CurrentCountryLabel = country.EN
                });
            break;
        }
    }

    // Tri des paramètres de recherche propres au service paramètres
    protected sortGlobalParameters() : void {
        this.parameters.countries.splice(0, 0, { Code: "", EN: "", FR: "", CurrentCountryLabel: ""})
        this.parameters.countries.sort((c1, c2) => StringHelper.compareStrings(c1.CurrentCountryLabel, c2.CurrentCountryLabel));
        this.parameters.filteredCountries.sort((c1, c2) => StringHelper.compareStrings(c1.FR, c2.FR));
    }

    // Sélection d'un élément
    // Cette méthode est redéfinie dans le cas des dossiers complexes, c'est à dire avec des sous éléments sélectionnables
    // C'est par exemple le cas des fractions de marque inter
    public selectItem(item: FEDBaseEntity,  select : boolean) : void {
        item.IsSelected = select;
    }

    public abstract export(exportType: ExportActionType) : void;

    public abstract getAvailableExportTypes() : ExportActionType[]

}