import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { HttpApiService } from '@core/services/http-api.service';
import { ApiPathsService } from '@core/services/api-paths.service';
import { EntityRef } from '@core/models/entity-ref';
import { Owner } from '@core/models/owner';
import { HydratationHelper } from '@core/helpers/hydratation-helper';
import { EpisoftTranslateService } from './translate.service';
import { AuthService } from './auth.service';
import { ParametersService } from './parameters.service';
import { BERTrademarkEntity } from '@core/models/entities/back-end-raw-entities/ber-trademark-entity';
import { FEDTrademarkEntity } from '@core/models/entities/front-end-displayable-entities/fed-trademark-entity';
import { TrademarkCriteria } from "@core/models/criterias/trademark-criteria";
import { FEDTrademarkDetails } from '@core/models/details/front-end-displayable-details/fed-trademark-details';
import { ExportActionType } from '@core/models/export-action';
import { ExportService } from './export.service';
import { FEDBaseEntity } from '@core/models/entities/front-end-displayable-entities/fed-base-entity';
import { environment } from 'src/environments/environment';

export interface TrademarkData {
  Refs: EntityRef[];
  Entities: BERTrademarkEntity[];
}

export interface TrademarkParamsData {
  TypeTitles: EntityRef[];
  Owners: Owner[];
  typeDueDates: EntityRef[];
}

@Injectable({
  providedIn: 'root'
})
export class TrademarksService {

  constructor(
    private httpApiService: HttpApiService, 
    private translateService: EpisoftTranslateService, 
    private parametersService : ParametersService, 
    private apiPathsService: ApiPathsService, 
    private authService: AuthService, 
    private exportService: ExportService) { }
  
  public getLabelemarks(searchCriterias: TrademarkCriteria | null = null): Observable<FEDTrademarkEntity[]> {
    let specificData: TrademarkParamsData;
      // Chargement des paramètres globaux
      return this.parametersService.Get().pipe(
        // Chargement des paramètres spécifiques
        switchMap( _ => this.GetParams()),
        // Chargement des données
        switchMap( data => {
          specificData = data;
          return this.httpApiService.post<TrademarkData>("API-Trademarks-POST", searchCriterias);
        }),
        // Hydratation des données
        map((data: TrademarkData) => { 
          let entities: FEDTrademarkEntity[] = (data.Entities ?? []).map(e => new FEDTrademarkEntity(e));
          HydratationHelper.HydrateTrademarks(entities, data.Refs, specificData.TypeTitles, this.parametersService, this.translateService.getCurrentLanguage());
          return entities;
        })
      );       
  }

  public GetParams(): Observable<TrademarkParamsData> {
    let data: Observable<TrademarkParamsData> = this.httpApiService.get<TrademarkParamsData>("API-TrademarksParams-GET");
    return data.pipe(map((data: TrademarkParamsData) => { 
      return data;
    }));
  }
  
  public getDetails(refgm: string, GUID: string): Observable<FEDTrademarkDetails>{
   let data: Observable<FEDTrademarkDetails> = this.httpApiService.get<FEDTrademarkDetails>("API-TrademarksDetails-GET", new Map<string, string>([["refgm", refgm],["guid", GUID]]), );
   return data.pipe(map((data: FEDTrademarkDetails) => { 
      HydratationHelper.HydrateTrademarkDetails(data, this.translateService.getCurrentLanguage());
      return data;
    })); 
  }

  public getTypeAhead(): Observable<any>{
    let data: Observable<any> = this.httpApiService.get<any>("API-TrademarksTypeAhead-GET");
    return data.pipe(map((data: any) => { 
      return data;
    }));
  }

  public GetImageUrl(refgm: string) : string {
    // L'URL d'une image est du type : https://episoft-test.germainmaureau.com/api/zen/epi/trademarks/MA045692/image?token=FOO
    return environment.backendUrl + 
          this.apiPathsService.GetUrl("API-TrademarksImage-GET", new Map<string, string>([["refgm", refgm], ["token", this.authService.getToken()]]));
  }

  public Export(exportType: ExportActionType, items: FEDBaseEntity[]) : void {
      // Export marques : mode liste ou carto
      // Les colonnes sont les mêmes, mais pas l'API
      // Certains paramètres changent également pour l'export carto
      let api : string;
      let texts : any;
      let sheets : any;
      switch(exportType) {
        case ExportActionType.List : 
          api = "API-TrademarksExport-POST";
          texts = [
            { Code: "Title", Text: "trademark.name" }
          ];
          sheets = []; // Pas d'onglet en mode liste
          break;
        case ExportActionType.Map : 
          api = "API-TrademarksExportMap-POST";
          texts = [
            { Code: "TXT_LEGEND", Text: "common.exportMap.texts.TXT_LEGEND" },
            { Code: "TXT_LEGEND_COUNTRY", Text: "common.exportMap.texts.TXT_LEGEND_COUNTRY" },
            { Code: "TXT_LEGEND_LABEL_ON", Text: "common.exportMap.texts.TXT_LEGEND_LABEL_ON" },
            { Code: "TXT_LEGEND_LABEL_OFF", Text: "common.exportMap.texts.TXT_LEGEND_LABEL_OFF" }
          ];
          sheets = [
            { Code: "TXT_WORLD", Text: "common.exportMap.sheets.TXT_WORLD"},
            { Code: "TXT_AFRICA", Text: "common.exportMap.sheets.TXT_AFRICA"},
            { Code: "TXT_AMERICA", Text: "common.exportMap.sheets.TXT_AMERICA"},
            { Code: "TXT_ASIA", Text: "common.exportMap.sheets.TXT_ASIA"},
            { Code: "TXT_EUROPE", Text: "common.exportMap.sheets.TXT_EUROPE"}
          ];
          break;
        default: 
          throw new Error("Invalid export type");
      }

    // Appel du service générique d'export
    this.exportService.Export(
      // API
      api,
      // Références des dossiers à exporter
      items.map(i => i.RefGM),
      // Colonnes
      [ 
        { Code: "RefGM", Text: "common.shared.refGM" },
        { Code: "Name", Text: "trademark.props.name"},
        { Code: "Logo", Text: ""},
        { Code: "TypeTitle", Text: "common.shared.type"},
        { Code: "Owners", Text: "common.shared.owners"},
        { Code: "Classes", Text: "trademark.props.classes"},
        { Code: "Products", Text: "common.groups.products"},
        { Code: "DepositCountry", Text: "common.shared.countryDep"},
        { Code: "DepositKind", Text: "common.shared.depKind"},
        { Code: "OriginDepositNum", Text: "common.shared.depNum"},
        { Code: "OriginDepositDate", Text: "common.shared.depDate"},
        { Code: "CurrentRegistrationNum", Text: "common.shared.regNum"},
        { Code: "CurrentRegistrationDate", Text: "common.shared.date"},
        { Code: "Situation", Text: "common.shared.status" },
        { Code: "RenewDueDates", Text: "common.shared.renewDates" },
        { Code: "OtherDueDates", Text: "common.shared.otherDueDates" }       
      ],
      // Textes
      texts,
      // Onglets
      sheets
    );
  }

}
